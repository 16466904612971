// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top, .message {
  display: flex;
  align-items: center;
}

.message {
  flex-grow: 1;
}

.type-icon {
  margin-right: 0.5em;
  flex-shrink: 0;
}

button {
  color: var(--mdc-snackbar-supporting-text-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNuYWNrLWJhci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLGFBQUE7RUFDQSxtQkFBQTtBQUNEOztBQUVBO0VBQ0MsWUFBQTtBQUNEOztBQUVBO0VBQ0MsbUJBQUE7RUFDQSxjQUFBO0FBQ0Q7O0FBRUE7RUFDQyxnREFBQTtBQUNEIiwiZmlsZSI6InNuYWNrLWJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50b3AsIC5tZXNzYWdlIHtcblx0ZGlzcGxheTogZmxleDtcblx0YWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cblxuLm1lc3NhZ2Uge1xuXHRmbGV4LWdyb3c6IDE7XG59XG5cbi50eXBlLWljb24ge1xuXHRtYXJnaW4tcmlnaHQ6IDAuNWVtO1xuXHRmbGV4LXNocmluazogMDtcbn1cblxuYnV0dG9uIHtcblx0Y29sb3I6IHZhcigtLW1kYy1zbmFja2Jhci1zdXBwb3J0aW5nLXRleHQtY29sb3IpO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./../../../lowgile/libs/ng-shared/src/lib/components/snack-bar/snack-bar.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,mBAAA;EACA,cAAA;AACD;;AAEA;EACC,gDAAA;AACD;AACA,gqBAAgqB","sourcesContent":[".top, .message {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.message {\n\tflex-grow: 1;\n}\n\n.type-icon {\n\tmargin-right: 0.5em;\n\tflex-shrink: 0;\n}\n\nbutton {\n\tcolor: var(--mdc-snackbar-supporting-text-color);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
