// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    invoice: This.ArInvoice;
    customers: This.Customer[];
    schedules: This.ArSchedule[];
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-CreateArInvoice',
    templateUrl: './CreateArInvoice.html',
    styleUrls: ['./CreateArInvoice.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class CreateArInvoice extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.CreateArInvoice';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    $validators: Record<number, ValidationRules> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        invoice: this.invoice !== undefined ? this.invoice : (undefined) as This.ArInvoice,
        customers: this.customers !== undefined ? this.customers : ([]) as This.Customer[],
        schedules: this.schedules !== undefined ? this.schedules : ([]) as This.ArSchedule[]
    });

    get $inputs() {
        return {

        }
    }

    get invoice() {
        return this.screen?.invoice
    }

    set invoice(invoice: This.ArInvoice) {
        invoice = (invoice as any)?.[ProxyTargetSymbol] ?? invoice
        this.storeService.setProperty([this.componentStore.name], 'invoice', invoice)
    }

    get customers() {
        return this.screen?.customers
    }

    set customers(customers: This.Customer[]) {
        customers = (customers as any)?.[ProxyTargetSymbol] ?? customers
        this.storeService.setProperty([this.componentStore.name], 'customers', customers)
    }

    get schedules() {
        return this.screen?.schedules
    }

    set schedules(schedules: This.ArSchedule[]) {
        schedules = (schedules as any)?.[ProxyTargetSymbol] ?? schedules
        this.storeService.setProperty([this.componentStore.name], 'schedules', schedules)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {

        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen


                screen.customers = []
                screen.schedules = []

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_ButtonBlock_0_0_0_0_1_click_save_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_save($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_save($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            try {
                await This.ArInvoice.createTestInvoice(screen.invoice)
                await this.action_initInvoice($context)
                Sys.App.showNotification('success', 'Invoice created successfully')
            } catch (err) {
                Sys.App.showNotification('error', err.message)
            }

        }
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.schedules = await This.Db.loadByCondition(This.ArSchedule)
            screen.customers = await This.Db.loadByCondition(This.Customer)
            await this.action_initInvoice($context)
        }
    }

    async action_initInvoice($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.invoice = new This.ArInvoice({
                dueDate: new Date,
                scheduleId: screen.schedules[0]?.id,
                customerId: screen.customers[0]?.id,
                status: This.ArInvoiceStatus.Open,
                amount: 10000,
                currency: This.Currency.CHF,
            })
        }
    }
}
