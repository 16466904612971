// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { ResourceDefinition } from "@shared/types";

export class $Resources {
    static readonly Logo: ResourceDefinition<'client', 'downloadable'> = {
        resourceType: 'image/png',
        mimeType: 'image/png',
        fileName: 'logo_large.png',
        relativeUrl: '/master/PurchaseToPay/PurchaseToPay.Logo/logo_large.png',
        get absoluteUrl() { return `${System.$Config.Environment.publicUrl}/master/PurchaseToPay/PurchaseToPay.Logo/logo_large.png` },


    };
}
