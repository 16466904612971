// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseVendorOffer {
    @jsonMember(() => PurchaseToPay__PurchaseVendorOffer.Id)
    id: PurchaseToPay__PurchaseVendorOffer.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "offerStatus", "offerStatus$id", "isValidOffer", "isPreferredOffer", "commentInternal", "commentForVendor", "rfqText", "offerNumber", "vendorId", "vendorName", "vendorAddress", "vendorEmail", "offerAttachmentComment", "referencedContractIds", "selectionStatus", "selectionStatus$id", "paymentTerms", "paymentTerms$id", "itemsOffered", "offerAttachments", "request", "requestId", "purchaseOrders", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber", "totalPrice"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "offerStatus": "PurchaseToPay.PurchaseVendorOfferStatus", "offerStatus$id": "string", "isValidOffer": "boolean", "isPreferredOffer": "boolean", "commentInternal": "string", "commentForVendor": "string", "rfqText": "string", "offerNumber": "string", "vendorId": "string", "vendorName": "string", "vendorAddress": "string", "vendorEmail": "string", "offerAttachmentComment": "string", "referencedContractIds": "string", "selectionStatus": "PurchaseToPay.PurchaseVendorOfferSelectionStatus", "selectionStatus$id": "string", "paymentTerms": "PurchaseToPay.PaymentTerm", "paymentTerms$id": "string", "itemsOffered": "PurchaseToPay.PurchaseVendorOfferItem[]", "offerAttachments": "PurchaseToPay.PurchaseVendorOfferAttachment[]", "request": "PurchaseToPay.PurchaseRequest", "requestId": "PurchaseToPay.PurchaseRequest.Id", "purchaseOrders": "PurchaseToPay.PurchaseOrder[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "totalPrice": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["offerStatus", "selectionStatus", "paymentTerms"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ request: 'PurchaseToPay.PurchaseRequest' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ itemsOffered: 'PurchaseToPay.PurchaseVendorOfferItem', offerAttachments: 'PurchaseToPay.PurchaseVendorOfferAttachment', purchaseOrders: 'PurchaseToPay.PurchaseOrder' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θofferStatus?: This.PurchaseVendorOfferStatus = This.PurchaseVendorOfferStatus.NotYetRequested;
    private θisValidOffer?: boolean = false;
    private θisPreferredOffer?: boolean = false;
    private θcommentInternal?: string = undefined;
    private θcommentForVendor?: string = undefined;
    private θrfqText?: string = undefined;
    private θofferNumber?: string = undefined;
    private θvendorId?: string = undefined;
    private θvendorName?: string = undefined;
    private θvendorAddress?: string = undefined;
    private θvendorEmail?: string = undefined;
    private θofferAttachmentComment?: string = undefined;
    private θreferencedContractIds?: string = '';
    private θselectionStatus?: This.PurchaseVendorOfferSelectionStatus = This.PurchaseVendorOfferSelectionStatus.Undecided;
    private θpaymentTerms?: This.PaymentTerm = This.PaymentTerm.Days30;
    private θitemsOffered?: PurchaseToPay.PurchaseVendorOfferItem[] = undefined;
    private θofferAttachments?: PurchaseToPay.PurchaseVendorOfferAttachment[] = undefined;
    private θrequest?: PurchaseToPay.PurchaseRequest = undefined;
    private θrequestId?: number = null;
    private θpurchaseOrders?: PurchaseToPay.PurchaseOrder[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        isValidOffer: 0,
        isPreferredOffer: 0,
        commentInternal: 0,
        commentForVendor: 0,
        rfqText: 0,
        offerNumber: 0,
        vendorId: 0,
        vendorName: 0,
        vendorAddress: 0,
        vendorEmail: 0,
        offerAttachmentComment: 0,
        referencedContractIds: 0,
        itemsOffered: 0,
        offerAttachments: 0,
        request: 0,
        requestId: 0,
        purchaseOrders: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        isValidOffer: () => {
            return false
        }
        , isPreferredOffer: () => {
            return false
        }
        , commentInternal: () => {
            return false
        }
        , commentForVendor: () => {
            return false
        }
        , rfqText: () => {
            return false
        }
        , offerNumber: () => {
            return false
        }
        , vendorId: () => {
            return false
        }
        , vendorName: () => {
            return false
        }
        , vendorAddress: () => {
            return false
        }
        , vendorEmail: () => {
            return false
        }
        , offerAttachmentComment: () => {
            return false
        }
        , referencedContractIds: () => {
            return false
        }
        , itemsOffered: () => {
            return false
        }
        , offerAttachments: () => {
            return false
        }
        , request: () => {
            return false
        }
        , requestId: () => {
            return false
        }
        , purchaseOrders: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseVendorOffer' {
        return 'PurchaseToPay.PurchaseVendorOffer'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseVendorOffer' {
        return 'PurchaseToPay.PurchaseVendorOffer'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseVendorOffer' {
        return 'PurchaseToPay.PurchaseVendorOffer'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseVendorOffer' {
        return 'PurchaseToPay.PurchaseVendorOffer'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseVendorOffer[EntityInfoSymbol]
    }

    get offerStatus(): This.PurchaseVendorOfferStatus {
        return this.θofferStatus
    }

    set offerStatus(offerStatus: This.PurchaseVendorOfferStatus | This.PurchaseVendorOfferStatus.Id) {
        if (!this.θofferStatus) this.θofferStatus = new This.PurchaseVendorOfferStatus()
        this.θofferStatus.θupdate(offerStatus)
    }

    @jsonMember(String)
    get 'offerStatus$id'(): string {
        return this.θofferStatus?.id
    }

    set 'offerStatus$id'(offerStatus: string) {
        if (!this.θofferStatus) this.θofferStatus = This.PurchaseVendorOfferStatus.NotYetRequested
        this.θofferStatus.id = offerStatus as any
    }

    get isValidOffer() {
        return this.θisValidOffer
    }

    set isValidOffer(isValidOffer: boolean) {
        this.θisValidOffer = LowgileDataUtil.coerceDataType(isValidOffer, 'boolean', false, true)
    }

    get isPreferredOffer() {
        return this.θisPreferredOffer
    }

    set isPreferredOffer(isPreferredOffer: boolean) {
        this.θisPreferredOffer = LowgileDataUtil.coerceDataType(isPreferredOffer, 'boolean', false, true)
    }

    get commentInternal() {
        return this.θcommentInternal
    }

    set commentInternal(commentInternal: string) {
        this.θcommentInternal = LowgileDataUtil.coerceDataType(commentInternal, 'string', false, true)
    }

    get commentForVendor() {
        return this.θcommentForVendor
    }

    set commentForVendor(commentForVendor: string) {
        this.θcommentForVendor = LowgileDataUtil.coerceDataType(commentForVendor, 'string', false, true)
    }

    get rfqText() {
        return this.θrfqText
    }

    set rfqText(rfqText: string) {
        this.θrfqText = LowgileDataUtil.coerceDataType(rfqText, 'string', false, true)
    }

    get offerNumber() {
        return this.θofferNumber
    }

    set offerNumber(offerNumber: string) {
        this.θofferNumber = LowgileDataUtil.coerceDataType(offerNumber, 'string', false, true)
    }

    get vendorId() {
        return this.θvendorId
    }

    set vendorId(vendorId: string) {
        this.θvendorId = LowgileDataUtil.coerceDataType(vendorId, 'string', false, true)
    }

    get vendorName() {
        return this.θvendorName
    }

    set vendorName(vendorName: string) {
        this.θvendorName = LowgileDataUtil.coerceDataType(vendorName, 'string', false, true)
    }

    get vendorAddress() {
        return this.θvendorAddress
    }

    set vendorAddress(vendorAddress: string) {
        this.θvendorAddress = LowgileDataUtil.coerceDataType(vendorAddress, 'string', false, true)
    }

    get vendorEmail() {
        return this.θvendorEmail
    }

    set vendorEmail(vendorEmail: string) {
        this.θvendorEmail = LowgileDataUtil.coerceDataType(vendorEmail, 'string', false, true)
    }

    get offerAttachmentComment() {
        return this.θofferAttachmentComment
    }

    set offerAttachmentComment(offerAttachmentComment: string) {
        this.θofferAttachmentComment = LowgileDataUtil.coerceDataType(offerAttachmentComment, 'string', false, true)
    }

    get referencedContractIds() {
        return this.θreferencedContractIds
    }

    set referencedContractIds(referencedContractIds: string) {
        this.θreferencedContractIds = LowgileDataUtil.coerceDataType(referencedContractIds, 'string', false, true)
    }

    get selectionStatus(): This.PurchaseVendorOfferSelectionStatus {
        return this.θselectionStatus
    }

    set selectionStatus(selectionStatus: This.PurchaseVendorOfferSelectionStatus | This.PurchaseVendorOfferSelectionStatus.Id) {
        if (!this.θselectionStatus) this.θselectionStatus = new This.PurchaseVendorOfferSelectionStatus()
        this.θselectionStatus.θupdate(selectionStatus)
    }

    @jsonMember(String)
    get 'selectionStatus$id'(): string {
        return this.θselectionStatus?.id
    }

    set 'selectionStatus$id'(selectionStatus: string) {
        if (!this.θselectionStatus) this.θselectionStatus = This.PurchaseVendorOfferSelectionStatus.Undecided
        this.θselectionStatus.id = selectionStatus as any
    }

    get paymentTerms(): This.PaymentTerm {
        return this.θpaymentTerms
    }

    set paymentTerms(paymentTerms: This.PaymentTerm | This.PaymentTerm.Id) {
        if (!this.θpaymentTerms) this.θpaymentTerms = new This.PaymentTerm()
        this.θpaymentTerms.θupdate(paymentTerms)
    }

    @jsonMember(String)
    get 'paymentTerms$id'(): string {
        return this.θpaymentTerms?.id
    }

    set 'paymentTerms$id'(paymentTerms: string) {
        if (!this.θpaymentTerms) this.θpaymentTerms = This.PaymentTerm.Days30
        this.θpaymentTerms.id = paymentTerms as any
    }

    get itemsOffered() {
        return this.θitemsOffered
    }

    set itemsOffered(itemsOffered: PurchaseToPay.PurchaseVendorOfferItem[]) {
        this.θitemsOffered = LowgileDataUtil.coerceDataType(itemsOffered, PurchaseToPay.PurchaseVendorOfferItem, true, true)
    }

    get offerAttachments() {
        return this.θofferAttachments
    }

    set offerAttachments(offerAttachments: PurchaseToPay.PurchaseVendorOfferAttachment[]) {
        this.θofferAttachments = LowgileDataUtil.coerceDataType(offerAttachments, PurchaseToPay.PurchaseVendorOfferAttachment, true, true)
    }

    get request() {
        return this.θrequest
    }

    set request(request: PurchaseToPay.PurchaseRequest) {
        this.θrequest = LowgileDataUtil.coerceDataType(request, PurchaseToPay.PurchaseRequest, false, true)
    }

    get requestId() {
        return this.θrequestId
    }

    set requestId(requestId: number) {
        this.θrequestId = LowgileDataUtil.coerceDataType(requestId, 'number', false, true)
    }

    get purchaseOrders() {
        return this.θpurchaseOrders
    }

    set purchaseOrders(purchaseOrders: PurchaseToPay.PurchaseOrder[]) {
        this.θpurchaseOrders = LowgileDataUtil.coerceDataType(purchaseOrders, PurchaseToPay.PurchaseOrder, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseVendorOffer.θmaxLength
    }

    get totalPrice(): number {
        return this.itemsOffered?.reduce((agg, item) => agg + item.quantityOffered * item.unitPrice, 0) ?? 0
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseVendorOffer>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseVendorOffer(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseVendorOffer>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseVendorOffer>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('offerStatus$id', 'isValidOffer', 'isPreferredOffer', 'commentInternal', 'commentForVendor', 'rfqText', 'offerNumber', 'vendorId', 'vendorName', 'vendorAddress', 'vendorEmail', 'offerAttachmentComment', 'referencedContractIds', 'selectionStatus$id', 'paymentTerms$id', 'itemsOffered', 'offerAttachments', 'request', 'requestId', 'purchaseOrders', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    getOrderedItems(): { idx: number, item: This.PurchaseVendorOfferItem }[] {
        if (!this.itemsOffered) return []
        return this.itemsOffered.map((item, idx) => ({ idx, item })).filter(({ item }) => item.quantityToOrder)
    }

    getItemById(id: This.PurchaseRequestItem.Id): This.PurchaseVendorOfferItem {
        return this.itemsOffered.find(item => item.requestItemId == id)
    }

    getTotalPrices(): { amount: number, currency: This.Currency }[] {
        const chfTotal = { amount: 0, currency: This.Currency.CHF }
        let otherCurrencyTotal = { amount: 0, currency: new This.Currency }

        for (const item of this.itemsOffered) {
            if (item.currency.isCHF || (otherCurrencyTotal?.currency.id && item.currency.id != otherCurrencyTotal.currency.id)) {
                otherCurrencyTotal = null
            } else {
                otherCurrencyTotal.currency.id = item.currency.id
                otherCurrencyTotal.amount += (item.quantityOffered ?? 0) * (item.unitPrice ?? 0)
            }
            chfTotal.amount += (item.quantityOffered ?? 0) * (item.unitPrice ?? 0) * (item.currency.amountInChf ?? 1)
        }

        const prices = otherCurrencyTotal ? [otherCurrencyTotal, chfTotal] : [chfTotal]
        for (const price of prices) {
            price.amount = Math.ceil(price.amount * 100) / 100
        }

        return prices
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseVendorOffer { }

export import PurchaseVendorOffer = PurchaseToPay__PurchaseVendorOffer
setTimeout(() => {
    jsonMember(() => Boolean)(PurchaseToPay__PurchaseVendorOffer.prototype, 'isValidOffer')
    jsonMember(() => Boolean)(PurchaseToPay__PurchaseVendorOffer.prototype, 'isPreferredOffer')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'commentInternal')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'commentForVendor')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'rfqText')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'offerNumber')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'vendorId')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'vendorName')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'vendorAddress')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'vendorEmail')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'offerAttachmentComment')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'referencedContractIds')
    jsonArrayMember(() => PurchaseToPay.PurchaseVendorOfferItem)(PurchaseToPay__PurchaseVendorOffer.prototype, 'itemsOffered')
    jsonArrayMember(() => PurchaseToPay.PurchaseVendorOfferAttachment)(PurchaseToPay__PurchaseVendorOffer.prototype, 'offerAttachments')
    jsonMember(() => PurchaseToPay.PurchaseRequest)(PurchaseToPay__PurchaseVendorOffer.prototype, 'request')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOffer.prototype, 'requestId')
    jsonArrayMember(() => PurchaseToPay.PurchaseOrder)(PurchaseToPay__PurchaseVendorOffer.prototype, 'purchaseOrders')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOffer.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOffer.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOffer.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOffer.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOffer.prototype, 'versionNumber')
})

export const PurchaseVendorOfferθId = Number;

export type PurchaseVendorOfferθId = number;

namespace PurchaseToPay__PurchaseVendorOffer {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseVendorOffer'>

}
