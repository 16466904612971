// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    offer: This.PurchaseVendorOffer;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-IncludeOfferedItems',
    templateUrl: './IncludeOfferedItems.html',
    styleUrls: ['./IncludeOfferedItems.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class IncludeOfferedItems extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.IncludeOfferedItems';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_424 = (screen: Properties) => ['action', 'item', 'description', 'quantity', 'unitPrice', 'totalPrice'];
    $validators: Record<number, ValidationRules> = {
        436: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        441: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        }
    };
    componentStore = this.storeService.createComponentStore<Properties>({
        offer: this.offer !== undefined ? this.offer : (undefined) as This.PurchaseVendorOffer
    });

    get $inputs() {
        return {
            offer: this.offer
        }
    }

    @Input()
    get offer() {
        return this.screen?.offer
    }

    set offer(offer: This.PurchaseVendorOffer) {
        offer = (offer as any)?.[ProxyTargetSymbol] ?? offer
        this.storeService.setProperty([this.componentStore.name], 'offer', offer)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.offer = this.dialogData.offer ?? this.offer
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen




            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    _DataTableBlock_collectionCode_424($scope: any): any {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.offer.itemsOffered)
    }

    _DataTableColumnBlock_cellText_425_425($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}

        return ''
    }

    async handler_LinkBlock_0_0_0_0_0_0_0_click_removeOfferItem_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeOfferItem(itemIndex, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableColumnBlock_cellText_430_430($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}

        return (item.requestItem?.name ?? '')
    }

    _DataTableColumnBlock_cellText_431_431($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_434_434($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_437_437($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_448_448($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseVendorOfferItem, itemIndex: number } = $scope ?? {}

        return (item.quantityOffered && item.unitPrice ? ((item.quantityOffered * item.unitPrice) + ' ' + item.currency.label) : '')
    }

    async handler_ButtonBlock_0_1_0_0_click_addOfferItem_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addOfferItem($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_addOfferItem($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.offer.itemsOffered.push(new This.PurchaseVendorOfferItem)
        }
    }

    async action_removeOfferItem(itemIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        itemIndex = this.storeService.replaceWithStateVersion(itemIndex, $context)
        {
            screen.offer.itemsOffered.splice(itemIndex, 1)
        }
    }
}
