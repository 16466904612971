import { DestroyRef, Directive, DoCheck, EmbeddedViewRef, Host, Input, OnDestroy, OnInit, Optional, SkipSelf, TemplateRef, ViewContainerRef } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { AbstractScreen } from '../components/abstract-screen'
import { FrontendLanguageBridge } from '../bridges/frontend-language-bridge'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

class FormDirectiveContext {
	constructor(
		public readonly $implicit: FormGroup[],
		public readonly form?: FormGroup,
	) {}
}

@Directive({
	selector: '[lowgileForm]',
	standalone: false,
})
export class FormDirective implements DoCheck, OnInit, OnDestroy {
	private _formName: string = '' // TODO: change, as formName is not really used. !!formName could be exchanged with !_screen
	private _screen?: AbstractScreen

	private forms?: FormGroup[] = undefined
	viewRef?: EmbeddedViewRef<FormDirectiveContext>
	
	constructor(
		private templateRef: TemplateRef<any>,
  		private viewContainer: ViewContainerRef,
		private destroyRef: DestroyRef,
  		@Host() @Optional() @SkipSelf() private parentDirective: FormDirective,
	) {}

	@Input() set lowgileForm(formName: string) {
		if(formName != this._formName) {
			this.forms = undefined
		}
		this._formName = formName
	}

	@Input() @Optional() set lowgileFormScreen(screen: AbstractScreen) {
		this._screen = screen
	}

	ngOnInit(): void {
		this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef)
	}

	ngOnDestroy(): void {
		// console.log('destroy form', this._formName)
	}

	ngDoCheck() {
		const forms = this.getForms()
		Object.assign(this.viewRef!.context, new FormDirectiveContext(forms, forms.at(-1)))
	}

	getForms(): FormGroup[] {
		if(!this.forms) {
			const form = this._formName ? new FormGroup({}) : null
			this.forms = [
				...this._screen?.$forms ?? [],
				...this.parentDirective?.getForms() ?? [],
				...form ? [form] : [],
			]

			FrontendLanguageBridge.languageChanged$.pipe(
				takeUntilDestroyed(this.destroyRef)
			).subscribe(() => form?.updateValueAndValidity())

			// console.log('forms', this.forms)
		}

		return this.forms
	}
}  