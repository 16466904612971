// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => PurchaseRejectionReason.loadInternal(), entryList => {
        PurchaseRejectionReason.θentryList = entryList
        PurchaseRejectionReason.θentryMap = undefined
        PurchaseRejectionReason.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class PurchaseRejectionReason {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: PurchaseRejectionReason.Id;
    static readonly Id = String;
    static θids: ReadonlyArray<PurchaseRejectionReason.Id> = Object.freeze(['damage', 'quantity', 'po']);
    static θentryMap: Readonly<Record<PurchaseRejectionReason.Id, Readonly<PurchaseRejectionReason.Entry>>>;
    static θentryList: ReadonlyArray<PurchaseRejectionReason.Entry>;
    static θchoicesCache = new Map<keyof PurchaseRejectionReason.Entry, Sys.Types.Choice[]>();
    readonly θhasOthers = false;
    static readonly $displayProperty = 'label';
    [immerable] = true;

    get __type(): 'PurchaseToPay.PurchaseRejectionReason' {
        return 'PurchaseToPay.PurchaseRejectionReason'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.PurchaseRejectionReason' {
        return 'PurchaseToPay.PurchaseRejectionReason'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get $displayValue(): string {
        return this.$isOther ? (this as any).$other : String((this as any)[PurchaseRejectionReason.$displayProperty])
    }

    get label() {
        return PurchaseRejectionReason.getEntryMap()[this.id]?.label
    }

    get $isOther() {
        return false
    }

    get isDamage() {
        return this.id == 'damage'
    }

    static get Damage() {
        return new this('damage')
    }

    get isQuantity() {
        return this.id == 'quantity'
    }

    static get Quantity() {
        return new this('quantity')
    }

    get isPO() {
        return this.id == 'po'
    }

    static get PO() {
        return new this('po')
    }

    constructor(id: PurchaseRejectionReason.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        if (!this.θentryList) this.θentryList = this.θgetStaticEntryList()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: PurchaseRejectionReason.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getChoices(labelColumn: keyof PurchaseRejectionReason.Entry = '$displayValue'): Sys.Types.Choice[] {
        if (labelColumn == "$displayValue") labelColumn = PurchaseRejectionReason.$displayProperty
        let options = this.θchoicesCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θchoicesCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<PurchaseRejectionReason.Entry[]> {
        const dynamicEntryList = []
        this.θentryMap = null
        this.θchoicesCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...this.θgetStaticEntryList(), ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new PurchaseRejectionReason(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: PurchaseRejectionReason.Id | PurchaseRejectionReason) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.PurchaseRejectionReason yet:', err.message)
            //throw err
        })
    }

    setDamage() {
        this.id = 'damage'

    }

    setQuantity() {
        this.id = 'quantity'

    }

    setPO() {
        this.id = 'po'

    }

    static θgetStaticEntryList() {
        return Object.freeze([
            Object.freeze({ id: "damage", label: "Damaged Item(s)" }),
            Object.freeze({ id: "quantity", label: "Wrong Quantity" }),
            Object.freeze({ id: "po", label: "Missing Purchase Order" })
        ])
    }
}

export const PurchaseRejectionReasonθId = String;

export type PurchaseRejectionReasonθId = string;

export namespace PurchaseRejectionReason {
    export type Id = 'damage' | 'quantity' | 'po'

    export interface Entry {
        id: string;
        label: string;
        $displayValue?: string;
    }
}
Sys.Language.languageChanged$.subscribe(() => PurchaseRejectionReason.load(true))
