import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms'
import { ValidationRules } from '@shared/types'
import { ValidationUtil } from '../validation/validation-util'

@Pipe({
  name: 'hasValidatorError',
  pure: false,
  standalone: false,
})
export class HasValidatorErrorPipe implements PipeTransform {
  private formControl: FormControl | undefined
  private promise: Promise<boolean> | undefined

  transform(formControl: FormControl, validationRules: ValidationRules | undefined, $scope: any): Promise<boolean> {
	if(!validationRules) {
		this.promise = this.promise ?? Promise.resolve(false)
		return this.promise
	}
		
	if(this.formControl !== formControl || !this.promise) {
		this.promise = new Promise(async res => {
			// await new Promise(resolve => setTimeout(resolve, 1000))
			
			const hasValidationErrors = ValidationUtil.hasValidatorError(formControl, validationRules, $scope)
			res(hasValidationErrors)
		})
		this.formControl = formControl
	}
	return this.promise
  }
}
