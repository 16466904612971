// Compiled at 2025-01-14T22:27:45.765Z
// Auto-generated by Lowgile
import { ApprovePurchaseOrder as PurchaseToPay__ApprovePurchaseOrder } from "@app/Screen/PurchaseToPay/ApprovePurchaseOrder";
import { ApprovePurchaseRequest as PurchaseToPay__ApprovePurchaseRequest } from "@app/Screen/PurchaseToPay/ApprovePurchaseRequest";
import { AwaitDeliveryAndPayment as PurchaseToPay__AwaitDeliveryAndPayment } from "@app/Screen/PurchaseToPay/AwaitDeliveryAndPayment";
import { ClassifyProcurementType as PurchaseToPay__ClassifyProcurementType } from "@app/Screen/PurchaseToPay/ClassifyProcurementType";
import { CompletePoInfo as PurchaseToPay__CompletePoInfo } from "@app/Screen/PurchaseToPay/CompletePoInfo";
import { ConfirmOrder as PurchaseToPay__ConfirmOrder } from "@app/Screen/PurchaseToPay/ConfirmOrder";
import { CreateArInvoice as PurchaseToPay__CreateArInvoice } from "@app/Screen/PurchaseToPay/CreateArInvoice";
import { Dashboard as PurchaseToPay__Dashboard } from "@app/Screen/PurchaseToPay/Dashboard";
import { DefineSchedule as PurchaseToPay__DefineSchedule } from "@app/Screen/PurchaseToPay/DefineSchedule";
import { EnterInvoice as PurchaseToPay__EnterInvoice } from "@app/Screen/PurchaseToPay/EnterInvoice";
import { EvaluateOffers as PurchaseToPay__EvaluateOffers } from "@app/Screen/PurchaseToPay/EvaluateOffers";
import { GoodsReceipt as PurchaseToPay__GoodsReceipt } from "@app/Screen/PurchaseToPay/GoodsReceipt";
import { HandleArInvoiceEvent as PurchaseToPay__HandleArInvoiceEvent } from "@app/Screen/PurchaseToPay/HandleArInvoiceEvent";
import { Home as PurchaseToPay__Home } from "@app/Screen/PurchaseToPay/Home";
import { IncludeApprovals as PurchaseToPay__IncludeApprovals } from "@app/Screen/PurchaseToPay/IncludeApprovals";
import { IncludeOfferedItems as PurchaseToPay__IncludeOfferedItems } from "@app/Screen/PurchaseToPay/IncludeOfferedItems";
import { IncludePurchaseInfo as PurchaseToPay__IncludePurchaseInfo } from "@app/Screen/PurchaseToPay/IncludePurchaseInfo";
import { IncludeRequest as PurchaseToPay__IncludeRequest } from "@app/Screen/PurchaseToPay/IncludeRequest";
import { InvoiceReceipt as PurchaseToPay__InvoiceReceipt } from "@app/Screen/PurchaseToPay/InvoiceReceipt";
import { MainLayout as PurchaseToPay__MainLayout } from "@app/Screen/PurchaseToPay/MainLayout";
import { ManageMasterData as PurchaseToPay__ManageMasterData } from "@app/Screen/PurchaseToPay/ManageMasterData";
import { PortalWorklist as PurchaseToPay__PortalWorklist } from "@app/Screen/PurchaseToPay/PortalWorklist";
import { ProcessLayout as PurchaseToPay__ProcessLayout } from "@app/Screen/PurchaseToPay/ProcessLayout";
import { RaiseRequest as PurchaseToPay__RaiseRequest } from "@app/Screen/PurchaseToPay/RaiseRequest";
import { SearchRequest as PurchaseToPay__SearchRequest } from "@app/Screen/PurchaseToPay/SearchRequest";
import { SelectVendors as PurchaseToPay__SelectVendors } from "@app/Screen/PurchaseToPay/SelectVendors";
import { Test as PurchaseToPay__Test } from "@app/Screen/PurchaseToPay/Test";
import { UserProfile as PurchaseToPay__UserProfile } from "@app/Screen/PurchaseToPay/UserProfile";
import { ViewPurchaseRequest as PurchaseToPay__ViewPurchaseRequest } from "@app/Screen/PurchaseToPay/ViewPurchaseRequest";
import { WaitForOffers as PurchaseToPay__WaitForOffers } from "@app/Screen/PurchaseToPay/WaitForOffers";

export const Declarations = [
    PurchaseToPay__ApprovePurchaseOrder,
    PurchaseToPay__ApprovePurchaseRequest,
    PurchaseToPay__AwaitDeliveryAndPayment,
    PurchaseToPay__ClassifyProcurementType,
    PurchaseToPay__CompletePoInfo,
    PurchaseToPay__ConfirmOrder,
    PurchaseToPay__CreateArInvoice,
    PurchaseToPay__Dashboard,
    PurchaseToPay__DefineSchedule,
    PurchaseToPay__EnterInvoice,
    PurchaseToPay__EvaluateOffers,
    PurchaseToPay__GoodsReceipt,
    PurchaseToPay__HandleArInvoiceEvent,
    PurchaseToPay__Home,
    PurchaseToPay__IncludeApprovals,
    PurchaseToPay__IncludeOfferedItems,
    PurchaseToPay__IncludePurchaseInfo,
    PurchaseToPay__IncludeRequest,
    PurchaseToPay__InvoiceReceipt,
    PurchaseToPay__MainLayout,
    PurchaseToPay__ManageMasterData,
    PurchaseToPay__PortalWorklist,
    PurchaseToPay__ProcessLayout,
    PurchaseToPay__RaiseRequest,
    PurchaseToPay__SearchRequest,
    PurchaseToPay__SelectVendors,
    PurchaseToPay__Test,
    PurchaseToPay__UserProfile,
    PurchaseToPay__ViewPurchaseRequest,
    PurchaseToPay__WaitForOffers
];
