// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseRequestItem {
    @jsonMember(() => PurchaseToPay__PurchaseRequestItem.Id)
    id: PurchaseToPay__PurchaseRequestItem.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "type", "name", "description", "quantity", "unit", "unit$id", "unit$other", "estimatedPriceRange", "knownVendors", "costCenter", "costCenter$id", "itemCode", "itemDescription", "requestLineNumber", "requestedDeliveryDate", "itemCategory", "itemCategory$id", "hasDifferentDeliveryAddress", "deliveryAddress", "overallBudget", "overallBudgetCurrency", "overallBudgetCurrency$id", "purchaseRequest", "purchaseRequestId", "costAllocations", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "type": "string", "name": "string", "description": "string", "quantity": "number", "unit": "PurchaseToPay.Unit", "unit$id": "string", "unit$other": "string", "estimatedPriceRange": "string", "knownVendors": "string", "costCenter": "PurchaseToPay.CostCenter", "costCenter$id": "string", "itemCode": "string", "itemDescription": "string", "requestLineNumber": "string", "requestedDeliveryDate": "Date", "itemCategory": "PurchaseToPay.ItemCategory", "itemCategory$id": "string", "hasDifferentDeliveryAddress": "boolean", "deliveryAddress": "string", "overallBudget": "number", "overallBudgetCurrency": "PurchaseToPay.Currency", "overallBudgetCurrency$id": "string", "purchaseRequest": "PurchaseToPay.PurchaseRequest", "purchaseRequestId": "PurchaseToPay.PurchaseRequest.Id", "costAllocations": "PurchaseToPay.PurchaseRequestItemCostAllocation[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["unit", "costCenter", "itemCategory", "overallBudgetCurrency"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ purchaseRequest: 'PurchaseToPay.PurchaseRequest' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ costAllocations: 'PurchaseToPay.PurchaseRequestItemCostAllocation' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θtype?: string = undefined;
    private θname?: string = undefined;
    private θdescription?: string = undefined;
    private θquantity?: number = undefined;
    private θunit?: This.Unit = This.Unit.Pieces;
    private θestimatedPriceRange?: string = undefined;
    private θknownVendors?: string = undefined;
    private θcostCenter?: This.CostCenter = new This.CostCenter;
    private θitemCode?: string = undefined;
    private θitemDescription?: string = undefined;
    private θrequestLineNumber?: string = undefined;
    private θrequestedDeliveryDate?: Date = undefined;
    private θitemCategory?: This.ItemCategory = new This.ItemCategory;
    private θhasDifferentDeliveryAddress?: boolean = false;
    private θdeliveryAddress?: string = undefined;
    private θoverallBudget?: number = undefined;
    private θoverallBudgetCurrency?: This.Currency = This.Currency.CHF;
    private θpurchaseRequest?: PurchaseToPay.PurchaseRequest = undefined;
    private θpurchaseRequestId?: number = null;
    private θcostAllocations?: PurchaseToPay.PurchaseRequestItemCostAllocation[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        type: 0,
        name: 0,
        description: 0,
        quantity: 0,
        estimatedPriceRange: 0,
        knownVendors: 0,
        itemCode: 0,
        itemDescription: 0,
        requestLineNumber: 0,
        requestedDeliveryDate: 0,
        hasDifferentDeliveryAddress: 0,
        deliveryAddress: 0,
        overallBudget: 0,
        purchaseRequest: 0,
        purchaseRequestId: 0,
        costAllocations: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        type: () => {
            return false
        }
        , name: () => {
            return false
        }
        , description: () => {
            return false
        }
        , quantity: () => {
            return false
        }
        , estimatedPriceRange: () => {
            return false
        }
        , knownVendors: () => {
            return false
        }
        , itemCode: () => {
            return false
        }
        , itemDescription: () => {
            return false
        }
        , requestLineNumber: () => {
            return false
        }
        , requestedDeliveryDate: () => {
            return false
        }
        , hasDifferentDeliveryAddress: () => {
            return false
        }
        , deliveryAddress: () => {
            return false
        }
        , overallBudget: () => {
            return false
        }
        , purchaseRequest: () => {
            return false
        }
        , purchaseRequestId: () => {
            return false
        }
        , costAllocations: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseRequestItem' {
        return 'PurchaseToPay.PurchaseRequestItem'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseRequestItem' {
        return 'PurchaseToPay.PurchaseRequestItem'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseRequestItem' {
        return 'PurchaseToPay.PurchaseRequestItem'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseRequestItem' {
        return 'PurchaseToPay.PurchaseRequestItem'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseRequestItem[EntityInfoSymbol]
    }

    get type() {
        return this.θtype
    }

    set type(type: string) {
        this.θtype = LowgileDataUtil.coerceDataType(type, 'string', false, true)
    }

    get name() {
        return this.θname
    }

    set name(name: string) {
        this.θname = LowgileDataUtil.coerceDataType(name, 'string', false, true)
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get quantity() {
        return this.θquantity
    }

    set quantity(quantity: number) {
        this.θquantity = LowgileDataUtil.coerceDataType(quantity, 'number', false, true)
    }

    get unit(): This.Unit {
        return this.θunit
    }

    set unit(unit: This.Unit | This.Unit.Id) {
        if (!this.θunit) this.θunit = new This.Unit()
        this.θunit.θupdate(unit)
    }

    @jsonMember(String)
    get 'unit$id'(): string {
        return this.θunit?.id
    }

    set 'unit$id'(unit: string) {
        if (!this.θunit) this.θunit = This.Unit.Pieces
        this.θunit.id = unit as any
    }

    @jsonMember(String)
    get 'unit$other'(): string {
        return this.θunit?.$other
    }

    set 'unit$other'(otherValue: string) {
        if (!this.θunit) this.θunit = This.Unit.Pieces
        this.θunit.$other = otherValue
    }

    get estimatedPriceRange() {
        return this.θestimatedPriceRange
    }

    set estimatedPriceRange(estimatedPriceRange: string) {
        this.θestimatedPriceRange = LowgileDataUtil.coerceDataType(estimatedPriceRange, 'string', false, true)
    }

    get knownVendors() {
        return this.θknownVendors
    }

    set knownVendors(knownVendors: string) {
        this.θknownVendors = LowgileDataUtil.coerceDataType(knownVendors, 'string', false, true)
    }

    get costCenter(): This.CostCenter {
        return this.θcostCenter
    }

    set costCenter(costCenter: This.CostCenter | This.CostCenter.Id) {
        if (!this.θcostCenter) this.θcostCenter = new This.CostCenter()
        this.θcostCenter.θupdate(costCenter)
    }

    @jsonMember(String)
    get 'costCenter$id'(): string {
        return this.θcostCenter?.id
    }

    set 'costCenter$id'(costCenter: string) {
        if (!this.θcostCenter) this.θcostCenter = new This.CostCenter
        this.θcostCenter.id = costCenter as any
    }

    get itemCode() {
        return this.θitemCode
    }

    set itemCode(itemCode: string) {
        this.θitemCode = LowgileDataUtil.coerceDataType(itemCode, 'string', false, true)
    }

    get itemDescription() {
        return this.θitemDescription
    }

    set itemDescription(itemDescription: string) {
        this.θitemDescription = LowgileDataUtil.coerceDataType(itemDescription, 'string', false, true)
    }

    get requestLineNumber() {
        return this.θrequestLineNumber
    }

    set requestLineNumber(requestLineNumber: string) {
        this.θrequestLineNumber = LowgileDataUtil.coerceDataType(requestLineNumber, 'string', false, true)
    }

    get requestedDeliveryDate() {
        return this.θrequestedDeliveryDate
    }

    set requestedDeliveryDate(requestedDeliveryDate: Date) {
        this.θrequestedDeliveryDate = LowgileDataUtil.coerceDataType(requestedDeliveryDate, 'Date', false, true)
    }

    get itemCategory(): This.ItemCategory {
        return this.θitemCategory
    }

    set itemCategory(itemCategory: This.ItemCategory | This.ItemCategory.Id) {
        if (!this.θitemCategory) this.θitemCategory = new This.ItemCategory()
        this.θitemCategory.θupdate(itemCategory)
    }

    @jsonMember(String)
    get 'itemCategory$id'(): string {
        return this.θitemCategory?.id
    }

    set 'itemCategory$id'(itemCategory: string) {
        if (!this.θitemCategory) this.θitemCategory = new This.ItemCategory
        this.θitemCategory.id = itemCategory as any
    }

    get hasDifferentDeliveryAddress() {
        return this.θhasDifferentDeliveryAddress
    }

    set hasDifferentDeliveryAddress(hasDifferentDeliveryAddress: boolean) {
        this.θhasDifferentDeliveryAddress = LowgileDataUtil.coerceDataType(hasDifferentDeliveryAddress, 'boolean', false, true)
    }

    get deliveryAddress() {
        return this.θdeliveryAddress
    }

    set deliveryAddress(deliveryAddress: string) {
        this.θdeliveryAddress = LowgileDataUtil.coerceDataType(deliveryAddress, 'string', false, true)
    }

    get overallBudget() {
        return this.θoverallBudget
    }

    set overallBudget(overallBudget: number) {
        this.θoverallBudget = LowgileDataUtil.coerceDataType(overallBudget, 'number', false, true)
    }

    get overallBudgetCurrency(): This.Currency {
        return this.θoverallBudgetCurrency
    }

    set overallBudgetCurrency(overallBudgetCurrency: This.Currency | This.Currency.Id) {
        if (!this.θoverallBudgetCurrency) this.θoverallBudgetCurrency = new This.Currency()
        this.θoverallBudgetCurrency.θupdate(overallBudgetCurrency)
    }

    @jsonMember(String)
    get 'overallBudgetCurrency$id'(): string {
        return this.θoverallBudgetCurrency?.id
    }

    set 'overallBudgetCurrency$id'(overallBudgetCurrency: string) {
        if (!this.θoverallBudgetCurrency) this.θoverallBudgetCurrency = This.Currency.CHF
        this.θoverallBudgetCurrency.id = overallBudgetCurrency as any
    }

    get purchaseRequest() {
        return this.θpurchaseRequest
    }

    set purchaseRequest(purchaseRequest: PurchaseToPay.PurchaseRequest) {
        this.θpurchaseRequest = LowgileDataUtil.coerceDataType(purchaseRequest, PurchaseToPay.PurchaseRequest, false, true)
    }

    get purchaseRequestId() {
        return this.θpurchaseRequestId
    }

    set purchaseRequestId(purchaseRequestId: number) {
        this.θpurchaseRequestId = LowgileDataUtil.coerceDataType(purchaseRequestId, 'number', false, true)
    }

    get costAllocations() {
        return this.θcostAllocations
    }

    set costAllocations(costAllocations: PurchaseToPay.PurchaseRequestItemCostAllocation[]) {
        this.θcostAllocations = LowgileDataUtil.coerceDataType(costAllocations, PurchaseToPay.PurchaseRequestItemCostAllocation, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseRequestItem.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseRequestItem>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseRequestItem(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseRequestItem>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseRequestItem>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('type', 'name', 'description', 'quantity', 'unit$id', 'unit$other', 'estimatedPriceRange', 'knownVendors', 'costCenter$id', 'itemCode', 'itemDescription', 'requestLineNumber', 'requestedDeliveryDate', 'itemCategory$id', 'hasDifferentDeliveryAddress', 'deliveryAddress', 'overallBudget', 'overallBudgetCurrency$id', 'purchaseRequest', 'purchaseRequestId', 'costAllocations', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    getUnallocatedAmount(): number {
        const allocatedAmount = this.costAllocations?.reduce((acc, allocation) => acc + parseFloat(allocation.amount as any ?? 0), 0) ?? 0
        return (this.quantity ?? 0) * (this.overallBudget ?? 0) - allocatedAmount
    }

    θcleanupBeforeSave() {
        if (!this.unit.$isOther) this.unit.$other = null
    }
}

namespace PurchaseToPay__PurchaseRequestItem { }

export import PurchaseRequestItem = PurchaseToPay__PurchaseRequestItem
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'type')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'name')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'description')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequestItem.prototype, 'quantity')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'estimatedPriceRange')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'knownVendors')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'itemCode')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'itemDescription')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'requestLineNumber')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequestItem.prototype, 'requestedDeliveryDate')
    jsonMember(() => Boolean)(PurchaseToPay__PurchaseRequestItem.prototype, 'hasDifferentDeliveryAddress')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'deliveryAddress')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequestItem.prototype, 'overallBudget')
    jsonMember(() => PurchaseToPay.PurchaseRequest)(PurchaseToPay__PurchaseRequestItem.prototype, 'purchaseRequest')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequestItem.prototype, 'purchaseRequestId')
    jsonArrayMember(() => PurchaseToPay.PurchaseRequestItemCostAllocation)(PurchaseToPay__PurchaseRequestItem.prototype, 'costAllocations')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequestItem.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequestItem.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequestItem.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequestItem.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequestItem.prototype, 'versionNumber')
})

export const PurchaseRequestItemθId = Number;

export type PurchaseRequestItemθId = number;

namespace PurchaseToPay__PurchaseRequestItem {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseRequestItem'>

}
