import { Component, ElementRef, Input, Output, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation, EventEmitter, ApplicationRef, forwardRef, ChangeDetectorRef, TrackByFunction } from '@angular/core';
import { FormBinding } from '@ng-shared/lib/pipes/form-binding.pipe'
import { SearchableDropdownBindingMode } from '@shared/blocks/form/searchable-dropdown-block'
import { DataQueryFilter } from '@shared/data/data-query-filter'
import { EntityClassOnDb, EntityInstanceOnDb, FindManyOptions, ServerDataStoreClass } from '@shared/types'
import { BehaviorSubject, switchMap, of, from, Subscription, Observable, Subject, debounceTime } from 'rxjs'

@Component({
	selector: 'lowgile-searchable-dropdown',
	templateUrl: './searchable-dropdown.component.html',
	styleUrls: ['./searchable-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: false,
})
export class SearchableDropdownComponent<T extends EntityInstanceOnDb> implements OnInit, OnDestroy {
	protected text = ''
	protected searchSubject = new BehaviorSubject<string | null>(null)
	value: T | null = null
	index: number = -1
	protected list: T[] = []

	@Input() data?: T[]
	@Input() label!: string
	@Input() bindingMode: SearchableDropdownBindingMode = 'object'
	@Input() formBinding!: FormBinding
	@Input() entity!: EntityClassOnDb
	@Input() serverDataStore!: ServerDataStoreClass
	@Input() minSearchLength = 3
	@Input() maxResults? = 20
	@Input() displayWith!: (input: T | null, index?: number) => string
	@Input() debouncingDelayMs = 250
	@Input() findOptions?: FindManyOptions<any>
	@Input() trackBy!: TrackByFunction<T>
	@Input() allowClearing: boolean = true
	@Input() allClasses: Record<string, Record<string, any>> = {}

	@Output() optionSelected = new EventEmitter<T | null>()

	protected onChanged?: (value: T['id']) => void
	private subscriptions: Subscription[] = []

	constructor(
		private cdRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.updateInternalValue(this.formBinding.formControl.value)

		this.subscriptions.push(
			this.formBinding.formControl.valueChanges.subscribe(id => this.updateInternalValue(id)),

			this.searchSubject.pipe(
				debounceTime(this.debouncingDelayMs),
				switchMap(text => this.executeSearch(text)),
			).subscribe(list => {
				this.list = list
				this.cdRef.markForCheck()
			}),
		)
	}
	
	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe())
	}

	onKeyUp(event: KeyboardEvent) {
		const text = (event.target as HTMLInputElement).value
		if(text != this.text) {
			this.text = text
			this.searchSubject.next(text)
		}
	}

	onSelected(value: T | null) {
		this.value = value

		this.formBinding.formControl.setValue(this.value?.id);
		(this.formBinding.object as any)[this.formBinding.property] = this.value?.id
		this.optionSelected.emit(this.value)
	}

	async updateInternalValue(id: T['id']) {
		const foundObject = this.findObjectWithId(id)
		this.value = foundObject?.value ?? null
		this.index = foundObject?.index ?? -1

		if(!this.value && id) {
			this.value = await this.serverDataStore.loadById<T>(this.entity, id)
			this.list = [this.value!]
		}
		this.text = this.value ? this.displayWith(this.value, this.index) ?? '' : ''
		this.cdRef.markForCheck()
	}

	private executeSearch(text: string | null) {
		if(text == null || text.length < this.minSearchLength) return of([] as T[])

		if(this.data) {
			const filter = new DataQueryFilter(this.entity, {
				query: text,
			}, this.allClasses)
			return of(filter.filterList(this.data))
		}

		const loadPromise = this.serverDataStore.loadByQuery<T>(this.entity, {
			query: text,
		}, {
			...this.findOptions,
			take: (this.maxResults || this.findOptions?.take) ?? 0,
		})
		return from(loadPromise)
	}

	private findObjectWithId(id: T['id']): { value: T, index: number } | null {
		if(!id) return null
		if(this.value?.id === id) return { value: this.value, index: this.index }
		
		const index = this.list?.findIndex(item => item.id === id)
		if(index >= 0) return { value: this.list[index], index }

		return null
	}
}
