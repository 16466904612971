// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../../../node_modules/cropperjs/dist/cropper.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video, img {
  z-index: 1;
}

img {
  display: block;
  max-width: 100%;
}

.hidden {
  display: none;
}

.video-and-image, video, img {
  position: relative;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImltYWdlLXNlbGVjdG9yLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFRLG9DQUFBO0FBRVI7RUFDQyxVQUFBO0FBQUQ7O0FBR0E7RUFDQyxjQUFBO0VBQ0EsZUFBQTtBQUFEOztBQUdBO0VBQ0MsYUFBQTtBQUFEOztBQUdBO0VBQ0Msa0JBQUE7RUFDQSxXQUFBO0FBQUQiLCJmaWxlIjoiaW1hZ2Utc2VsZWN0b3IuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICdjcm9wcGVyanMvZGlzdC9jcm9wcGVyLmNzcyc7XG5cbnZpZGVvLCBpbWcge1xuXHR6LWluZGV4OiAxO1xufVxuXG5pbWcge1xuXHRkaXNwbGF5OiBibG9jaztcblx0bWF4LXdpZHRoOiAxMDAlOztcbn1cblxuLmhpZGRlbiB7XG5cdGRpc3BsYXk6IG5vbmU7XG59XG5cbi52aWRlby1hbmQtaW1hZ2UsIHZpZGVvLCBpbWcge1xuXHRwb3NpdGlvbjogcmVsYXRpdmU7XG5cdHdpZHRoOiAxMDAlO1xufVxuXG4vLyB2aWRlbywgaW1nIHtcbi8vIFx0cG9zaXRpb246IGFic29sdXRlO1xuLy8gXHRpbnNldDogMDtcbi8vIH0iXX0= */`, "",{"version":3,"sources":["webpack://./../../../lowgile/libs/ng-shared/src/lib/components/image-selector/image-selector.component.scss"],"names":[],"mappings":"AAEA;EACC,UAAA;AAAD;;AAGA;EACC,cAAA;EACA,eAAA;AAAD;;AAGA;EACC,aAAA;AAAD;;AAGA;EACC,kBAAA;EACA,WAAA;AAAD;AACA,4xBAA4xB","sourcesContent":["@import 'cropperjs/dist/cropper.css';\n\nvideo, img {\n\tz-index: 1;\n}\n\nimg {\n\tdisplay: block;\n\tmax-width: 100%;;\n}\n\n.hidden {\n\tdisplay: none;\n}\n\n.video-and-image, video, img {\n\tposition: relative;\n\twidth: 100%;\n}\n\n// video, img {\n// \tposition: absolute;\n// \tinset: 0;\n// }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
