import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, computed, inject } from '@angular/core';
import { TranslationService } from '@ng-shared/lib/services/translation.service';
import { Subscription, map, scan, shareReplay, skip } from 'rxjs';
import { AppContext } from '../../app-context'
import { FrontendLanguageBridge } from '@ng-shared/lib/bridges/frontend-language-bridge'
import { BuildConfiguration, BuildStatus } from '@shared/build/build-configuration'
import { StoreService } from '@ng-shared/lib/store/store.service'
import { DevBarService } from '@ng-shared/lib/services/dev-bar.service'
import { LayoutService } from '@ng-shared/lib/services/layout-service'
import { AppTrpcService } from '../../services/app-trpc.service'
import { BoReference } from '@shared/bos/bo-reference'

@Component({
  selector: 'dev-bar',
  templateUrl: './dev-bar.component.html',
  styleUrls: ['./dev-bar.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DevBarComponent implements OnInit, OnDestroy {
  private trpc = inject(AppTrpcService)
  subscriptions: Subscription[] = []
  languages = AppContext.languages
  languageBridge = FrontendLanguageBridge
  buildStatus$ = this.trpc.subscriptionAsObservable(c => c.build.getStatus$, {
    branchName: AppContext.branchName,
    appModuleId: AppContext.appModuleId,
  }).pipe(
    shareReplay(1),
  )
  buildStatusColor$ = this.buildStatus$.pipe(
    scan((prev, buildStatus) => {
      if(prev.buildStatus && buildStatus.status == 'Running') {
        return { dirty: true, buildStatus }
      }
      return { dirty: false, buildStatus }
    }, { dirty: false, buildStatus: null } as { dirty: boolean, buildStatus: BuildStatus | null }),
    map(({ dirty, buildStatus }) => dirty ? 'greenyellow' : buildStatus?.bgColor),
    shareReplay(1),
  )

  isHandset = true
  
  constructor(
    public devBarService: DevBarService,
    public translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
    private storeService: StoreService,
    private layoutService: LayoutService,
  ) {
    this.buildStatus$.pipe(
      skip(1),
    ).subscribe(buildStatus => {
      if(buildStatus.status == 'Running' && this.devBarService.settings().isAutoReload) {
        this.reloadApp(this.devBarService.settings().isKeepData)
      }
    })
  }

  async ngOnInit() {
    await this.devBarService.init()
    if(!this.devBarService.canUseDevBar) return

    this.layoutService.breakpoints$.subscribe(breakpoints => this.isHandset = breakpoints.isHandset)
    this.cdRef.detectChanges()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  reloadApp(keepData: boolean) {
    if(keepData) {
      this.storeService.saveToSessionStorage()
    }
    window.location.reload()
  }

  openMainScreenEditor() {
    const boRef = BoReference.fromQualifiedName(this.devBarService.mainScreenName()!, 'Screen')
		this.devBarService.openBoEditor(AppContext.branchName, boRef)
  }

  openMainProcessEditor() {
    const boRef = BoReference.fromQualifiedName(this.devBarService.getTopLevelProcessName(), 'Process')
		this.devBarService.openBoEditor(AppContext.branchName, boRef)
  }

  async toggleFloating() {
    this.devBarService.settings.update(settings => ({
      ...settings,
      isFloating: !settings.isFloating,
    }))
    await this.devBarService.saveSettings()
  }
}
