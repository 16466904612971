import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByValue',
  pure: true,
  standalone: false,
})
export class FilterByValuePipe implements PipeTransform {
  transform<T extends any>(list: T[], filterValue: string | undefined, consideredProperties: string): T[] {
    if(!filterValue) return list

	const filterParts = filterValue.toLocaleLowerCase().split(' ').map(part => part.trim()).filter(part => part.length > 0)
	const properties = consideredProperties.split('|')

    const filteredList = list.filter(item => {
	  if(item == null) return false
	  let values: any[] = [item]
	  if(typeof item == 'object') {
		if(consideredProperties == '*') {
			values = Object.values(item)
		} else {
			values = properties.map(p => Reflect.get(item, p))
		}
	  }
	  const valueParts = values.flatMap(value => (value?.toString?.() as string)?.toLocaleLowerCase().split(' ').map(part => part.trim()).filter(part => part.length > 0) ?? [])
  	  
	  return filterParts.every(filterPart => valueParts.some(valuePart => valuePart.includes(filterPart)))
	})

	return filteredList
  }
}
