// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => ExtraType.loadInternal(), entryList => {
        ExtraType.θentryList = entryList
        ExtraType.θentryMap = undefined
        ExtraType.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class ExtraType {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: ExtraType.Id;
    static readonly Id = String;
    static θids: ReadonlyArray<ExtraType.Id> = Object.freeze(['shipping', 'discount']);
    static θentryMap: Readonly<Record<ExtraType.Id, Readonly<ExtraType.Entry>>>;
    static θentryList: ReadonlyArray<ExtraType.Entry>;
    static θchoicesCache = new Map<keyof ExtraType.Entry, Sys.Types.Choice[]>();
    readonly θhasOthers = false;
    static readonly $displayProperty = 'label';
    [immerable] = true;

    get __type(): 'PurchaseToPay.ExtraType' {
        return 'PurchaseToPay.ExtraType'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.ExtraType' {
        return 'PurchaseToPay.ExtraType'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get $displayValue(): string {
        return this.$isOther ? (this as any).$other : String((this as any)[ExtraType.$displayProperty])
    }

    get label() {
        return ExtraType.getEntryMap()[this.id]?.label
    }

    get $isOther() {
        return false
    }

    get isShipping() {
        return this.id == 'shipping'
    }

    static get Shipping() {
        return new this('shipping')
    }

    get isDiscount() {
        return this.id == 'discount'
    }

    static get Discount() {
        return new this('discount')
    }

    constructor(id: ExtraType.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        if (!this.θentryList) this.θentryList = this.θgetStaticEntryList()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: ExtraType.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getChoices(labelColumn: keyof ExtraType.Entry = '$displayValue'): Sys.Types.Choice[] {
        if (labelColumn == "$displayValue") labelColumn = ExtraType.$displayProperty
        let options = this.θchoicesCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θchoicesCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<ExtraType.Entry[]> {
        const dynamicEntryList = []
        this.θentryMap = null
        this.θchoicesCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...this.θgetStaticEntryList(), ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new ExtraType(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: ExtraType.Id | ExtraType) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.ExtraType yet:', err.message)
            //throw err
        })
    }

    setShipping() {
        this.id = 'shipping'

    }

    setDiscount() {
        this.id = 'discount'

    }

    static θgetStaticEntryList() {
        return Object.freeze([
            Object.freeze({ id: "shipping", label: "Shipping Fee" }),
            Object.freeze({ id: "discount", label: "Discount" })
        ])
    }
}

export const ExtraTypeθId = String;

export type ExtraTypeθId = string;

export namespace ExtraType {
    export type Id = 'shipping' | 'discount'

    export interface Entry {
        id: string;
        label: string;
        $displayValue?: string;
    }
}
Sys.Language.languageChanged$.subscribe(() => ExtraType.load(true))
