import { Component, ElementRef, Input } from '@angular/core'

@Component({
	selector: 'inline-comment',
	template: '',
	standalone: false,
})
export class InlineCommentComponent {
	constructor(
		private elementRef: ElementRef<any>,
	) {}

	@Input() set comment(comment: string) {
		this.elementRef.nativeElement.innerHTML = `<!-- ${comment} -->`
	}
}