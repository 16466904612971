// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .NgxEditor__Content--Disabled {
  user-select: inherit;
  pointer-events: inherit;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJpY2gtdGV4dC1lZGl0b3IuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFFQyxvQkFBQTtFQUNBLHVCQUFBO0FBQ0QiLCJmaWxlIjoicmljaC10ZXh0LWVkaXRvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IDo6bmctZGVlcCAuTmd4RWRpdG9yX19Db250ZW50LS1EaXNhYmxlZCB7XG5cdC13ZWJraXQtdXNlci1zZWxlY3Q6IGluaGVyaXQ7XG5cdHVzZXItc2VsZWN0OiBpbmhlcml0O1xuXHRwb2ludGVyLWV2ZW50czogaW5oZXJpdDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./../../../lowgile/libs/ng-shared/src/lib/components/rich-text-editor/rich-text-editor.component.scss"],"names":[],"mappings":"AAAA;EAEC,oBAAA;EACA,uBAAA;AACD;AAAA,wdAAwd","sourcesContent":[":host ::ng-deep .NgxEditor__Content--Disabled {\n\t-webkit-user-select: inherit;\n\tuser-select: inherit;\n\tpointer-events: inherit;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
