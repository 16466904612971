// Compiled at 2025-01-14T21:36:14.508Z
import { BaseJsonMapper } from "@shared/data/base-json-mapper";
import type { FindOptionsOrder, FindOptionsWhere } from "typeorm";
import type { FindManyOptions, FindManyPagedOptions, PagingDefinition, PagedData, DataQuery, SavedIds, EntityClass, EntityClassOnDb, GroupedCount, AggregationInput, AggregationOutput, allowedAggregationFunctions } from "@shared/types";
import { BoTypeSymbol } from "@shared/types";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import type { IQueryBuilder as QueryBuilder } from "@shared/data/query-builder-interface";
import type { And, Any, ArrayContainedBy, ArrayContains, ArrayOverlap, Between, Equal, ILike, In, IsNull, LessThan, LessThanOrEqual, Like, MoreThan, MoreThanOrEqual, Not } from "typeorm";

const jsonMapper = new BaseJsonMapper(false, {});

export class Db {
    static readonly [BoTypeSymbol] = 'ServerDataStore';
    readonly [BoTypeSymbol] = 'ServerDataStore';
    static readonly And: typeof And = (value) => ({ _type: 'and', _value: value, _useParameter: true, _multipleParameters: true }) as any;
    static readonly Any: typeof Any = (value) => ({ _type: 'any', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly ArrayContainedBy: typeof ArrayContainedBy = (value) => ({ _type: 'arrayContainedBy', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly ArrayContains: typeof ArrayContains = (value) => ({ _type: 'arrayContains', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly ArrayOverlap: typeof ArrayOverlap = (value) => ({ _type: 'arrayOverlap', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly Between: typeof Between = (from, to) => ({ _type: 'between', _value: [from, to], _useParameter: true, _multipleParameters: true }) as any;
    static readonly Equal: typeof Equal = (value) => ({ _type: 'equal', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly ILike: typeof ILike = (value) => ({ _type: 'ilike', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly In: typeof In = (value) => ({ _type: 'in', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly IsNull: typeof IsNull = () => ({ _type: 'isNull', _value: undefined, _useParameter: false, _multipleParameters: false }) as any;
    static readonly LessThan: typeof LessThan = (value) => ({ _type: 'lessThan', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly LessThanOrEqual: typeof LessThanOrEqual = (value) => ({ _type: 'lessThanOrEqual', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly Like: typeof Like = (value) => ({ _type: 'like', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly MoreThan: typeof MoreThan = (value) => ({ _type: 'moreThan', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly MoreThanOrEqual: typeof MoreThanOrEqual = (value) => ({ _type: 'moreThanOrEqual', _value: value, _useParameter: true, _multipleParameters: false }) as any;
    static readonly Not: typeof Not = (value) => ({ _type: 'not', _value: value, _useParameter: true, _multipleParameters: false }) as any;

    static get __type() {
        return 'PurchaseToPay.Db'
    }

    static get __boType(): 'ServerDataStore' {
        return 'ServerDataStore'
    }

    static async loadById<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, id: IdType<InstanceType<T>>, findOptions?: Omit<FindManyOptions<InstanceType<T>>, 'where'>): Promise<InstanceType<T> | null> {
        const obj = await ExecutionService.executeMethodAndReturnData<InstanceType<T>>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadById', [type.__type, id, findOptions])
        return obj
    }

    static async loadByIdOrFail<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, id: IdType<InstanceType<T>>, findOptions?: Omit<FindManyOptions<InstanceType<T>>, 'where'>): Promise<InstanceType<T>> {
        const obj = await ExecutionService.executeMethodAndReturnData<InstanceType<T>>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadByIdOrFail', [type.__type, id, findOptions])
        return obj
    }

    static async loadByIds<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, ids: IdType<InstanceType<T>>[], findOptions?: Omit<FindManyOptions<InstanceType<T>>, 'where'>, removeEmptyResults = false): Promise<InstanceType<T>[]> {
        const objs = await ExecutionService.executeMethodAndReturnData<InstanceType<T>[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadByIds', [type.__type, ids, findOptions, removeEmptyResults])
        return objs
    }

    static async loadByCondition<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem>(type: EntityClassOnDb<T>, findOptions?: FindManyOptions<T>): Promise<T[]> {
        const anyType = type as any
        const objs = await ExecutionService.executeMethodAndReturnData<T[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadByCondition', [anyType.__type, findOptions])
        return objs
    }

    static async loadPageByCondition<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem>(type: EntityClassOnDb<T>, paging: PagingDefinition, findOptions?: FindManyPagedOptions<T>): Promise<PagedData<T>> {
        const anyType = type as any
        const page = await ExecutionService.executeMethodAndReturnData<PagedData<T>>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadPageByCondition', [anyType.__type, paging, findOptions])
        return page
    }

    static async loadByQuery<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem>(type: EntityClassOnDb<T>, query?: DataQuery<T>, findOptions?: FindManyOptions<T>): Promise<T[]> {
        const anyType = type as any
        const objs = await ExecutionService.executeMethodAndReturnData<T[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadByQuery', [anyType.__type, query, findOptions])
        return objs
    }

    static async loadPageByQuery<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem>(type: EntityClassOnDb<T>, paging: PagingDefinition, query?: DataQuery<T>, findOptions?: FindManyPagedOptions<T>): Promise<PagedData<T>> {
        const anyType = type as any
        const page = await ExecutionService.executeMethodAndReturnData<PagedData<T>>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadPageByQuery', [anyType.__type, paging, query, findOptions])
        return page
    }

    static async loadByVectorDistance<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem>(type: EntityClassOnDb<T>, vectorPropertyName: keyof T & string, searchVector: number[], operator?: Sys.Types.DbVectorOperator, findOptions?: Omit<FindManyOptions<T>, 'order'>, order?: 'ASC' | 'DESC'): Promise<T[]> {
        const anyType = type as any
        const objs = await ExecutionService.executeMethodAndReturnData<T[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadByVectorDistance', [anyType.__type, vectorPropertyName, searchVector, operator, findOptions, order])
        return objs
    }

    static async loadByVectorDistanceIncludingDistance<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem>(type: EntityClassOnDb<T>, vectorPropertyName: keyof T & string, searchVector: number[], operator?: Sys.Types.DbVectorOperator, findOptions?: Omit<FindManyOptions<T>, 'order'>, order?: 'ASC' | 'DESC'): Promise<{ data: T, distance: number }[]> {
        const anyType = type as any
        const objs = await ExecutionService.executeMethodAndReturnData<{ data: T, distance: number }[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'loadByVectorDistanceIncludingDistance', [anyType.__type, vectorPropertyName, searchVector, operator, findOptions, order])
        return objs
    }

    static async aggregate<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem, G extends keyof T, A extends typeof allowedAggregationFunctions[number], K extends keyof T, O extends string>(type: EntityClassOnDb<T>, groupBy: G[], aggregations: AggregationInput<T, A, K, O>[], findOptions?: FindManyOptions<T>): Promise<AggregationOutput<T, A, G, K, O>[]> {
        const anyType = type as any
        const objs = await ExecutionService.executeMethodAndReturnData<AggregationOutput<T, A, G, K, O>[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'aggregate', [anyType.__type, groupBy, aggregations, findOptions])
        return objs
    }

    static async count<T extends PurchaseToPay.ArDispute | PurchaseToPay.ArEmailTemplate | PurchaseToPay.ArInvoice | PurchaseToPay.ArSchedule | PurchaseToPay.ArScheduleEvent | PurchaseToPay.ArScheduleEventLog | PurchaseToPay.Customer | PurchaseToPay.ExchangeRate | PurchaseToPay.PurchaseGoodsReceiptNote | PurchaseToPay.PurchaseGoodsReceiptNoteItem | PurchaseToPay.PurchaseInvoice | PurchaseToPay.PurchaseInvoiceCostAllocation | PurchaseToPay.PurchaseInvoiceItem | PurchaseToPay.PurchaseInvoiceItemCostAllocation | PurchaseToPay.PurchaseOrder | PurchaseToPay.PurchaseOrderApproval | PurchaseToPay.PurchaseOrderExtra | PurchaseToPay.PurchaseOrderItem | PurchaseToPay.PurchaseOrderItemExtra | PurchaseToPay.PurchaseRequest | PurchaseToPay.PurchaseRequest.ForDropdown | PurchaseToPay.PurchaseRequestApproval | PurchaseToPay.PurchaseRequestEvaluationAttachment | PurchaseToPay.PurchaseRequestItem | PurchaseToPay.PurchaseRequestItemCostAllocation | PurchaseToPay.PurchaseRequisition | PurchaseToPay.PurchaseRequisitionApproval | PurchaseToPay.PurchaseRequisitionItem | PurchaseToPay.PurchaseVendor | PurchaseToPay.PurchaseVendorOffer | PurchaseToPay.PurchaseVendorOfferAttachment | PurchaseToPay.PurchaseVendorOfferItem, G extends keyof T>(type: EntityClassOnDb<T>, groupBy?: G[], findOptions?: FindManyOptions<T>): Promise<GroupedCount<T, G>[]> {
        const anyType = type as any
        const objs = await ExecutionService.executeMethodAndReturnData<GroupedCount<T, G>[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'count', [anyType.__type, groupBy, findOptions])
        return objs
    }

    static async updateById<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, id: IdType<InstanceType<T>>, updateProperties: Partial<InstanceType<T>>): Promise<number | null> {
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'updateById', [type.__type, id, updateProperties])
        return affected
    }

    static async updateByIds<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, ids: IdType<InstanceType<T>>[], updateProperties: Partial<InstanceType<T>>): Promise<number | null> {
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'updateByIds', [type.__type, ids, updateProperties])
        return affected
    }

    static async updateByCondition<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, where: FindOptionsWhere<InstanceType<T>> | FindOptionsWhere<InstanceType<T>>[] | '*', updateProperties: Partial<InstanceType<T>>): Promise<number | null> {
        const anyType = type as any
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'updateByCondition', [anyType.__type, where, updateProperties])
        return affected
    }

    static async upsert<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, objs: InstanceType<T>[], propertiesToMatchBy: [keyof InstanceType<T>, ...(keyof InstanceType<T>)[]]): Promise<SavedIds<InstanceType<T>>[]> {
        const anyType = type as any
        const savedIds = await ExecutionService.executeMethodAndReturnData<SavedIds<InstanceType<T>>[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'upsert', [anyType.__type, objs, propertiesToMatchBy])
        objs.forEach((obj, idx) => this.applySavedIds(obj, savedIds[idx]))
        return savedIds
    }

    static async saveOne<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(obj: InstanceType<T>): Promise<SavedIds<InstanceType<T>>> {
        const anyObj = obj as any
        const beforeSave = (anyObj.id ? anyObj.beforeUpdate : anyObj.beforeInsert) ?? anyObj.beforeSave
        if (typeof beforeSave == 'function') await beforeSave.call(obj)
        const savedIds = await ExecutionService.executeMethodAndReturnData<SavedIds<InstanceType<T>>>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'saveOne', [obj])
        this.applySavedIds(obj, savedIds)
        return savedIds
    }

    static async saveMany<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(objs: InstanceType<T>[]): Promise<SavedIds<InstanceType<T>>[]> {
        await Promise.all(objs.map(async obj => {
            const anyObj = obj as any
            const beforeSave = (anyObj.id ? anyObj.beforeUpdate : anyObj.beforeInsert) ?? anyObj.beforeSave
            if (typeof beforeSave == 'function') await beforeSave.call(obj)
        }))
        const savedIds = await ExecutionService.executeMethodAndReturnData<SavedIds<InstanceType<T>>[]>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'saveMany', [objs])
        objs.forEach((obj, idx) => this.applySavedIds(obj, savedIds[idx]))
        return savedIds
    }

    static async deleteOne<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(obj: InstanceType<T>): Promise<number | null> {
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'deleteOne', [{ __type: obj.__type, id: obj.id }])
        return affected
    }

    static async deleteMany<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(objs: InstanceType<T>[]): Promise<number | null> {
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'deleteMany', [objs.map(obj => ({ __type: obj.__type, id: obj.id }))])
        return affected
    }

    static async deleteById<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, id: IdType<InstanceType<T>>): Promise<number | null> {
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'deleteById', [type.__type, id])
        return affected
    }

    static async deleteByIds<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, ids: IdType<InstanceType<T>>[]): Promise<number | null> {
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'deleteByIds', [type.__type, ids])
        return affected
    }

    static async deleteByCondition<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(type: T, where: FindOptionsWhere<InstanceType<T>> | FindOptionsWhere<InstanceType<T>>[] | '*'): Promise<number | null> {
        const anyType = type as any
        const affected = await ExecutionService.executeMethodAndReturnData<number | null>('ServerDataStore', 'PurchaseToPay', 'Db', this, 'deleteByCondition', [anyType.__type, where])
        return affected
    }

    static applySavedIds<T extends typeof PurchaseToPay.ArDispute | typeof PurchaseToPay.ArEmailTemplate | typeof PurchaseToPay.ArInvoice | typeof PurchaseToPay.ArSchedule | typeof PurchaseToPay.ArScheduleEvent | typeof PurchaseToPay.ArScheduleEventLog | typeof PurchaseToPay.Customer | typeof PurchaseToPay.ExchangeRate | typeof PurchaseToPay.PurchaseGoodsReceiptNote | typeof PurchaseToPay.PurchaseGoodsReceiptNoteItem | typeof PurchaseToPay.PurchaseInvoice | typeof PurchaseToPay.PurchaseInvoiceCostAllocation | typeof PurchaseToPay.PurchaseInvoiceItem | typeof PurchaseToPay.PurchaseInvoiceItemCostAllocation | typeof PurchaseToPay.PurchaseOrder | typeof PurchaseToPay.PurchaseOrderApproval | typeof PurchaseToPay.PurchaseOrderExtra | typeof PurchaseToPay.PurchaseOrderItem | typeof PurchaseToPay.PurchaseOrderItemExtra | typeof PurchaseToPay.PurchaseRequest | typeof PurchaseToPay.PurchaseRequest.ForDropdown | typeof PurchaseToPay.PurchaseRequestApproval | typeof PurchaseToPay.PurchaseRequestEvaluationAttachment | typeof PurchaseToPay.PurchaseRequestItem | typeof PurchaseToPay.PurchaseRequestItemCostAllocation | typeof PurchaseToPay.PurchaseRequisition | typeof PurchaseToPay.PurchaseRequisitionApproval | typeof PurchaseToPay.PurchaseRequisitionItem | typeof PurchaseToPay.PurchaseVendor | typeof PurchaseToPay.PurchaseVendorOffer | typeof PurchaseToPay.PurchaseVendorOfferAttachment | typeof PurchaseToPay.PurchaseVendorOfferItem>(obj: InstanceType<T>, savedIds: SavedIds<InstanceType<T>>) {

        for (const [key, savedIdValue] of Object.entries(savedIds)) {
            if (typeof savedIdValue == 'number' || typeof savedIdValue == 'string') {
                obj[key] = savedIdValue
                continue
            }
            if (Array.isArray(savedIdValue)) {
                const savedIdValues = savedIdValue
                savedIdValues.forEach((itemIdValue, idx) => this.applySavedIds(obj[key][idx], itemIdValue))
                continue
            }
            if (savedIdValue && typeof savedIdValue == 'object') {
                this.applySavedIds(obj[key], savedIdValue)
                continue
            }

            throw new Error(`Invalid saved id for ${key} in object ${obj}`)
        }

    }
}

export namespace Db {
}
