import { Directive, Input, OnDestroy, OnInit, forwardRef } from '@angular/core'
import { NgControl, AbstractControlDirective, FormControlDirective, FormControl } from '@angular/forms'
import { MatFormFieldControl } from '@angular/material/form-field'
import { Observable, Subject, Subscription } from 'rxjs'

@Directive({
	selector: '[lowgileFormField]',
	providers: [
		{ provide: MatFormFieldControl, useExisting: forwardRef(() => FormFieldDirective) },
	],
	standalone: false,
})
export class FormFieldDirective implements MatFormFieldControl<any>, OnInit, OnDestroy {
	@Input('lowgileFormField') set lowgileFormField(formControl: FormControl) {
		this.formControl = formControl
		this.reregisterListeners()
	}

	@Input('lowgileFormFieldRequired') set lowgileFormFieldRequired(required: boolean) {
		this.required = required
		this.stateChangesSubject.next()
	}

	private formControl?: FormControl
	private subscriptions: Subscription[] = []
	private stateChangesSubject = new Subject<void>()
	
	get value() { return this.formControl?.value}
	stateChanges: Observable<void> = this.stateChangesSubject.asObservable()
	id: string = ''
	placeholder: string = ''
	ngControl: NgControl | AbstractControlDirective | null = null
	focused: boolean = false
	empty: boolean = false
	shouldLabelFloat: boolean = true
	required: boolean = false
	get disabled(): boolean { return !!this.formControl?.disabled }
	get errorState(): boolean { return !!this.formControl?.errors }
	controlType?: string | undefined
	autofilled?: boolean | undefined
	userAriaDescribedBy?: string | undefined
	setDescribedByIds(ids: string[]): void {
	}
	onContainerClick(event: MouseEvent): void {
	}

	private reregisterListeners() {
		if(!this.formControl) return
		this.subscriptions.forEach(s => s.unsubscribe())
		this.subscriptions = []

		this.subscriptions.push(
			this.formControl.valueChanges.subscribe(() => this.stateChangesSubject.next()),
			this.formControl.statusChanges.subscribe(() => this.stateChangesSubject.next()),
			)
		this.formControl.registerOnDisabledChange(() => this.stateChangesSubject.next())

		this.stateChangesSubject.next()
	}

	ngOnInit(): void {
		// this.reregisterListeners()
	}
	
	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe())
	}
}