// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    request: This.PurchaseRequest;
    allUsers: { id: string, name: string }[];
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-IncludeRequest',
    templateUrl: './IncludeRequest.html',
    styleUrls: ['./IncludeRequest.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class IncludeRequest extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.IncludeRequest';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_119 = (screen: Properties) => ['actions', 'costAccount', 'costAllocation', 'amount'];
    $validators: Record<number, ValidationRules> = {
        13: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        10: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        86: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        57: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        94: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        130: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        145: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        147: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        123: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        150: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope
                if (item.costAllocations.length) return
                return {
                    ruleId: 'costAllocations',
                    error: 'Please enter at least one cost allocation'
                }
            },
            ($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, item, itemIdx } = $scope
                if (!item.costAllocations.length) return
                const unallocated = item.getUnallocatedAmount()
                if (Math.abs(unallocated) < 0.01) return
                return {
                    ruleId: 'costAllocationMismatch',
                    error: `Please allocate the missing amount of ${item.overallBudgetCurrency.$displayValue} ${Math.round(unallocated * 100) / 100}`
                }
            }],
            async: [],
        }
    };
    componentStore = this.storeService.createComponentStore<Properties>({
        request: this.request !== undefined ? this.request : (undefined) as This.PurchaseRequest,
        allUsers: this.allUsers !== undefined ? this.allUsers : ([]) as { id: string, name: string }[]
    });

    get $inputs() {
        return {
            request: this.request
        }
    }

    @Input()
    get request() {
        return this.screen?.request
    }

    set request(request: This.PurchaseRequest) {
        request = (request as any)?.[ProxyTargetSymbol] ?? request
        this.storeService.setProperty([this.componentStore.name], 'request', request)
    }

    get allUsers() {
        return this.screen?.allUsers
    }

    set allUsers(allUsers: { id: string, name: string }[]) {
        allUsers = (allUsers as any)?.[ProxyTargetSymbol] ?? allUsers
        this.storeService.setProperty([this.componentStore.name], 'allUsers', allUsers)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.request = this.dialogData.request ?? this.request
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen


                screen.allUsers = []

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_ButtonBlock_1_2_0_0_0_1_0_0_click_removeItem_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, item, itemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeItem(itemIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_1_2_0_0_0_2_5_1_0_0_click_copyDeliveryAddressFrom_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, item, itemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_copyDeliveryAddressFrom('header', itemIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_1_2_0_0_0_2_5_1_0_1_0_click_copyDeliveryAddressFrom_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, item, itemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_copyDeliveryAddressFrom('previousItem', itemIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_119($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}

        return (item.costAllocations)
    }

    _DataTableBlock_additionalFindOptions_119($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_148_148($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number, costAllocation: This.PurchaseRequestItemCostAllocation, costAllocationIndex: number } = $scope ?? {}

        return ''
    }

    async handler_LinkBlock_1_2_0_0_0_3_0_0_0_0_0_0_click_removeCostAllocation_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number, costAllocation: This.PurchaseRequestItemCostAllocation, costAllocationIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeCostAllocation(itemIdx, costAllocationIndex, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableColumnBlock_cellText_124_124($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number, costAllocation: This.PurchaseRequestItemCostAllocation, costAllocationIndex: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_140_140($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number, costAllocation: This.PurchaseRequestItemCostAllocation, costAllocationIndex: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_121_121($scope: any): any {
        let { app, screen, $pathSegments, $disabled, item, itemIdx, costAllocation, costAllocationIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number, costAllocation: This.PurchaseRequestItemCostAllocation, costAllocationIndex: number } = $scope ?? {}

        return ''
    }

    async handler_ButtonBlock_1_2_0_0_0_3_0_1_0_0_click_addCostAllocation_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, item, itemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addCostAllocation(itemIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_1_4_0_0_click_addItem_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addItem($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_addItem($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.request.items.push(new This.PurchaseRequestItem({
                requestLineNumber: Sys.Text.calculateNextFreeName(screen.request.requestNumber + '-', screen.request.items, item => item.requestLineNumber, 2, true),
                costAllocations: [],
            }))
        }
    }

    async action_removeItem(index: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        index = this.storeService.replaceWithStateVersion(index, $context)
        {
            screen.request.items.splice(index, 1)
        }
    }

    async action_copyDeliveryAddressFrom(source: 'header' | 'previousItem', toItemIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        source = this.storeService.replaceWithStateVersion(source, $context)
        toItemIndex = this.storeService.replaceWithStateVersion(toItemIndex, $context)
        {
            let address: string
            switch (source) {
                case 'header': {
                    address = screen.request.deliveryAddress
                    break
                }
                case 'previousItem': {
                    address = screen.request.items[toItemIndex - 1]?.deliveryAddress
                    break
                }
            }

            screen.request.items[toItemIndex].deliveryAddress = address
        }
    }

    async action_addCostAllocation(itemIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        itemIndex = this.storeService.replaceWithStateVersion(itemIndex, $context)
        {
            screen.request.items[itemIndex].costAllocations.push(new This.PurchaseRequestItemCostAllocation({
                amount: screen.request.items[itemIndex].getUnallocatedAmount(),
            }))
        }
    }

    async action_removeCostAllocation(itemIndex: number, costAllocationIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        itemIndex = this.storeService.replaceWithStateVersion(itemIndex, $context)
        costAllocationIndex = this.storeService.replaceWithStateVersion(costAllocationIndex, $context)
        {
            screen.request.items[itemIndex].costAllocations.splice(costAllocationIndex, 1)
        }
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.allUsers = await This.Util.getAllUsers()
            if (!screen.request.items.length) await this.action_addItem($context)
        }
    }

    async action_onRequestorUpdated($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            const user = screen.allUsers.find(u => u.id == screen.request.requestorUserId)
            screen.request.requestorName = user?.name
        }
    }
}
