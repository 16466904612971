// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseInvoiceCostAllocation {
    @jsonMember(() => PurchaseToPay__PurchaseInvoiceCostAllocation.Id)
    id: PurchaseToPay__PurchaseInvoiceCostAllocation.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "costAccount", "costAccount$id", "allocationType", "allocationType$id", "costCenter", "costCenter$id", "projectCode", "projectCode$id", "amount", "invoice", "invoiceId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "costAccount": "PurchaseToPay.CostAllocationAccount", "costAccount$id": "string", "allocationType": "PurchaseToPay.CostAllocationType", "allocationType$id": "string", "costCenter": "PurchaseToPay.CostCenter", "costCenter$id": "string", "projectCode": "PurchaseToPay.ProjectCode", "projectCode$id": "string", "amount": "number", "invoice": "PurchaseToPay.PurchaseInvoice", "invoiceId": "PurchaseToPay.PurchaseInvoice.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["costAccount", "allocationType", "costCenter", "projectCode"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ invoice: 'PurchaseToPay.PurchaseInvoice' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θcostAccount?: This.CostAllocationAccount = new This.CostAllocationAccount();
    private θallocationType?: This.CostAllocationType = This.CostAllocationType.CostCenter;
    private θcostCenter?: This.CostCenter = new This.CostCenter();
    private θprojectCode?: This.ProjectCode = new This.ProjectCode();
    private θamount?: number = 0;
    private θinvoice?: PurchaseToPay.PurchaseInvoice = undefined;
    private θinvoiceId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        amount: 0,
        invoice: 0,
        invoiceId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        amount: () => {
            return false
        }
        , invoice: () => {
            return false
        }
        , invoiceId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseInvoiceCostAllocation' {
        return 'PurchaseToPay.PurchaseInvoiceCostAllocation'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseInvoiceCostAllocation' {
        return 'PurchaseToPay.PurchaseInvoiceCostAllocation'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseInvoiceCostAllocation' {
        return 'PurchaseToPay.PurchaseInvoiceCostAllocation'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseInvoiceCostAllocation' {
        return 'PurchaseToPay.PurchaseInvoiceCostAllocation'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseInvoiceCostAllocation[EntityInfoSymbol]
    }

    get costAccount(): This.CostAllocationAccount {
        return this.θcostAccount
    }

    set costAccount(costAccount: This.CostAllocationAccount | This.CostAllocationAccount.Id) {
        if (!this.θcostAccount) this.θcostAccount = new This.CostAllocationAccount()
        this.θcostAccount.θupdate(costAccount)
    }

    @jsonMember(String)
    get 'costAccount$id'(): string {
        return this.θcostAccount?.id
    }

    set 'costAccount$id'(costAccount: string) {
        if (!this.θcostAccount) this.θcostAccount = new This.CostAllocationAccount()
        this.θcostAccount.id = costAccount as any
    }

    get allocationType(): This.CostAllocationType {
        return this.θallocationType
    }

    set allocationType(allocationType: This.CostAllocationType | This.CostAllocationType.Id) {
        if (!this.θallocationType) this.θallocationType = new This.CostAllocationType()
        this.θallocationType.θupdate(allocationType)
    }

    @jsonMember(String)
    get 'allocationType$id'(): string {
        return this.θallocationType?.id
    }

    set 'allocationType$id'(allocationType: string) {
        if (!this.θallocationType) this.θallocationType = This.CostAllocationType.CostCenter
        this.θallocationType.id = allocationType as any
    }

    get costCenter(): This.CostCenter {
        return this.θcostCenter
    }

    set costCenter(costCenter: This.CostCenter | This.CostCenter.Id) {
        if (!this.θcostCenter) this.θcostCenter = new This.CostCenter()
        this.θcostCenter.θupdate(costCenter)
    }

    @jsonMember(String)
    get 'costCenter$id'(): string {
        return this.θcostCenter?.id
    }

    set 'costCenter$id'(costCenter: string) {
        if (!this.θcostCenter) this.θcostCenter = new This.CostCenter()
        this.θcostCenter.id = costCenter as any
    }

    get projectCode(): This.ProjectCode {
        return this.θprojectCode
    }

    set projectCode(projectCode: This.ProjectCode | This.ProjectCode.Id) {
        if (!this.θprojectCode) this.θprojectCode = new This.ProjectCode()
        this.θprojectCode.θupdate(projectCode)
    }

    @jsonMember(String)
    get 'projectCode$id'(): string {
        return this.θprojectCode?.id
    }

    set 'projectCode$id'(projectCode: string) {
        if (!this.θprojectCode) this.θprojectCode = new This.ProjectCode()
        this.θprojectCode.id = projectCode as any
    }

    get amount() {
        return this.θamount
    }

    set amount(amount: number) {
        this.θamount = LowgileDataUtil.coerceDataType(amount, 'number', false, true)
    }

    get invoice() {
        return this.θinvoice
    }

    set invoice(invoice: PurchaseToPay.PurchaseInvoice) {
        this.θinvoice = LowgileDataUtil.coerceDataType(invoice, PurchaseToPay.PurchaseInvoice, false, true)
    }

    get invoiceId() {
        return this.θinvoiceId
    }

    set invoiceId(invoiceId: number) {
        this.θinvoiceId = LowgileDataUtil.coerceDataType(invoiceId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseInvoiceCostAllocation.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseInvoiceCostAllocation>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseInvoiceCostAllocation(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseInvoiceCostAllocation>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseInvoiceCostAllocation>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('costAccount$id', 'allocationType$id', 'costCenter$id', 'projectCode$id', 'amount', 'invoice', 'invoiceId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseInvoiceCostAllocation { }

export import PurchaseInvoiceCostAllocation = PurchaseToPay__PurchaseInvoiceCostAllocation
setTimeout(() => {
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'amount')
    jsonMember(() => PurchaseToPay.PurchaseInvoice)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'invoice')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'invoiceId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceCostAllocation.prototype, 'versionNumber')
})

export const PurchaseInvoiceCostAllocationθId = Number;

export type PurchaseInvoiceCostAllocationθId = number;

namespace PurchaseToPay__PurchaseInvoiceCostAllocation {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseInvoiceCostAllocation'>

}
