// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => ArInvoiceStatus.loadInternal(), entryList => {
        ArInvoiceStatus.θentryList = entryList
        ArInvoiceStatus.θentryMap = undefined
        ArInvoiceStatus.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class ArInvoiceStatus {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: ArInvoiceStatus.Id;
    static readonly Id = String;
    static θids: ReadonlyArray<ArInvoiceStatus.Id> = Object.freeze(['open', 'closed', 'blocked']);
    static θentryMap: Readonly<Record<ArInvoiceStatus.Id, Readonly<ArInvoiceStatus.Entry>>>;
    static θentryList: ReadonlyArray<ArInvoiceStatus.Entry>;
    static θchoicesCache = new Map<keyof ArInvoiceStatus.Entry, Sys.Types.Choice[]>();
    readonly θhasOthers = false;
    static readonly $displayProperty = 'label';
    [immerable] = true;

    get __type(): 'PurchaseToPay.ArInvoiceStatus' {
        return 'PurchaseToPay.ArInvoiceStatus'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.ArInvoiceStatus' {
        return 'PurchaseToPay.ArInvoiceStatus'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get $displayValue(): string {
        return this.$isOther ? (this as any).$other : String((this as any)[ArInvoiceStatus.$displayProperty])
    }

    get label() {
        return ArInvoiceStatus.getEntryMap()[this.id]?.label
    }

    get $isOther() {
        return false
    }

    get isOpen() {
        return this.id == 'open'
    }

    static get Open() {
        return new this('open')
    }

    get isClosed() {
        return this.id == 'closed'
    }

    static get Closed() {
        return new this('closed')
    }

    get isBlocked() {
        return this.id == 'blocked'
    }

    static get Blocked() {
        return new this('blocked')
    }

    constructor(id: ArInvoiceStatus.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        if (!this.θentryList) this.θentryList = this.θgetStaticEntryList()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: ArInvoiceStatus.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getChoices(labelColumn: keyof ArInvoiceStatus.Entry = '$displayValue'): Sys.Types.Choice[] {
        if (labelColumn == "$displayValue") labelColumn = ArInvoiceStatus.$displayProperty
        let options = this.θchoicesCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θchoicesCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<ArInvoiceStatus.Entry[]> {
        const dynamicEntryList = []
        this.θentryMap = null
        this.θchoicesCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...this.θgetStaticEntryList(), ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new ArInvoiceStatus(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: ArInvoiceStatus.Id | ArInvoiceStatus) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.ArInvoiceStatus yet:', err.message)
            //throw err
        })
    }

    setOpen() {
        this.id = 'open'

    }

    setClosed() {
        this.id = 'closed'

    }

    setBlocked() {
        this.id = 'blocked'

    }

    static θgetStaticEntryList() {
        return Object.freeze([
            Object.freeze({ id: "open", label: "Open" }),
            Object.freeze({ id: "closed", label: "Closed" }),
            Object.freeze({ id: "blocked", label: "Blocked" })
        ])
    }
}

export const ArInvoiceStatusθId = String;

export type ArInvoiceStatusθId = string;

export namespace ArInvoiceStatus {
    export type Id = 'open' | 'closed' | 'blocked'

    export interface Entry {
        id: string;
        label: string;
        $displayValue?: string;
    }
}
Sys.Language.languageChanged$.subscribe(() => ArInvoiceStatus.load(true))
