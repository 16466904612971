// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__ArSchedule {
    @jsonMember(() => PurchaseToPay__ArSchedule.Id)
    id: PurchaseToPay__ArSchedule.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "name", "type", "description", "customer", "events", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "name": "string", "type": "string", "description": "string", "customer": "PurchaseToPay.Customer[]", "events": "PurchaseToPay.ArScheduleEvent[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ customer: 'PurchaseToPay.Customer', events: 'PurchaseToPay.ArScheduleEvent' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θname?: string = undefined;
    private θtype?: string = undefined;
    private θdescription?: string = undefined;
    private θcustomer?: PurchaseToPay.Customer[] = undefined;
    private θevents?: PurchaseToPay.ArScheduleEvent[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        name: 0,
        type: 0,
        description: 0,
        customer: 0,
        events: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        name: () => {
            return false
        }
        , type: () => {
            return false
        }
        , description: () => {
            return false
        }
        , customer: () => {
            return false
        }
        , events: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.ArSchedule' {
        return 'PurchaseToPay.ArSchedule'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.ArSchedule' {
        return 'PurchaseToPay.ArSchedule'
    }

    static get __baseType(): 'PurchaseToPay.ArSchedule' {
        return 'PurchaseToPay.ArSchedule'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.ArSchedule' {
        return 'PurchaseToPay.ArSchedule'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__ArSchedule[EntityInfoSymbol]
    }

    get name() {
        return this.θname
    }

    set name(name: string) {
        this.θname = LowgileDataUtil.coerceDataType(name, 'string', false, true)
    }

    get type() {
        return this.θtype
    }

    set type(type: string) {
        this.θtype = LowgileDataUtil.coerceDataType(type, 'string', false, true)
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get customer() {
        return this.θcustomer
    }

    set customer(customer: PurchaseToPay.Customer[]) {
        this.θcustomer = LowgileDataUtil.coerceDataType(customer, PurchaseToPay.Customer, true, true)
    }

    get events() {
        return this.θevents
    }

    set events(events: PurchaseToPay.ArScheduleEvent[]) {
        this.θevents = LowgileDataUtil.coerceDataType(events, PurchaseToPay.ArScheduleEvent, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__ArSchedule.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__ArSchedule>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__ArSchedule(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__ArSchedule>, restrictToClass?: Class<Partial<PurchaseToPay__ArSchedule>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('name', 'type', 'description', 'customer', 'events', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__ArSchedule { }

export import ArSchedule = PurchaseToPay__ArSchedule
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__ArSchedule.prototype, 'name')
    jsonMember(() => String)(PurchaseToPay__ArSchedule.prototype, 'type')
    jsonMember(() => String)(PurchaseToPay__ArSchedule.prototype, 'description')
    jsonArrayMember(() => PurchaseToPay.Customer)(PurchaseToPay__ArSchedule.prototype, 'customer')
    jsonArrayMember(() => PurchaseToPay.ArScheduleEvent)(PurchaseToPay__ArSchedule.prototype, 'events')
    jsonMember(() => Date)(PurchaseToPay__ArSchedule.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__ArSchedule.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__ArSchedule.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__ArSchedule.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__ArSchedule.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__ArSchedule.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__ArSchedule.prototype, 'versionNumber')
})

export const ArScheduleθId = Number;

export type ArScheduleθId = number;

namespace PurchaseToPay__ArSchedule {
    export type Id = NominalType<number, 'PurchaseToPay.ArSchedule'>

}
