import { ResourceType } from './bos/resource'
import { ExecutionLocation } from './script/execution-context'
import { ValidationErrors } from '@angular/forms'

import { EntityCommon, EntityInstance, EntityRecord, UserIdType } from './types'
import type { FindOptionsSelect, FindOptionsWhere, FindOptionsRelations, FindOptionsOrder } from 'typeorm';
import { Draft } from 'immer'

export interface Choice<T=any> {
	id: T
	label: string | number
	isOther?: boolean
}
export interface HasChoices {
	getChoices(...args: any[]): Choice[]
}

export type { FileUpload } from '@shared/data/file-upload'
// export type { FileUploadSession } from '@shared/data/file-upload-session'
export type { EntityState } from '@shared/data/entity-state'
export type { SendMailOptions as MailObject } from 'nodemailer'

export type { FindOptionsSelect, FindOptionsWhere, FindOptionsRelations, FindOptionsOrder } from 'typeorm';
export interface FindManyOptions<T extends EntityCommon> {
	/**
	* Specifies what columns should be retrieved.
	*/
	select?: FindOptionsSelect<EntityRecord<T>>
	
	/**
	* Simple condition that should be applied to match entities.
	*/
	where?: FindOptionsWhere<EntityRecord<T>>[] | FindOptionsWhere<EntityRecord<T>>
	
	/**
	* Indicates what relations of entity should be loaded (simplified left join form).
	*/
	relations?: FindOptionsRelations<EntityRecord<T>>
	
	/**
	* Specifies how relations must be loaded - using "joins" or separate queries.
	* If you are loading too much data with nested joins it's better to load relations
	* using separate queries.
	*
	* Default strategy is "join", but default can be customized in connection options.
	*/
	// relationLoadStrategy?: "join" | "query"
	
	/**
	* Order, in which entities should be ordered.
	*/
	order?: FindOptionsOrder<EntityRecord<T>>
	
	/**
	* Enables or disables query result caching.
	*/
	cache?: boolean | number | { id: any; milliseconds: number }
	
	/**
	* Indicates what locking mode should be used.
	*
	* Note: For lock tables, you must specify the table names and not the relation names
	*/
	lock?:
	| { mode: "optimistic"; version: number | Date }
	| {
		mode:
		| "pessimistic_read"
		| "pessimistic_write"
		| "dirty_read"
		| "pessimistic_partial_write"
		| "pessimistic_write_or_fail"
		| "for_no_key_update"
		| "for_key_share"
		tables?: string[]
	}
	
	/**
	* Indicates if soft-deleted rows should be included in entity result.
	*/
	// withDeleted?: boolean
	
	/**
	* If sets to true then loads all relation ids of the entity and maps them into relation values (not relation objects).
	* If array of strings is given then loads only relation ids of the given properties.
	*/
	// loadRelationIds?:
	// | boolean
	// | { relations?: string[]; disableMixedMap?: boolean } // todo: extract options into separate interface, reuse
	
	/**
	* Indicates if eager relations should be loaded or not.
	* By default, they are loaded when find methods are used.
	*/
	// loadEagerRelations?: boolean
	
	/**
	* If this is set to true, SELECT query in a `find` method will be executed in a transaction.
	*/
	transaction?: boolean
	
	/**
	* Offset (paginated) where from entities should be taken.
	*/
	skip?: number
	
	/**
	* Limit (paginated) - max number of entities should be taken.
	*/
	take?: number
}

export type FindManyPagedOptions<T extends EntityCommon> = Omit<FindManyOptions<T>, 'take' | 'skip'>

export interface PagingDefinition {
	pageSize: number
	pageIndex: number
}
export interface SortingDefinition<K extends string> {
	by: K
	direction?: 'asc' | 'desc'
}
export interface PagedData<T> extends PagingDefinition {
	list: T[]
	length: number
	pageStartIndex: number
	pageEndIndex: number
}

export const allowedAggregationFunctions = ['count', 'sum', 'min', 'max', 'avg'] as const
export interface AggregationInput<T extends EntityInstance, A extends typeof allowedAggregationFunctions[number], K extends keyof T, O extends string> {
	aggregationFunction: A
	column: '*' | K
	outputName: O
}
export type AggregationOutput<T extends EntityInstance, A extends typeof allowedAggregationFunctions[number], G extends keyof T, K extends keyof T, O extends string> = {
	[P in G]: T[P] // properties that the query was grouped by
} & {
	[P in O]: A extends 'count' ? number : T[K] // output property
}

export type GroupedCount<T extends EntityInstance, G extends keyof T> = {
	[P in G]: T[P]
} & {
	$count: number
}

export interface Form {
	reset(): void
	markAllAsTouched(): void
	get valid(): boolean
	get invalid(): boolean
	get pending(): boolean
	get disabled(): boolean
	get enabled(): boolean
	get errors(): ValidationErrors | null
	get pristine(): boolean
	get dirty(): boolean
	get touched(): boolean
	get untouched(): boolean
}

import { User } from './auth/user'
export { User } from './auth/user'
export { UserRole } from './auth/user-role'
export { UserPermission } from './auth/user-permission'
export { ChartConfiguration } from 'chart.js'

export interface ScreenActionContext<GlobalState = any, ScreenState = any, E extends Event = Event> {
	app: Draft<GlobalState>
	screen: Draft<ScreenState>
	/** @deprecated ??? */
	process?: any
	/** @deprecated ??? */
	output?: any
	$event: E
}

export type RemoveMethods<T extends {}> = {
	[P in keyof T]: T[P] extends (...args: any[]) => any ? never : T[P]
}

export const LoginStages = ['credentials', 'mfa', 'passwordChangeNeeded', 'successful'] as const
export type LoginStage = typeof LoginStages[number]

export type ClientLogLevel = 'success' | 'error' | 'warning' | 'info'

export type ResourceAvailabilityOnClientType = 'none' | 'downloadable' | 'builtin'
export type ServiceIntegrationAvailabilityOnClientType = 'none' | 'builtin'

export interface ResourceDefinition<EL extends ExecutionLocation, Avail extends Omit<ResourceAvailabilityOnClientType, 'none'>> {
	resourceType: ResourceType
	mimeType: string
	fileName: string
	relativeUrl: string
	absoluteUrl: string
	getDataBase64?: EL extends 'server'
		? (() => Promise<string>)
		: (Avail extends 'builtin' ? (() => Promise<string>) : never)
	getDataBuffer?: EL extends 'server' ? (() => Promise<Buffer>) : never
	getDataString?: EL extends 'server'
	? (() => Promise<string>)
	: (Avail extends 'builtin' ? (() => Promise<string>) : never)
}

export const AiTypes = ['AzureOpenAi'] as const
export type AiType = typeof AiTypes[number]

export interface Swimlane {
	id: string
	name: string
	assignee: User
}

export interface SwimlaneAssignment {
	users?: (User | UserIdType | string) | (User | UserIdType | string)[]
	groups?: string | string[]
	roles?: string | string[]
}

export interface ProcessScope<P=Record<string, any>> {
	process: P
	initiator: User | null
	startSignalName?: string
	startMessageName?: string
	startTimerActivityId?: string
	startMessageData?: any
	action?: string
}
export interface ProcessTokenScope<P=Record<string, any>, T=Record<string, any>> extends ProcessScope<P> {
	token: T,
	action: string,
	swimlane: Swimlane,
}

export { UserTaskToken, UserTaskTokenDetails } from '@shared/process/user-task-token'

import type Imap from 'node-imap'
import type { ParsedMail } from 'mailparser'
export interface MailBody extends ParsedMail {
	sequenceNumber: number
}
export type Mail = MailBody & Imap.ImapMessageAttributes


export interface UserIdentityTokenRecord {
	identityProviderId?: string
	accessToken: string
	scopes: string[]
}

export type NumberProperties<T> = {
	[K in keyof T]: T[K] extends number ? K : never
}
export type NumberPropertyKeys<T> = keyof NumberProperties<T>

export type StringProperties<T> = {
	[K in keyof T]: T[K] extends string ? K : never
}
export type StringPropertyKeys<T> = keyof StringProperties<T>

export type TypedProperties<T, U> = {
	[K in keyof T]: T[K] extends U ? K : never
}
export type TypedPropertyKeys<T, U> = keyof TypedProperties<T, U>