import { AuthInterceptor } from '@ng-shared/lib/services/auth-interceptor';
import { TranslationService } from '@ng-shared/lib/services/translation.service';
import { fromEvent, interval, Subscription, throttle } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, HostListener, Injector, ViewChild, ElementRef, Inject } from '@angular/core';
import { ChangeDetectionService } from '@ng-shared/lib/services/change-detection.service'
import { HttpClient, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AppModuleId, BranchName, DefaultScreen, Languages, DefaultLanguage } from './config/config';
import * as $Modules from './Module/$modules'
import { LabelIds, Translations } from './translation/translations'
import { AppContext } from '@ng-shared/lib/app-context'
import { globalInitialState, GlobalStateInterface } from './store/global-state-interface'
import { StoreService } from '@ng-shared/lib/store/store.service'
import { Title } from '@angular/platform-browser'
import { DevBarService } from '@ng-shared/lib/services/dev-bar.service'
import * as Sys from '@ng-shared/lib/frontend-sys'
import { TypedJSON } from 'typedjson'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { SharedBackgroundWorkerService } from '@ng-shared/lib/services/shared-background-worker.service'
import { AuthService } from '@ng-shared/lib/services/auth-service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
//   changeDetection: ChangeDetectionStrategy.OnPush,
//   encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	// state: GlobalStateInterface = globalInitialState
	subscriptions: Subscription[] = []
	@ViewChild('devBarScreenOverlay') devBarScreenOverlay: ElementRef<HTMLDivElement>
	@ViewChild('devBarScreenName') devBarScreenName: ElementRef<HTMLParagraphElement>
	Sys = Sys

	constructor(
		private store: StoreService,
		private cdService: ChangeDetectionService,
		private translationService: TranslationService,
		http: HttpClient,
		router: Router,
		location: Location,
		private injector: Injector,
		private titleService: Title,
		private authService: AuthService,
		private devBarService: DevBarService,
		@Inject(HTTP_INTERCEPTORS) httpInterceptors: HttpInterceptor[],
		private sharedWorkerService: SharedBackgroundWorkerService,
	  ) {
		//   this.subscriptions.push(this.store.select(state => state).subscribe(state => this.state = state))
		  cdService.setRootComponent(this)
		  titleService.setTitle(AppModuleId)
		  httpInterceptors.forEach(i => {
			  if(i instanceof AuthInterceptor) i.branchName = BranchName
		  })

		  const knownClasses = {}
		  for(const module of Object.values($Modules)) {
			for(const ctor of Object.values(module)) {
				if((ctor as any).__type) knownClasses[(ctor as any).__type] = ctor
			}
		  }

		  AppContext.set({
			translations: Translations,
			languages: Languages,
			labelIds: [...LabelIds],
			modules: $Modules,
			appModuleId: AppModuleId,
			injector,
			branchName: BranchName,
			defaultScreen: DefaultScreen,
			http,
			router,
			location,
			typedJSON: TypedJSON,
			knownClasses,
		  })
		  Sys.System.branchName = BranchName
		  Sys.Language.defaultLanguage = DefaultLanguage
		  this.store.initialize(globalInitialState)

		  const worker = new SharedWorker('/studio/shared-background-worker.js', { name: 'shared-background-worker' })
		  this.sharedWorkerService.init(worker)
		  this.sharedWorkerService.send({ type: 'connect', name: `App ${AppModuleId}` })
		  window.addEventListener('pagehide', () => this.sharedWorkerService.send({ type: 'disconnect' }))
	  }
	
	ngOnInit() {
		this.cdService.markViewDirty(this)

		// TODO!: continue to implement

		window.addEventListener('click', event => this.devBarService.handleMouseEvent(event))
		fromEvent(window, 'mousemove').pipe(
			throttle(() => interval(100))
		).subscribe((event: MouseEvent) => this.devBarService.handleMouseEvent(event))
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe())
	}

	@HostListener('document:translateLabel', ['$event'])
	onTranslateLabel(event) {
		this.translationService.showEditDialog(event.detail.moduleId, event.detail.boId)
	}
}
