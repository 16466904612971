// Compiled at 2025-01-14T21:36:14.508Z
import { System } from "./$modules";
import * as Common from "./Common";
import * as This from "./Common";
import { ScreenDefinition } from "@shared/types";

// Auto-generated by Lowgile
export const $ModuleId = 'Common';

export * from "../Entity/Common/UserAndName";
export * from "../Label/Common/$translations";
export * from "../Resource/Common/$resources";
export { $Config } from "../ConfigurationItem/Common/config";
export * as $Process from "../Process/Common/$processes";
export * from "../Role/Common/$roles";
export * from "../Permission/Common/$permissions";

export const $Screens = {
};
