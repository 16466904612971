// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Compiled at 2025-01-14T22:50:38.369Z */
:host {
  --bg-color: #f5f5f5;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIk1haW5MYXlvdXQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSx5Q0FBQTtBQUNBO0VBQ0ksbUJBQUE7QUFDSiIsImZpbGUiOiJNYWluTGF5b3V0LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiBDb21waWxlZCBhdCAyMDI1LTAxLTE0VDIyOjUwOjM4LjM2OVogKi9cbjpob3N0IHtcbiAgICAtLWJnLWNvbG9yOiAjZjVmNWY1O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/Screen/PurchaseToPay/MainLayout.scss"],"names":[],"mappings":"AAAA,yCAAA;AACA;EACI,mBAAA;AACJ;AACA,oWAAoW","sourcesContent":["/* Compiled at 2025-01-14T22:50:38.369Z */\n:host {\n    --bg-color: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
