import { CdkColumnDef } from '@angular/cdk/table'
import { Directive, Input } from '@angular/core'
import { MatColumnDef } from '@angular/material/table'

@Directive({
	selector: '[lowgile-column]',
	providers: [
		{ provide: MatColumnDef, useExisting: GenericTableColumn },
		{ provide: CdkColumnDef, useExisting: GenericTableColumn },
		{ provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: GenericTableColumn },
	],
	inputs: [{ name: 'name', alias: 'lowgile-column' }, 'sticky'],
	standalone: false,
})
export class GenericTableColumn extends MatColumnDef {
	@Input()
	columnValueFn?: (item: any) => any
}