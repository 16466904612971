import { AsyncValidatorFn, FormControl, ValidatorFn, Validators } from '@angular/forms';
// import moment from 'moment'
import { ProxyUtil } from '@shared/util/proxy-util'

export class FormControlCache {
	private static controlCache = new WeakMap<object, Map<string, FormControl>>()
	
	static getFormControl(object: object, propName: string, createIfNotExisting = true, validators: ValidatorFn | null = null, asyncValidator: AsyncValidatorFn | null = null) {
		object = ProxyUtil.getTarget(object) ?? {}
		const objFcCache = this.controlCache.get(object)
		let fc = objFcCache?.get(propName)
		
		const wasNewlyCreated = !fc && createIfNotExisting
		if(wasNewlyCreated) {
			let propVal = Reflect.get(object, propName)
			
			fc = new FormControl(propVal, {
				validators,
				asyncValidators: asyncValidator,
			})
			this.setFormControl(object, propName, fc)
		} else {
			if(validators) {
				fc?.setValidators(validators)
			}
			if(asyncValidator) {
				fc?.setAsyncValidators(asyncValidator)
			}
		}
		
		return {
			formControl: fc,
			wasNewlyCreated,
		}		
	}
	
	private static setFormControl(object: object, propName: string, formControl: FormControl) {
		object = ProxyUtil.getTarget(object) ?? {}
		const objFcCache = this.controlCache.get(object) ?? new Map<string, FormControl>()
		this.controlCache.set(object, objFcCache)
		objFcCache.set(propName, formControl)
	}
	
	static migrateFormControls(fromObject: object, toObject: object) {
		// Migration is needed to preserve validation errors	
		const migratedObjs = new Set<any>()	
		
		const migrate = (fromObject: any, toObject: any) => {
			if(!fromObject || !toObject || typeof fromObject != 'object' || typeof toObject != 'object') return
			if(migratedObjs.has(fromObject)) return
			migratedObjs.add(fromObject)
			
			fromObject = ProxyUtil.getTarget(fromObject)
			toObject = ProxyUtil.getTarget(toObject)
			if(fromObject === toObject || !fromObject || !toObject) return
			
			const objFcCache = this.controlCache.get(fromObject)
			if(objFcCache) {
				this.controlCache.set(toObject, objFcCache)
			}
			
			for(const key in fromObject) {
				const from = fromObject[key]
				const to = toObject[key]
				if(from === to) continue // skip early if no change

				let propertyControl = objFcCache?.get(key) ?? objFcCache?.get(`θ${key}`)
				if(!propertyControl && key.startsWith('θ')) {
					propertyControl = objFcCache?.get(key.substring(1))
				}
				propertyControl?.setValue(to)

				migrate(from, to)
			}
		}

		migrate(fromObject, toObject)
		// const _ = 0
	}
}
