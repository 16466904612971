// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__ArScheduleEventLog {
    @jsonMember(() => PurchaseToPay__ArScheduleEventLog.Id)
    id: PurchaseToPay__ArScheduleEventLog.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "log", "isCompleted", "event", "eventId", "invoice", "invoiceId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "log": "string", "isCompleted": "boolean", "event": "PurchaseToPay.ArScheduleEvent", "eventId": "PurchaseToPay.ArScheduleEvent.Id", "invoice": "PurchaseToPay.ArInvoice", "invoiceId": "PurchaseToPay.ArInvoice.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ event: 'PurchaseToPay.ArScheduleEvent', invoice: 'PurchaseToPay.ArInvoice' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θlog?: string = undefined;
    private θisCompleted?: boolean = false;
    private θevent?: PurchaseToPay.ArScheduleEvent = undefined;
    private θeventId?: number = null;
    private θinvoice?: PurchaseToPay.ArInvoice = undefined;
    private θinvoiceId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        log: 0,
        isCompleted: 0,
        event: 0,
        eventId: 0,
        invoice: 0,
        invoiceId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        log: () => {
            return false
        }
        , isCompleted: () => {
            return false
        }
        , event: () => {
            return false
        }
        , eventId: () => {
            return false
        }
        , invoice: () => {
            return false
        }
        , invoiceId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.ArScheduleEventLog' {
        return 'PurchaseToPay.ArScheduleEventLog'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.ArScheduleEventLog' {
        return 'PurchaseToPay.ArScheduleEventLog'
    }

    static get __baseType(): 'PurchaseToPay.ArScheduleEventLog' {
        return 'PurchaseToPay.ArScheduleEventLog'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.ArScheduleEventLog' {
        return 'PurchaseToPay.ArScheduleEventLog'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__ArScheduleEventLog[EntityInfoSymbol]
    }

    get log() {
        return this.θlog
    }

    set log(log: string) {
        this.θlog = LowgileDataUtil.coerceDataType(log, 'string', false, true)
    }

    get isCompleted() {
        return this.θisCompleted
    }

    set isCompleted(isCompleted: boolean) {
        this.θisCompleted = LowgileDataUtil.coerceDataType(isCompleted, 'boolean', false, true)
    }

    get event() {
        return this.θevent
    }

    set event(event: PurchaseToPay.ArScheduleEvent) {
        this.θevent = LowgileDataUtil.coerceDataType(event, PurchaseToPay.ArScheduleEvent, false, true)
    }

    get eventId() {
        return this.θeventId
    }

    set eventId(eventId: number) {
        this.θeventId = LowgileDataUtil.coerceDataType(eventId, 'number', false, true)
    }

    get invoice() {
        return this.θinvoice
    }

    set invoice(invoice: PurchaseToPay.ArInvoice) {
        this.θinvoice = LowgileDataUtil.coerceDataType(invoice, PurchaseToPay.ArInvoice, false, true)
    }

    get invoiceId() {
        return this.θinvoiceId
    }

    set invoiceId(invoiceId: number) {
        this.θinvoiceId = LowgileDataUtil.coerceDataType(invoiceId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__ArScheduleEventLog.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__ArScheduleEventLog>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__ArScheduleEventLog(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__ArScheduleEventLog>, restrictToClass?: Class<Partial<PurchaseToPay__ArScheduleEventLog>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('log', 'isCompleted', 'event', 'eventId', 'invoice', 'invoiceId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__ArScheduleEventLog { }

export import ArScheduleEventLog = PurchaseToPay__ArScheduleEventLog
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__ArScheduleEventLog.prototype, 'log')
    jsonMember(() => Boolean)(PurchaseToPay__ArScheduleEventLog.prototype, 'isCompleted')
    jsonMember(() => PurchaseToPay.ArScheduleEvent)(PurchaseToPay__ArScheduleEventLog.prototype, 'event')
    jsonMember(() => Number)(PurchaseToPay__ArScheduleEventLog.prototype, 'eventId')
    jsonMember(() => PurchaseToPay.ArInvoice)(PurchaseToPay__ArScheduleEventLog.prototype, 'invoice')
    jsonMember(() => Number)(PurchaseToPay__ArScheduleEventLog.prototype, 'invoiceId')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEventLog.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEventLog.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEventLog.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEventLog.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEventLog.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEventLog.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__ArScheduleEventLog.prototype, 'versionNumber')
})

export const ArScheduleEventLogθId = Number;

export type ArScheduleEventLogθId = number;

namespace PurchaseToPay__ArScheduleEventLog {
    export type Id = NominalType<number, 'PurchaseToPay.ArScheduleEventLog'>

}
