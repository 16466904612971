// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => IncoTerm.loadInternal(), entryList => {
        IncoTerm.θentryList = entryList
        IncoTerm.θentryMap = undefined
        IncoTerm.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class IncoTerm {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: IncoTerm.Id;
    static readonly Id = String;
    static θids: ReadonlyArray<IncoTerm.Id> = Object.freeze(['DPU', 'DDP']);
    static θentryMap: Readonly<Record<IncoTerm.Id, Readonly<IncoTerm.Entry>>>;
    static θentryList: ReadonlyArray<IncoTerm.Entry>;
    static θchoicesCache = new Map<keyof IncoTerm.Entry, Sys.Types.Choice[]>();
    readonly θhasOthers = false;
    static readonly $displayProperty = 'label';
    [immerable] = true;

    get __type(): 'PurchaseToPay.IncoTerm' {
        return 'PurchaseToPay.IncoTerm'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.IncoTerm' {
        return 'PurchaseToPay.IncoTerm'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get $displayValue(): string {
        return this.$isOther ? (this as any).$other : String((this as any)[IncoTerm.$displayProperty])
    }

    get label() {
        return IncoTerm.getEntryMap()[this.id]?.label
    }

    get $isOther() {
        return false
    }

    get isDPU() {
        return this.id == 'DPU'
    }

    static get DPU() {
        return new this('DPU')
    }

    get isDDP() {
        return this.id == 'DDP'
    }

    static get DDP() {
        return new this('DDP')
    }

    constructor(id: IncoTerm.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        if (!this.θentryList) this.θentryList = this.θgetStaticEntryList()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: IncoTerm.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getChoices(labelColumn: keyof IncoTerm.Entry = '$displayValue'): Sys.Types.Choice[] {
        if (labelColumn == "$displayValue") labelColumn = IncoTerm.$displayProperty
        let options = this.θchoicesCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θchoicesCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<IncoTerm.Entry[]> {
        const dynamicEntryList = []
        this.θentryMap = null
        this.θchoicesCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...this.θgetStaticEntryList(), ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new IncoTerm(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: IncoTerm.Id | IncoTerm) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.IncoTerm yet:', err.message)
            //throw err
        })
    }

    setDPU() {
        this.id = 'DPU'

    }

    setDDP() {
        this.id = 'DDP'

    }

    static θgetStaticEntryList() {
        return Object.freeze([
            Object.freeze({ id: "DPU", label: "Delivered at Place Unloaded" }),
            Object.freeze({ id: "DDP", label: "Delivered Duty Paid" })
        ])
    }
}

export const IncoTermθId = String;

export type IncoTermθId = string;

export namespace IncoTerm {
    export type Id = 'DPU' | 'DDP'

    export interface Entry {
        id: string;
        label: string;
        $displayValue?: string;
    }
}
Sys.Language.languageChanged$.subscribe(() => IncoTerm.load(true))
