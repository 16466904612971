// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lowgile-searchable-dropdown-input {
  background-color: transparent;
  outline: 0;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlYXJjaGFibGUtZHJvcGRvd24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDQyw2QkFBQTtFQUNBLFVBQUE7RUFDQSxVQUFBO0FBQ0QiLCJmaWxlIjoic2VhcmNoYWJsZS1kcm9wZG93bi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5sb3dnaWxlLXNlYXJjaGFibGUtZHJvcGRvd24taW5wdXQge1xuXHRiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDtcblx0b3V0bGluZTogMDtcblx0cGFkZGluZzogMDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./../../../lowgile/libs/ng-shared/src/lib/components/searchable-dropdown/searchable-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,UAAA;EACA,UAAA;AACD;AACA,gcAAgc","sourcesContent":[".lowgile-searchable-dropdown-input {\n\tbackground-color: transparent;\n\toutline: 0;\n\tpadding: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
