// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseVendorOfferItem {
    @jsonMember(() => PurchaseToPay__PurchaseVendorOfferItem.Id)
    id: PurchaseToPay__PurchaseVendorOfferItem.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "description", "quantityOffered", "unitPrice", "currency", "currency$id", "quantityToOrder", "quantityReceived", "quantityReceivedOfWhichDamaged", "allow", "receptionComment", "requestItem", "requestItemId", "vendorOffer", "vendorOfferId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "description": "string", "quantityOffered": "number", "unitPrice": "number", "currency": "PurchaseToPay.Currency", "currency$id": "string", "quantityToOrder": "number", "quantityReceived": "number", "quantityReceivedOfWhichDamaged": "number", "allow": "boolean", "receptionComment": "string", "requestItem": "PurchaseToPay.PurchaseRequestItem", "requestItemId": "PurchaseToPay.PurchaseRequestItem.Id", "vendorOffer": "PurchaseToPay.PurchaseVendorOffer", "vendorOfferId": "PurchaseToPay.PurchaseVendorOffer.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["currency"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ requestItem: 'PurchaseToPay.PurchaseRequestItem', vendorOffer: 'PurchaseToPay.PurchaseVendorOffer' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θdescription?: string = undefined;
    private θquantityOffered?: number = undefined;
    private θunitPrice?: number = undefined;
    private θcurrency?: This.Currency = This.Currency.CHF;
    private θquantityToOrder?: number = undefined;
    private θquantityReceived?: number = undefined;
    private θquantityReceivedOfWhichDamaged?: number = undefined;
    private θallow?: boolean = true;
    private θreceptionComment?: string = undefined;
    private θrequestItem?: PurchaseToPay.PurchaseRequestItem = undefined;
    private θrequestItemId?: number = null;
    private θvendorOffer?: PurchaseToPay.PurchaseVendorOffer = undefined;
    private θvendorOfferId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        description: 0,
        quantityOffered: 0,
        unitPrice: 0,
        quantityToOrder: 0,
        quantityReceived: 0,
        quantityReceivedOfWhichDamaged: 0,
        allow: 0,
        receptionComment: 0,
        requestItem: 0,
        requestItemId: 0,
        vendorOffer: 0,
        vendorOfferId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        description: () => {
            return false
        }
        , quantityOffered: () => {
            return false
        }
        , unitPrice: () => {
            return false
        }
        , quantityToOrder: () => {
            return false
        }
        , quantityReceived: () => {
            return false
        }
        , quantityReceivedOfWhichDamaged: () => {
            return false
        }
        , allow: () => {
            return false
        }
        , receptionComment: () => {
            return false
        }
        , requestItem: () => {
            return false
        }
        , requestItemId: () => {
            return false
        }
        , vendorOffer: () => {
            return false
        }
        , vendorOfferId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseVendorOfferItem' {
        return 'PurchaseToPay.PurchaseVendorOfferItem'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseVendorOfferItem' {
        return 'PurchaseToPay.PurchaseVendorOfferItem'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseVendorOfferItem' {
        return 'PurchaseToPay.PurchaseVendorOfferItem'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseVendorOfferItem' {
        return 'PurchaseToPay.PurchaseVendorOfferItem'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseVendorOfferItem[EntityInfoSymbol]
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get quantityOffered() {
        return this.θquantityOffered
    }

    set quantityOffered(quantityOffered: number) {
        this.θquantityOffered = LowgileDataUtil.coerceDataType(quantityOffered, 'number', false, true)
    }

    get unitPrice() {
        return this.θunitPrice
    }

    set unitPrice(unitPrice: number) {
        this.θunitPrice = LowgileDataUtil.coerceDataType(unitPrice, 'number', false, true)
    }

    get currency(): This.Currency {
        return this.θcurrency
    }

    set currency(currency: This.Currency | This.Currency.Id) {
        if (!this.θcurrency) this.θcurrency = new This.Currency()
        this.θcurrency.θupdate(currency)
    }

    @jsonMember(String)
    get 'currency$id'(): string {
        return this.θcurrency?.id
    }

    set 'currency$id'(currency: string) {
        if (!this.θcurrency) this.θcurrency = This.Currency.CHF
        this.θcurrency.id = currency as any
    }

    get quantityToOrder() {
        return this.θquantityToOrder
    }

    set quantityToOrder(quantityToOrder: number) {
        this.θquantityToOrder = LowgileDataUtil.coerceDataType(quantityToOrder, 'number', false, true)
    }

    get quantityReceived() {
        return this.θquantityReceived
    }

    set quantityReceived(quantityReceived: number) {
        this.θquantityReceived = LowgileDataUtil.coerceDataType(quantityReceived, 'number', false, true)
    }

    get quantityReceivedOfWhichDamaged() {
        return this.θquantityReceivedOfWhichDamaged
    }

    set quantityReceivedOfWhichDamaged(quantityReceivedOfWhichDamaged: number) {
        this.θquantityReceivedOfWhichDamaged = LowgileDataUtil.coerceDataType(quantityReceivedOfWhichDamaged, 'number', false, true)
    }

    get allow() {
        return this.θallow
    }

    set allow(allow: boolean) {
        this.θallow = LowgileDataUtil.coerceDataType(allow, 'boolean', false, true)
    }

    get receptionComment() {
        return this.θreceptionComment
    }

    set receptionComment(receptionComment: string) {
        this.θreceptionComment = LowgileDataUtil.coerceDataType(receptionComment, 'string', false, true)
    }

    get requestItem() {
        return this.θrequestItem
    }

    set requestItem(requestItem: PurchaseToPay.PurchaseRequestItem) {
        this.θrequestItem = LowgileDataUtil.coerceDataType(requestItem, PurchaseToPay.PurchaseRequestItem, false, true)
    }

    get requestItemId() {
        return this.θrequestItemId
    }

    set requestItemId(requestItemId: number) {
        this.θrequestItemId = LowgileDataUtil.coerceDataType(requestItemId, 'number', false, true)
    }

    get vendorOffer() {
        return this.θvendorOffer
    }

    set vendorOffer(vendorOffer: PurchaseToPay.PurchaseVendorOffer) {
        this.θvendorOffer = LowgileDataUtil.coerceDataType(vendorOffer, PurchaseToPay.PurchaseVendorOffer, false, true)
    }

    get vendorOfferId() {
        return this.θvendorOfferId
    }

    set vendorOfferId(vendorOfferId: number) {
        this.θvendorOfferId = LowgileDataUtil.coerceDataType(vendorOfferId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseVendorOfferItem.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseVendorOfferItem>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseVendorOfferItem(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseVendorOfferItem>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseVendorOfferItem>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('description', 'quantityOffered', 'unitPrice', 'currency$id', 'quantityToOrder', 'quantityReceived', 'quantityReceivedOfWhichDamaged', 'allow', 'receptionComment', 'requestItem', 'requestItemId', 'vendorOffer', 'vendorOfferId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseVendorOfferItem { }

export import PurchaseVendorOfferItem = PurchaseToPay__PurchaseVendorOfferItem
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'description')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'quantityOffered')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'unitPrice')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'quantityToOrder')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'quantityReceived')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'quantityReceivedOfWhichDamaged')
    jsonMember(() => Boolean)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'allow')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'receptionComment')
    jsonMember(() => PurchaseToPay.PurchaseRequestItem)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'requestItem')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'requestItemId')
    jsonMember(() => PurchaseToPay.PurchaseVendorOffer)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'vendorOffer')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'vendorOfferId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferItem.prototype, 'versionNumber')
})

export const PurchaseVendorOfferItemθId = Number;

export type PurchaseVendorOfferItemθId = number;

namespace PurchaseToPay__PurchaseVendorOfferItem {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseVendorOfferItem'>

}
