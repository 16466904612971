import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ScopeDirective } from '../directives/scope.directive'

@Pipe({
	name: 'untilChanged',
	pure: true,
	standalone: false,
})
export class UntilChangedPipe implements PipeTransform {
	private cachedParameters: any[] | undefined = undefined
	private cachedResult: any = undefined

	constructor(
		private scopeDirective: ScopeDirective,
	) {}

	transform<T>(input: (...args: any[]) => T, ...parameters: any[]): T {
		if(!this.haveParametersChanged(parameters)) return this.cachedResult

		console.log('reload until-changed')
		if(input instanceof Promise) {
			input.then(value => {
				console.log('awaited', value)
			})
		}
		const scope = this.scopeDirective.getScope()
		this.cachedParameters = parameters
		this.cachedResult = input(scope)

		return this.cachedResult
	}

	private haveParametersChanged(parameters: any[]) {
		if(!this.cachedParameters || this.cachedParameters.length != parameters.length) return true
		for(let i=0; i<parameters.length; i++) {
			if(this.cachedParameters[i] !== parameters[i]) return true
		}

		return false
	}
}
