import { Component, ComponentRef, DestroyRef, effect, EventEmitter, input, OnDestroy, Output, signal, viewChild, ViewContainerRef } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import type * as NgxEditor from 'ngx-editor'

@Component({
	selector: 'lowgile-rich-text-editor',
	template: `
		<div class="NgxEditor__Wrapper">
			<ng-container #editorMenu></ng-container>
			<ng-container #editor></ng-container>
		</div>
	`,
	styleUrls: ['./rich-text-editor.component.scss'],
	standalone: false,
})
export class RichTextEditorComponent implements OnDestroy {
	private static editorImportPromise: Promise<typeof NgxEditor> | undefined
	private editor = signal<NgxEditor.Editor | undefined>(undefined)
	private htmlInternal: string
	
	html = input.required<string>()
	placeholder = input<string>()
	disabled = input<boolean>(false)
	@Output() htmlChange = new EventEmitter<string>()

	editorContainerRef = viewChild('editor', { read: ViewContainerRef })
	menuContainerRef = viewChild('editorMenu', { read: ViewContainerRef })
	editorComponentRef = signal<ComponentRef<NgxEditor.NgxEditorComponent> | undefined>(undefined)
	menuComponent = signal<NgxEditor.MenuComponent | undefined>(undefined)

	constructor(
		protected viewContainerRef: ViewContainerRef,
		private destroyRef: DestroyRef,
	) {
		if(!RichTextEditorComponent.editorImportPromise) {
			RichTextEditorComponent.editorImportPromise = import('ngx-editor')
		}

		effect(async () => {
			const editorContainerRef = this.editorContainerRef()
			const menuContainerRef = this.menuContainerRef()
			
			if(editorContainerRef && menuContainerRef) {
				const editorImport = await RichTextEditorComponent.editorImportPromise!

				const editor = new editorImport.Editor()
				editor.valueChanges.pipe(
					takeUntilDestroyed(this.destroyRef),
				).subscribe(jsonDoc => {
					this.htmlInternal = editorImport.toHTML(jsonDoc)
					this.htmlChange.emit(this.htmlInternal)
				})
				this.editor.set(editor)

				const editorComponentRef = editorContainerRef?.createComponent(editorImport.NgxEditorComponent)
				editorComponentRef?.setInput('editor', editor)
				this.editorComponentRef.set(editorComponentRef)

				const menuComponentRef = menuContainerRef?.createComponent(editorImport.MenuComponent)
				menuComponentRef?.setInput('editor', editor)
				this.menuComponent.set(menuComponentRef?.instance)
			}
		})

		effect(() => {
			const editor = this.editor()
			if(editor) {
				const html = this.html()
				if(html != this.htmlInternal) {
					editor.setContent(html)
				}
			}
		})

		effect(() => {
			const editorComponentRef = this.editorComponentRef()
			const menuComponent = this.menuComponent()
			const disabled = this.disabled()
			const placeholder = this.placeholder()
			
			if(editorComponentRef) {
				setTimeout(() => {
					editorComponentRef.instance.setDisabledState(disabled)
					if(menuComponent) {
						menuComponent.disabled = disabled
					}
					editorComponentRef.setInput('placeholder', placeholder)
				})
			}
		})
	}

	ngOnDestroy() {
		this.editor()?.destroy()
	}
}