// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    ownedRequests: This.PurchaseRequest[];
    selectedRequest: This.PurchaseRequest;
    selectedRequestIdx: number;
    selectedPo: This.PurchaseOrder;
    grn: This.PurchaseGoodsReceiptNote;
    pos: This.PurchaseOrder[];
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-InvoiceReceipt',
    templateUrl: './InvoiceReceipt.html',
    styleUrls: ['./InvoiceReceipt.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class InvoiceReceipt extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.InvoiceReceipt';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    @Output()
    submit = new EventEmitter<{
        grn: This.PurchaseGoodsReceiptNote
        processInstanceId: number
    }>();
    @Output()
    save = new EventEmitter<This.PurchaseGoodsReceiptNote>();
    $output = {
        submit: async (data: {
            grn: This.PurchaseGoodsReceiptNote
            processInstanceId: number
        }) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('submit', data)
            this.submit.emit(data)
        },
        save: async (data: This.PurchaseGoodsReceiptNote) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('save', data)
            this.save.emit(data)
        },
    };
    _DataTableBlock_columns_8 = (screen: Properties) => ['item', 'quantityOrdered', 'quantityReceived', 'quantityRejected', 'comment'];
    $validators: Record<number, ValidationRules> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        ownedRequests: this.ownedRequests !== undefined ? this.ownedRequests : (undefined) as This.PurchaseRequest[],
        selectedRequest: this.selectedRequest !== undefined ? this.selectedRequest : (undefined) as This.PurchaseRequest,
        selectedRequestIdx: this.selectedRequestIdx !== undefined ? this.selectedRequestIdx : (undefined) as number,
        selectedPo: this.selectedPo !== undefined ? this.selectedPo : (undefined) as This.PurchaseOrder,
        grn: this.grn !== undefined ? this.grn : (undefined) as This.PurchaseGoodsReceiptNote,
        pos: this.pos !== undefined ? this.pos : (undefined) as This.PurchaseOrder[]
    });

    get $inputs() {
        return {
            selectedRequest: this.selectedRequest
        }
    }

    get ownedRequests() {
        return this.screen?.ownedRequests
    }

    set ownedRequests(ownedRequests: This.PurchaseRequest[]) {
        ownedRequests = (ownedRequests as any)?.[ProxyTargetSymbol] ?? ownedRequests
        this.storeService.setProperty([this.componentStore.name], 'ownedRequests', ownedRequests)
    }

    @Input()
    get selectedRequest() {
        return this.screen?.selectedRequest
    }

    set selectedRequest(selectedRequest: This.PurchaseRequest) {
        selectedRequest = (selectedRequest as any)?.[ProxyTargetSymbol] ?? selectedRequest
        this.storeService.setProperty([this.componentStore.name], 'selectedRequest', selectedRequest)
    }

    get selectedRequestIdx() {
        return this.screen?.selectedRequestIdx
    }

    set selectedRequestIdx(selectedRequestIdx: number) {
        selectedRequestIdx = (selectedRequestIdx as any)?.[ProxyTargetSymbol] ?? selectedRequestIdx
        this.storeService.setProperty([this.componentStore.name], 'selectedRequestIdx', selectedRequestIdx)
    }

    get selectedPo() {
        return this.screen?.selectedPo
    }

    set selectedPo(selectedPo: This.PurchaseOrder) {
        selectedPo = (selectedPo as any)?.[ProxyTargetSymbol] ?? selectedPo
        this.storeService.setProperty([this.componentStore.name], 'selectedPo', selectedPo)
    }

    get grn() {
        return this.screen?.grn
    }

    set grn(grn: This.PurchaseGoodsReceiptNote) {
        grn = (grn as any)?.[ProxyTargetSymbol] ?? grn
        this.storeService.setProperty([this.componentStore.name], 'grn', grn)
    }

    get pos() {
        return this.screen?.pos
    }

    set pos(pos: This.PurchaseOrder[]) {
        pos = (pos as any)?.[ProxyTargetSymbol] ?? pos
        this.storeService.setProperty([this.componentStore.name], 'pos', pos)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.selectedRequest = this.dialogData.selectedRequest ?? this.selectedRequest
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen


                screen.selectedRequestIdx = undefined
                screen.grn = undefined
                screen.pos = undefined

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_DropdownBlock_0_0_0_0_0_0_selectionChange_onRequestSelected_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let selection: any = $event
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onRequestSelected($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_DropdownBlock_0_0_1_0_0_selectionChange_onPoSelected_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let selection: any = $event
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onPoSelected($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_8($scope: any): any {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.selectedPo.items)
    }

    _DataTableBlock_additionalFindOptions_8($scope: any): any {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_9_9($scope: any): any {
        let { app, screen, $pathSegments, $disabled, poItem, poItemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, poItem: This.PurchaseOrderItem, poItemIdx: number } = $scope ?? {}

        return (poItem.description)
    }

    _DataTableColumnBlock_cellText_10_10($scope: any): any {
        let { app, screen, $pathSegments, $disabled, poItem, poItemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, poItem: This.PurchaseOrderItem, poItemIdx: number } = $scope ?? {}

        return (poItem.quantity + ' ' + poItem.unit.label)
    }

    _DataTableColumnBlock_cellText_11_11($scope: any): any {
        let { app, screen, $pathSegments, $disabled, poItem, poItemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, poItem: This.PurchaseOrderItem, poItemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_13_13($scope: any): any {
        let { app, screen, $pathSegments, $disabled, poItem, poItemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, poItem: This.PurchaseOrderItem, poItemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_21_21($scope: any): any {
        let { app, screen, $pathSegments, $disabled, poItem, poItemIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, poItem: This.PurchaseOrderItem, poItemIdx: number } = $scope ?? {}

        return ''
    }

    async handler_ButtonBlock_0_0_2_0_0_click_submit_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_submit($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_2_1_click_save_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_save($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_submit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            await output.submit({
                grn: screen.grn,
                processInstanceId: screen.selectedRequest.processInstanceId,
            })
        }
    }

    async action_save($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            output.save(screen.grn)
        }
    }

    async action_onRequestSelected($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.selectedRequest = await This.Db.loadById(This.PurchaseRequest, screen.ownedRequests[screen.selectedRequestIdx].id, {
                relations: {
                    items: true,
                    vendorOffers: {
                        purchaseOrders: {
                            items: true,
                        }
                    }
                }
            })
            screen.pos = screen.selectedRequest.vendorOffers.flatMap(offer => offer.purchaseOrders)
            screen.selectedPo = undefined
            screen.grn = undefined
        }
    }

    async action_onPoSelected($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            const receiptNumber = 'TBD'
            const items = screen.selectedPo.items.map(poItem => new This.PurchaseGoodsReceiptNoteItem({
                description: poItem.description,
                purchaseOrderItemId: poItem.id,
                unit: poItem.unit,
            }))
            for (const item of items) {
                item.purchaseOrderLineNumber = Sys.Text.calculateNextFreeName(receiptNumber + '-', items, item => item.purchaseOrderLineNumber, 1)
            }

            screen.grn = new This.PurchaseGoodsReceiptNote({
                purchaseOrderId: screen.selectedPo.id,
                postingDate: new Date(),
                receiptNumber,
                items,
            })
        }
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.ownedRequests = await This.Db.loadByCondition(This.PurchaseRequest, {
                where: {
                    requestorUserId: Sys.User.currentUser?.id,
                },
            })
        }
    }
}
