import { Directive, Input } from '@angular/core'
import { MatTab } from '@angular/material/tabs'

@Directive({
	selector: 'mat-tab',
	exportAs: 'lowgileTabGroupTabControl',
	standalone: false,
})
export class TabGroupTabControlDirective {
	@Input() lowgileTabName?: string

	constructor(
		private matTab: MatTab,
	) {}
}