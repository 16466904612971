// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseRequisitionItem {
    @jsonMember(() => PurchaseToPay__PurchaseRequisitionItem.Id)
    id: PurchaseToPay__PurchaseRequisitionItem.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "partNumber", "description", "comment", "quanitity", "unitPrice", "currency", "requestItem", "requestItemId", "selectedVendor", "selectedVendorId", "purchaseRequisition", "purchaseRequisitionId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "partNumber": "string", "description": "string", "comment": "string", "quanitity": "number", "unitPrice": "number", "currency": "string", "requestItem": "PurchaseToPay.PurchaseRequestItem", "requestItemId": "PurchaseToPay.PurchaseRequestItem.Id", "selectedVendor": "PurchaseToPay.PurchaseVendor", "selectedVendorId": "PurchaseToPay.PurchaseVendor.Id", "purchaseRequisition": "PurchaseToPay.PurchaseRequisition", "purchaseRequisitionId": "PurchaseToPay.PurchaseRequisition.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ requestItem: 'PurchaseToPay.PurchaseRequestItem', selectedVendor: 'PurchaseToPay.PurchaseVendor', purchaseRequisition: 'PurchaseToPay.PurchaseRequisition' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θpartNumber?: string = undefined;
    private θdescription?: string = undefined;
    private θcomment?: string = undefined;
    private θquanitity?: number = undefined;
    private θunitPrice?: number = undefined;
    private θcurrency?: string = undefined;
    private θrequestItem?: PurchaseToPay.PurchaseRequestItem = undefined;
    private θrequestItemId?: number = null;
    private θselectedVendor?: PurchaseToPay.PurchaseVendor = undefined;
    private θselectedVendorId?: number = null;
    private θpurchaseRequisition?: PurchaseToPay.PurchaseRequisition = undefined;
    private θpurchaseRequisitionId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        partNumber: 0,
        description: 0,
        comment: 0,
        quanitity: 0,
        unitPrice: 0,
        currency: 0,
        requestItem: 0,
        requestItemId: 0,
        selectedVendor: 0,
        selectedVendorId: 0,
        purchaseRequisition: 0,
        purchaseRequisitionId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        partNumber: () => {
            return false
        }
        , description: () => {
            return false
        }
        , comment: () => {
            return false
        }
        , quanitity: () => {
            return false
        }
        , unitPrice: () => {
            return false
        }
        , currency: () => {
            return false
        }
        , requestItem: () => {
            return false
        }
        , requestItemId: () => {
            return false
        }
        , selectedVendor: () => {
            return false
        }
        , selectedVendorId: () => {
            return false
        }
        , purchaseRequisition: () => {
            return false
        }
        , purchaseRequisitionId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseRequisitionItem' {
        return 'PurchaseToPay.PurchaseRequisitionItem'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseRequisitionItem' {
        return 'PurchaseToPay.PurchaseRequisitionItem'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseRequisitionItem' {
        return 'PurchaseToPay.PurchaseRequisitionItem'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseRequisitionItem' {
        return 'PurchaseToPay.PurchaseRequisitionItem'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseRequisitionItem[EntityInfoSymbol]
    }

    get partNumber() {
        return this.θpartNumber
    }

    set partNumber(partNumber: string) {
        this.θpartNumber = LowgileDataUtil.coerceDataType(partNumber, 'string', false, true)
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get comment() {
        return this.θcomment
    }

    set comment(comment: string) {
        this.θcomment = LowgileDataUtil.coerceDataType(comment, 'string', false, true)
    }

    get quanitity() {
        return this.θquanitity
    }

    set quanitity(quanitity: number) {
        this.θquanitity = LowgileDataUtil.coerceDataType(quanitity, 'number', false, true)
    }

    get unitPrice() {
        return this.θunitPrice
    }

    set unitPrice(unitPrice: number) {
        this.θunitPrice = LowgileDataUtil.coerceDataType(unitPrice, 'number', false, true)
    }

    get currency() {
        return this.θcurrency
    }

    set currency(currency: string) {
        this.θcurrency = LowgileDataUtil.coerceDataType(currency, 'string', false, true)
    }

    get requestItem() {
        return this.θrequestItem
    }

    set requestItem(requestItem: PurchaseToPay.PurchaseRequestItem) {
        this.θrequestItem = LowgileDataUtil.coerceDataType(requestItem, PurchaseToPay.PurchaseRequestItem, false, true)
    }

    get requestItemId() {
        return this.θrequestItemId
    }

    set requestItemId(requestItemId: number) {
        this.θrequestItemId = LowgileDataUtil.coerceDataType(requestItemId, 'number', false, true)
    }

    get selectedVendor() {
        return this.θselectedVendor
    }

    set selectedVendor(selectedVendor: PurchaseToPay.PurchaseVendor) {
        this.θselectedVendor = LowgileDataUtil.coerceDataType(selectedVendor, PurchaseToPay.PurchaseVendor, false, true)
    }

    get selectedVendorId() {
        return this.θselectedVendorId
    }

    set selectedVendorId(selectedVendorId: number) {
        this.θselectedVendorId = LowgileDataUtil.coerceDataType(selectedVendorId, 'number', false, true)
    }

    get purchaseRequisition() {
        return this.θpurchaseRequisition
    }

    set purchaseRequisition(purchaseRequisition: PurchaseToPay.PurchaseRequisition) {
        this.θpurchaseRequisition = LowgileDataUtil.coerceDataType(purchaseRequisition, PurchaseToPay.PurchaseRequisition, false, true)
    }

    get purchaseRequisitionId() {
        return this.θpurchaseRequisitionId
    }

    set purchaseRequisitionId(purchaseRequisitionId: number) {
        this.θpurchaseRequisitionId = LowgileDataUtil.coerceDataType(purchaseRequisitionId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseRequisitionItem.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseRequisitionItem>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseRequisitionItem(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseRequisitionItem>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseRequisitionItem>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('partNumber', 'description', 'comment', 'quanitity', 'unitPrice', 'currency', 'requestItem', 'requestItemId', 'selectedVendor', 'selectedVendorId', 'purchaseRequisition', 'purchaseRequisitionId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseRequisitionItem { }

export import PurchaseRequisitionItem = PurchaseToPay__PurchaseRequisitionItem
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'partNumber')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'description')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'comment')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'quanitity')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'unitPrice')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'currency')
    jsonMember(() => PurchaseToPay.PurchaseRequestItem)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'requestItem')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'requestItemId')
    jsonMember(() => PurchaseToPay.PurchaseVendor)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'selectedVendor')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'selectedVendorId')
    jsonMember(() => PurchaseToPay.PurchaseRequisition)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'purchaseRequisition')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'purchaseRequisitionId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequisitionItem.prototype, 'versionNumber')
})

export const PurchaseRequisitionItemθId = Number;

export type PurchaseRequisitionItemθId = number;

namespace PurchaseToPay__PurchaseRequisitionItem {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseRequisitionItem'>

}
