import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

@Component({
	templateUrl: './snack-bar.component.html',
	styleUrls: ['./snack-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class SnackBarComponent {
	typeIcon: string
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) protected data: {
			type: string
			message: string
			duration: number
			dismiss: () => void
		},
	) {
		const icons: Record<string, string> = {
			error: 'error',
			warning: 'warning',
			success: 'check',
		}
		this.typeIcon = icons[data.type]
	}
}