// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseOrderItem {
    @jsonMember(() => PurchaseToPay__PurchaseOrderItem.Id)
    id: PurchaseToPay__PurchaseOrderItem.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "lineNumber", "code", "description", "quantity", "unit", "unit$id", "unit$other", "comment", "unitPrice", "currency", "currency$id", "requestedDeliveryDate", "costCenter", "costCenter$id", "category", "category$id", "taxRate", "taxRate$id", "purchaseOrder", "purchaseOrderId", "itemExtras", "purchaseGoodsReceiptNoteItem", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "lineNumber": "string", "code": "string", "description": "string", "quantity": "number", "unit": "PurchaseToPay.Unit", "unit$id": "string", "unit$other": "string", "comment": "string", "unitPrice": "number", "currency": "PurchaseToPay.Currency", "currency$id": "string", "requestedDeliveryDate": "Date", "costCenter": "PurchaseToPay.CostCenter", "costCenter$id": "string", "category": "PurchaseToPay.ItemCategory", "category$id": "string", "taxRate": "PurchaseToPay.TaxRate", "taxRate$id": "string", "purchaseOrder": "PurchaseToPay.PurchaseOrder", "purchaseOrderId": "PurchaseToPay.PurchaseOrder.Id", "itemExtras": "PurchaseToPay.PurchaseOrderItemExtra[]", "purchaseGoodsReceiptNoteItem": "PurchaseToPay.PurchaseGoodsReceiptNoteItem[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["unit", "currency", "costCenter", "category", "taxRate"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ purchaseOrder: 'PurchaseToPay.PurchaseOrder' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ itemExtras: 'PurchaseToPay.PurchaseOrderItemExtra', purchaseGoodsReceiptNoteItem: 'PurchaseToPay.PurchaseGoodsReceiptNoteItem' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θlineNumber?: string = undefined;
    private θcode?: string = undefined;
    private θdescription?: string = undefined;
    private θquantity?: number = undefined;
    private θunit?: This.Unit = This.Unit.Pieces;
    private θcomment?: string = undefined;
    private θunitPrice?: number = undefined;
    private θcurrency?: This.Currency = new This.Currency();
    private θrequestedDeliveryDate?: Date = undefined;
    private θcostCenter?: This.CostCenter = new This.CostCenter();
    private θcategory?: This.ItemCategory = new This.ItemCategory();
    private θtaxRate?: This.TaxRate = This.TaxRate.NormalRate;
    private θpurchaseOrder?: PurchaseToPay.PurchaseOrder = undefined;
    private θpurchaseOrderId?: number = null;
    private θitemExtras?: PurchaseToPay.PurchaseOrderItemExtra[] = undefined;
    private θpurchaseGoodsReceiptNoteItem?: PurchaseToPay.PurchaseGoodsReceiptNoteItem[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        lineNumber: 0,
        code: 0,
        description: 0,
        quantity: 0,
        comment: 0,
        unitPrice: 0,
        requestedDeliveryDate: 0,
        purchaseOrder: 0,
        purchaseOrderId: 0,
        itemExtras: 0,
        purchaseGoodsReceiptNoteItem: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        lineNumber: () => {
            return false
        }
        , code: () => {
            return false
        }
        , description: () => {
            return false
        }
        , quantity: () => {
            return false
        }
        , comment: () => {
            return false
        }
        , unitPrice: () => {
            return false
        }
        , requestedDeliveryDate: () => {
            return false
        }
        , purchaseOrder: () => {
            return false
        }
        , purchaseOrderId: () => {
            return false
        }
        , itemExtras: () => {
            return false
        }
        , purchaseGoodsReceiptNoteItem: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseOrderItem' {
        return 'PurchaseToPay.PurchaseOrderItem'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseOrderItem' {
        return 'PurchaseToPay.PurchaseOrderItem'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseOrderItem' {
        return 'PurchaseToPay.PurchaseOrderItem'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseOrderItem' {
        return 'PurchaseToPay.PurchaseOrderItem'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseOrderItem[EntityInfoSymbol]
    }

    get lineNumber() {
        return this.θlineNumber
    }

    set lineNumber(lineNumber: string) {
        this.θlineNumber = LowgileDataUtil.coerceDataType(lineNumber, 'string', false, true)
    }

    get code() {
        return this.θcode
    }

    set code(code: string) {
        this.θcode = LowgileDataUtil.coerceDataType(code, 'string', false, true)
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get quantity() {
        return this.θquantity
    }

    set quantity(quantity: number) {
        this.θquantity = LowgileDataUtil.coerceDataType(quantity, 'number', false, true)
    }

    get unit(): This.Unit {
        return this.θunit
    }

    set unit(unit: This.Unit | This.Unit.Id) {
        if (!this.θunit) this.θunit = new This.Unit()
        this.θunit.θupdate(unit)
    }

    @jsonMember(String)
    get 'unit$id'(): string {
        return this.θunit?.id
    }

    set 'unit$id'(unit: string) {
        if (!this.θunit) this.θunit = This.Unit.Pieces
        this.θunit.id = unit as any
    }

    @jsonMember(String)
    get 'unit$other'(): string {
        return this.θunit?.$other
    }

    set 'unit$other'(otherValue: string) {
        if (!this.θunit) this.θunit = This.Unit.Pieces
        this.θunit.$other = otherValue
    }

    get comment() {
        return this.θcomment
    }

    set comment(comment: string) {
        this.θcomment = LowgileDataUtil.coerceDataType(comment, 'string', false, true)
    }

    get unitPrice() {
        return this.θunitPrice
    }

    set unitPrice(unitPrice: number) {
        this.θunitPrice = LowgileDataUtil.coerceDataType(unitPrice, 'number', false, true)
    }

    get currency(): This.Currency {
        return this.θcurrency
    }

    set currency(currency: This.Currency | This.Currency.Id) {
        if (!this.θcurrency) this.θcurrency = new This.Currency()
        this.θcurrency.θupdate(currency)
    }

    @jsonMember(String)
    get 'currency$id'(): string {
        return this.θcurrency?.id
    }

    set 'currency$id'(currency: string) {
        if (!this.θcurrency) this.θcurrency = new This.Currency()
        this.θcurrency.id = currency as any
    }

    get requestedDeliveryDate() {
        return this.θrequestedDeliveryDate
    }

    set requestedDeliveryDate(requestedDeliveryDate: Date) {
        this.θrequestedDeliveryDate = LowgileDataUtil.coerceDataType(requestedDeliveryDate, 'Date', false, true)
    }

    get costCenter(): This.CostCenter {
        return this.θcostCenter
    }

    set costCenter(costCenter: This.CostCenter | This.CostCenter.Id) {
        if (!this.θcostCenter) this.θcostCenter = new This.CostCenter()
        this.θcostCenter.θupdate(costCenter)
    }

    @jsonMember(String)
    get 'costCenter$id'(): string {
        return this.θcostCenter?.id
    }

    set 'costCenter$id'(costCenter: string) {
        if (!this.θcostCenter) this.θcostCenter = new This.CostCenter()
        this.θcostCenter.id = costCenter as any
    }

    get category(): This.ItemCategory {
        return this.θcategory
    }

    set category(category: This.ItemCategory | This.ItemCategory.Id) {
        if (!this.θcategory) this.θcategory = new This.ItemCategory()
        this.θcategory.θupdate(category)
    }

    @jsonMember(String)
    get 'category$id'(): string {
        return this.θcategory?.id
    }

    set 'category$id'(category: string) {
        if (!this.θcategory) this.θcategory = new This.ItemCategory()
        this.θcategory.id = category as any
    }

    get taxRate(): This.TaxRate {
        return this.θtaxRate
    }

    set taxRate(taxRate: This.TaxRate | This.TaxRate.Id) {
        if (!this.θtaxRate) this.θtaxRate = new This.TaxRate()
        this.θtaxRate.θupdate(taxRate)
    }

    @jsonMember(String)
    get 'taxRate$id'(): string {
        return this.θtaxRate?.id
    }

    set 'taxRate$id'(taxRate: string) {
        if (!this.θtaxRate) this.θtaxRate = This.TaxRate.NormalRate
        this.θtaxRate.id = taxRate as any
    }

    get purchaseOrder() {
        return this.θpurchaseOrder
    }

    set purchaseOrder(purchaseOrder: PurchaseToPay.PurchaseOrder) {
        this.θpurchaseOrder = LowgileDataUtil.coerceDataType(purchaseOrder, PurchaseToPay.PurchaseOrder, false, true)
    }

    get purchaseOrderId() {
        return this.θpurchaseOrderId
    }

    set purchaseOrderId(purchaseOrderId: number) {
        this.θpurchaseOrderId = LowgileDataUtil.coerceDataType(purchaseOrderId, 'number', false, true)
    }

    get itemExtras() {
        return this.θitemExtras
    }

    set itemExtras(itemExtras: PurchaseToPay.PurchaseOrderItemExtra[]) {
        this.θitemExtras = LowgileDataUtil.coerceDataType(itemExtras, PurchaseToPay.PurchaseOrderItemExtra, true, true)
    }

    get purchaseGoodsReceiptNoteItem() {
        return this.θpurchaseGoodsReceiptNoteItem
    }

    set purchaseGoodsReceiptNoteItem(purchaseGoodsReceiptNoteItem: PurchaseToPay.PurchaseGoodsReceiptNoteItem[]) {
        this.θpurchaseGoodsReceiptNoteItem = LowgileDataUtil.coerceDataType(purchaseGoodsReceiptNoteItem, PurchaseToPay.PurchaseGoodsReceiptNoteItem, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseOrderItem.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseOrderItem>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseOrderItem(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseOrderItem>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseOrderItem>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('lineNumber', 'code', 'description', 'quantity', 'unit$id', 'unit$other', 'comment', 'unitPrice', 'currency$id', 'requestedDeliveryDate', 'costCenter$id', 'category$id', 'taxRate$id', 'purchaseOrder', 'purchaseOrderId', 'itemExtras', 'purchaseGoodsReceiptNoteItem', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
        if (!this.unit.$isOther) this.unit.$other = null
    }
}

namespace PurchaseToPay__PurchaseOrderItem { }

export import PurchaseOrderItem = PurchaseToPay__PurchaseOrderItem
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'lineNumber')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'code')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'description')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItem.prototype, 'quantity')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'comment')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItem.prototype, 'unitPrice')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItem.prototype, 'requestedDeliveryDate')
    jsonMember(() => PurchaseToPay.PurchaseOrder)(PurchaseToPay__PurchaseOrderItem.prototype, 'purchaseOrder')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItem.prototype, 'purchaseOrderId')
    jsonArrayMember(() => PurchaseToPay.PurchaseOrderItemExtra)(PurchaseToPay__PurchaseOrderItem.prototype, 'itemExtras')
    jsonArrayMember(() => PurchaseToPay.PurchaseGoodsReceiptNoteItem)(PurchaseToPay__PurchaseOrderItem.prototype, 'purchaseGoodsReceiptNoteItem')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItem.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItem.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItem.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItem.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItem.prototype, 'versionNumber')
})

export const PurchaseOrderItemθId = Number;

export type PurchaseOrderItemθId = number;

namespace PurchaseToPay__PurchaseOrderItem {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseOrderItem'>

}
