import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, Input, Output, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation, EventEmitter, ApplicationRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core'
import { FileUpload } from '@shared/data/file-upload'
import { FileUploadSession } from '@shared/data/file-upload-session'

export type FileUploadDisplayStyle = 'dropZone' | 'button'

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: false,
})
export class FileUploadComponent implements OnInit, OnDestroy {
	/*@Input()*/ fileUploadSession: FileUploadSession = new FileUploadSession()
	@Input() multiple: boolean = false
	@Input() disabled: boolean = false
	@Input() allowedMimeTypes: string = '*'
	@Input() displayStyle: FileUploadDisplayStyle = 'dropZone'
	@Input() uploadToServer: boolean = false
	@Input() buttonType: 'mat-button' | 'mat-raised-button' | 'mat-stroked-button' | 'mat-flat-button' | 'mat-icon-button' | 'mat-icon-button mat-medium-icon-button' | 'mat-icon-button mat-small-icon-button' | 'mat-icon-button mat-tiny-icon-button' | 'mat-fab' | 'mat-mini-fab' | 'mat-fab extended' = 'mat-raised-button'
	@Input() buttonIcon: string = 'attach_file'
	@Input() color: string = ''

	@Output() filesSelected = new EventEmitter<File[]>()
	@Output() uploadStarted = new EventEmitter<FileUpload[]>()
	@Output() uploadFinished = new EventEmitter<FileUpload>()

	draggingOverDropzone = false
	
	constructor(
		private http: HttpClient,
	) { }
	
	ngOnInit(): void {
	}
	
	ngOnDestroy() {
	}

	private processFiles(files: File[]) {
		this.filesSelected.emit(files)

		if(this.uploadToServer) {
			const uploads: FileUpload[] = []

			for(const file of files) {
				const upload = new FileUpload(this.http, file)
				upload.uploadSession = this.fileUploadSession
				uploads.push(upload)
				this.fileUploadSession.addFileUpload(upload)
				upload.uploadFinished$.subscribe(() => this.uploadFinished.emit(upload))
			}
			
			this.uploadStarted.emit(uploads)
		}
	}
	
	onFilesSelected(event: Event) {
		const files: File[] = (event.target as any)?.files ?? []
		this.processFiles(files)
	}

	onFileDrag(event: Event, isDraggingOver: boolean) {
		if(isDraggingOver) {
			event.preventDefault()
			event.stopPropagation()
		}
		this.draggingOverDropzone = isDraggingOver && !this.disabled
	}

	onFileDrop(event: DragEvent) {
		event.preventDefault()
		event.stopPropagation()
		if(!this.disabled) {
			const files = new Array(event.dataTransfer?.files.length).fill(0).map((_, idx) => event.dataTransfer?.files.item(idx)!)
			this.processFiles(files)
		}
	}

	getColor(upload: FileUpload): ThemePalette {
		switch(upload.status) {
			case 'inprogress':
				return 'primary'

			case 'complete':
				return 'accent'

			case 'failed':
				return 'warn'

			default:
				return undefined
		}
	}
}
