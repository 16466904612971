// Compiled at 2025-01-14T22:27:45.765Z
import { System } from "./$modules";
import * as PurchaseToPay from "./PurchaseToPay";
import * as This from "./PurchaseToPay";
import { ScreenDefinition } from "@shared/types";
import { ApprovePurchaseOrder } from "../Screen/PurchaseToPay/ApprovePurchaseOrder";
import { ApprovePurchaseRequest } from "../Screen/PurchaseToPay/ApprovePurchaseRequest";
import { AwaitDeliveryAndPayment } from "../Screen/PurchaseToPay/AwaitDeliveryAndPayment";
import { ClassifyProcurementType } from "../Screen/PurchaseToPay/ClassifyProcurementType";
import { CompletePoInfo } from "../Screen/PurchaseToPay/CompletePoInfo";
import { ConfirmOrder } from "../Screen/PurchaseToPay/ConfirmOrder";
import { CreateArInvoice } from "../Screen/PurchaseToPay/CreateArInvoice";
import { Dashboard } from "../Screen/PurchaseToPay/Dashboard";
import { DefineSchedule } from "../Screen/PurchaseToPay/DefineSchedule";
import { EnterInvoice } from "../Screen/PurchaseToPay/EnterInvoice";
import { EvaluateOffers } from "../Screen/PurchaseToPay/EvaluateOffers";
import { GoodsReceipt } from "../Screen/PurchaseToPay/GoodsReceipt";
import { HandleArInvoiceEvent } from "../Screen/PurchaseToPay/HandleArInvoiceEvent";
import { Home } from "../Screen/PurchaseToPay/Home";
import { IncludeApprovals } from "../Screen/PurchaseToPay/IncludeApprovals";
import { IncludeOfferedItems } from "../Screen/PurchaseToPay/IncludeOfferedItems";
import { IncludePurchaseInfo } from "../Screen/PurchaseToPay/IncludePurchaseInfo";
import { IncludeRequest } from "../Screen/PurchaseToPay/IncludeRequest";
import { InvoiceReceipt } from "../Screen/PurchaseToPay/InvoiceReceipt";
import { MainLayout } from "../Screen/PurchaseToPay/MainLayout";
import { ManageMasterData } from "../Screen/PurchaseToPay/ManageMasterData";
import { PortalWorklist } from "../Screen/PurchaseToPay/PortalWorklist";
import { ProcessLayout } from "../Screen/PurchaseToPay/ProcessLayout";
import { RaiseRequest } from "../Screen/PurchaseToPay/RaiseRequest";
import { SearchRequest } from "../Screen/PurchaseToPay/SearchRequest";
import { SelectVendors } from "../Screen/PurchaseToPay/SelectVendors";
import { Test } from "../Screen/PurchaseToPay/Test";
import { UserProfile } from "../Screen/PurchaseToPay/UserProfile";
import { ViewPurchaseRequest } from "../Screen/PurchaseToPay/ViewPurchaseRequest";
import { WaitForOffers } from "../Screen/PurchaseToPay/WaitForOffers";

// Auto-generated by Lowgile
export const $ModuleId = 'PurchaseToPay';

export * from "../StaticEntity/PurchaseToPay/AmountType";
export * from "../StaticEntity/PurchaseToPay/ArCommunicationChannel";
export * from "../StaticEntity/PurchaseToPay/ArDisputeStatus";
export * from "../StaticEntity/PurchaseToPay/ArEmailTemplateLanguage";
export * from "../StaticEntity/PurchaseToPay/ArInvoiceStatus";
export * from "../StaticEntity/PurchaseToPay/ArScheduleEventType";
export * from "../StaticEntity/PurchaseToPay/CostAllocationAccount";
export * from "../StaticEntity/PurchaseToPay/CostAllocationType";
export * from "../StaticEntity/PurchaseToPay/CostCenter";
export * from "../StaticEntity/PurchaseToPay/Currency";
export * from "../StaticEntity/PurchaseToPay/ExtraType";
export * from "../StaticEntity/PurchaseToPay/IncoTerm";
export * from "../StaticEntity/PurchaseToPay/ItemCategory";
export * from "../StaticEntity/PurchaseToPay/MockupFile";
export * from "../StaticEntity/PurchaseToPay/PaymentStatus";
export * from "../StaticEntity/PurchaseToPay/PaymentTerm";
export * from "../StaticEntity/PurchaseToPay/Product";
export * from "../StaticEntity/PurchaseToPay/ProductGroup";
export * from "../StaticEntity/PurchaseToPay/ProjectCode";
export * from "../StaticEntity/PurchaseToPay/PurchaseRejectionReason";
export * from "../StaticEntity/PurchaseToPay/PurchaseRequestStatus";
export * from "../StaticEntity/PurchaseToPay/PurchaseVendorOfferSelectionStatus";
export * from "../StaticEntity/PurchaseToPay/PurchaseVendorOfferStatus";
export * from "../StaticEntity/PurchaseToPay/PurchasingGroup";
export * from "../StaticEntity/PurchaseToPay/TaxRate";
export * from "../StaticEntity/PurchaseToPay/Unit";
export * from "../StaticEntity/PurchaseToPay/Vendor";
export * from "../StaticEntity/PurchaseToPay/VendorContract";
export * from "../Entity/PurchaseToPay/ArDispute";
export * from "../Entity/PurchaseToPay/ArEmailTemplate";
export * from "../Entity/PurchaseToPay/ArInvoice";
export * from "../Entity/PurchaseToPay/ArSchedule";
export * from "../Entity/PurchaseToPay/ArScheduleEvent";
export * from "../Entity/PurchaseToPay/ArScheduleEventLog";
export * from "../Entity/PurchaseToPay/Customer";
export * from "../Entity/PurchaseToPay/ExchangeRate";
export * from "../Entity/PurchaseToPay/PurchaseGoodsReceiptNote";
export * from "../Entity/PurchaseToPay/PurchaseGoodsReceiptNoteItem";
export * from "../Entity/PurchaseToPay/PurchaseInvoice";
export * from "../Entity/PurchaseToPay/PurchaseInvoiceCostAllocation";
export * from "../Entity/PurchaseToPay/PurchaseInvoiceItem";
export * from "../Entity/PurchaseToPay/PurchaseInvoiceItemCostAllocation";
export * from "../Entity/PurchaseToPay/PurchaseInvoiceItemMatch";
export * from "../Entity/PurchaseToPay/PurchaseOrder";
export * from "../Entity/PurchaseToPay/PurchaseOrderApproval";
export * from "../Entity/PurchaseToPay/PurchaseOrderExtra";
export * from "../Entity/PurchaseToPay/PurchaseOrderItem";
export * from "../Entity/PurchaseToPay/PurchaseOrderItemExtra";
export * from "../Entity/PurchaseToPay/PurchaseRequest";
export * from "../Entity/PurchaseToPay/PurchaseRequestApproval";
export * from "../Entity/PurchaseToPay/PurchaseRequestEvaluationAttachment";
export * from "../Entity/PurchaseToPay/PurchaseRequestItem";
export * from "../Entity/PurchaseToPay/PurchaseRequestItemCostAllocation";
export * from "../Entity/PurchaseToPay/PurchaseRequisition";
export * from "../Entity/PurchaseToPay/PurchaseRequisitionApproval";
export * from "../Entity/PurchaseToPay/PurchaseRequisitionItem";
export * from "../Entity/PurchaseToPay/PurchaseVendor";
export * from "../Entity/PurchaseToPay/PurchaseVendorOffer";
export * from "../Entity/PurchaseToPay/PurchaseVendorOfferAttachment";
export * from "../Entity/PurchaseToPay/PurchaseVendorOfferItem";
export * from "../Entity/PurchaseToPay/Util";
export * from "../ServerDataStore/PurchaseToPay/Db";
export * from "../UserDefinedConfiguration/PurchaseToPay/DefaultBillingAddress";
export * from "../UserDefinedConfiguration/PurchaseToPay/DefaultDeliveryAddress";
export * from "../Label/PurchaseToPay/$translations";
export * from "../Resource/PurchaseToPay/$resources";
export { $Config } from "../ConfigurationItem/PurchaseToPay/config";
export * as $Process from "../Process/PurchaseToPay/$processes";
export * from "../Role/PurchaseToPay/$roles";
export * from "../Permission/PurchaseToPay/$permissions";

export const $Screens = {
    ApprovePurchaseOrder: {
        moduleId: 'PurchaseToPay', boId: 'ApprovePurchaseOrder', component: ApprovePurchaseOrder, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ApprovePurchaseOrder`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    ApprovePurchaseRequest: {
        moduleId: 'PurchaseToPay', boId: 'ApprovePurchaseRequest', component: ApprovePurchaseRequest, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ApprovePurchaseRequest`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    AwaitDeliveryAndPayment: {
        moduleId: 'PurchaseToPay', boId: 'AwaitDeliveryAndPayment', component: AwaitDeliveryAndPayment, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/AwaitDeliveryAndPayment`
        }
    } as ScreenDefinition<{
    }, {
        request?: This.PurchaseRequest,
    }>,
    ClassifyProcurementType: {
        moduleId: 'PurchaseToPay', boId: 'ClassifyProcurementType', component: ClassifyProcurementType, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ClassifyProcurementType`
        }
    } as ScreenDefinition<{
    }, {
        request?: This.PurchaseRequest,
    }>,
    CompletePoInfo: {
        moduleId: 'PurchaseToPay', boId: 'CompletePoInfo', component: CompletePoInfo, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/CompletePoInfo`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    ConfirmOrder: {
        moduleId: 'PurchaseToPay', boId: 'ConfirmOrder', component: ConfirmOrder, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ConfirmOrder`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    CreateArInvoice: {
        moduleId: 'PurchaseToPay', boId: 'CreateArInvoice', component: CreateArInvoice, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/CreateArInvoice`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    Dashboard: {
        moduleId: 'PurchaseToPay', boId: 'Dashboard', component: Dashboard, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/Dashboard`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    DefineSchedule: {
        moduleId: 'PurchaseToPay', boId: 'DefineSchedule', component: DefineSchedule, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/DefineSchedule`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    EnterInvoice: {
        moduleId: 'PurchaseToPay', boId: 'EnterInvoice', component: EnterInvoice, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/EnterInvoice`
        }
    } as ScreenDefinition<{
    }, {
        showPoSelection?: boolean,
        selectedPo: This.PurchaseOrder,
        invoice?: This.PurchaseInvoice,
        allowInvoiceSelection?: boolean,
    }>,
    EvaluateOffers: {
        moduleId: 'PurchaseToPay', boId: 'EvaluateOffers', component: EvaluateOffers, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/EvaluateOffers`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    GoodsReceipt: {
        moduleId: 'PurchaseToPay', boId: 'GoodsReceipt', component: GoodsReceipt, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/GoodsReceipt`
        }
    } as ScreenDefinition<{
    }, {
        selectedRequest: This.PurchaseRequest,
    }>,
    HandleArInvoiceEvent: {
        moduleId: 'PurchaseToPay', boId: 'HandleArInvoiceEvent', component: HandleArInvoiceEvent, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/HandleArInvoiceEvent`
        }
    } as ScreenDefinition<{
    }, {
        log?: This.ArScheduleEventLog,
        event?: This.ArScheduleEvent,
        invoice?: This.ArInvoice,
    }>,
    Home: {
        moduleId: 'PurchaseToPay', boId: 'Home', component: Home, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/Home`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    IncludeApprovals: {
        moduleId: 'PurchaseToPay', boId: 'IncludeApprovals', component: IncludeApprovals, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/IncludeApprovals`
        }
    } as ScreenDefinition<{
    }, {
        areaTitle?: string,
        approvals?: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[],
    }>,
    IncludeOfferedItems: {
        moduleId: 'PurchaseToPay', boId: 'IncludeOfferedItems', component: IncludeOfferedItems, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/IncludeOfferedItems`
        }
    } as ScreenDefinition<{
    }, {
        offer?: This.PurchaseVendorOffer,
    }>,
    IncludePurchaseInfo: {
        moduleId: 'PurchaseToPay', boId: 'IncludePurchaseInfo', component: IncludePurchaseInfo, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/IncludePurchaseInfo`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
        stage?: 'request' | 'wait' | 'evaluate' | 'pick' | 'issuePo',
        showRequest?: boolean | 'disabled',
        showVendors: boolean | 'disabled',
        showEvaluation: boolean | 'disabled',
        showOrders: boolean | 'disabled',
        showPurchaseOrders: boolean | 'disabled',
        selectedTabId: string,
    }>,
    IncludeRequest: {
        moduleId: 'PurchaseToPay', boId: 'IncludeRequest', component: IncludeRequest, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/IncludeRequest`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    InvoiceReceipt: {
        moduleId: 'PurchaseToPay', boId: 'InvoiceReceipt', component: InvoiceReceipt, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/InvoiceReceipt`
        }
    } as ScreenDefinition<{
    }, {
        selectedRequest: This.PurchaseRequest,
    }>,
    MainLayout: {
        moduleId: 'PurchaseToPay', boId: 'MainLayout', component: MainLayout, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/MainLayout`
        }
    } as ScreenDefinition<{
    }, {
        screenTitle: string,
    }>,
    ManageMasterData: {
        moduleId: 'PurchaseToPay', boId: 'ManageMasterData', component: ManageMasterData, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ManageMasterData`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    PortalWorklist: {
        moduleId: 'PurchaseToPay', boId: 'PortalWorklist', component: PortalWorklist, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/PortalWorklist`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    ProcessLayout: {
        moduleId: 'PurchaseToPay', boId: 'ProcessLayout', component: ProcessLayout, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ProcessLayout`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    RaiseRequest: {
        moduleId: 'PurchaseToPay', boId: 'RaiseRequest', component: RaiseRequest, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/RaiseRequest`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    SearchRequest: {
        moduleId: 'PurchaseToPay', boId: 'SearchRequest', component: SearchRequest, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/SearchRequest`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    SelectVendors: {
        moduleId: 'PurchaseToPay', boId: 'SelectVendors', component: SelectVendors, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/SelectVendors`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
    Test: {
        moduleId: 'PurchaseToPay', boId: 'Test', component: Test, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/Test`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    UserProfile: {
        moduleId: 'PurchaseToPay', boId: 'UserProfile', component: UserProfile, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/UserProfile`
        }
    } as ScreenDefinition<{
    }, {
        }>,
    ViewPurchaseRequest: {
        moduleId: 'PurchaseToPay', boId: 'ViewPurchaseRequest', component: ViewPurchaseRequest, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/ViewPurchaseRequest`
        }
    } as ScreenDefinition<{
        requestId?: number,
    }, {
        }>,
    WaitForOffers: {
        moduleId: 'PurchaseToPay', boId: 'WaitForOffers', component: WaitForOffers, get fullUrl() {
            return `${System.$Config.Environment.publicUrl}/PurchaseToPay/WaitForOffers`
        }
    } as ScreenDefinition<{
    }, {
        request: This.PurchaseRequest,
    }>,
};
