// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseOrderItemExtra {
    @jsonMember(() => PurchaseToPay__PurchaseOrderItemExtra.Id)
    id: PurchaseToPay__PurchaseOrderItemExtra.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "amountType", "amountType$id", "extraType", "extraType$id", "amount", "orderItem", "orderItemId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "amountType": "PurchaseToPay.AmountType", "amountType$id": "string", "extraType": "PurchaseToPay.ExtraType", "extraType$id": "string", "amount": "number", "orderItem": "PurchaseToPay.PurchaseOrderItem", "orderItemId": "PurchaseToPay.PurchaseOrderItem.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["amountType", "extraType"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ orderItem: 'PurchaseToPay.PurchaseOrderItem' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θamountType?: This.AmountType = new This.AmountType();
    private θextraType?: This.ExtraType = new This.ExtraType();
    private θamount?: number = undefined;
    private θorderItem?: PurchaseToPay.PurchaseOrderItem = undefined;
    private θorderItemId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        amount: 0,
        orderItem: 0,
        orderItemId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        amount: () => {
            return false
        }
        , orderItem: () => {
            return false
        }
        , orderItemId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseOrderItemExtra' {
        return 'PurchaseToPay.PurchaseOrderItemExtra'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseOrderItemExtra' {
        return 'PurchaseToPay.PurchaseOrderItemExtra'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseOrderItemExtra' {
        return 'PurchaseToPay.PurchaseOrderItemExtra'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseOrderItemExtra' {
        return 'PurchaseToPay.PurchaseOrderItemExtra'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseOrderItemExtra[EntityInfoSymbol]
    }

    get amountType(): This.AmountType {
        return this.θamountType
    }

    set amountType(amountType: This.AmountType | This.AmountType.Id) {
        if (!this.θamountType) this.θamountType = new This.AmountType()
        this.θamountType.θupdate(amountType)
    }

    @jsonMember(String)
    get 'amountType$id'(): string {
        return this.θamountType?.id
    }

    set 'amountType$id'(amountType: string) {
        if (!this.θamountType) this.θamountType = new This.AmountType()
        this.θamountType.id = amountType as any
    }

    get extraType(): This.ExtraType {
        return this.θextraType
    }

    set extraType(extraType: This.ExtraType | This.ExtraType.Id) {
        if (!this.θextraType) this.θextraType = new This.ExtraType()
        this.θextraType.θupdate(extraType)
    }

    @jsonMember(String)
    get 'extraType$id'(): string {
        return this.θextraType?.id
    }

    set 'extraType$id'(extraType: string) {
        if (!this.θextraType) this.θextraType = new This.ExtraType()
        this.θextraType.id = extraType as any
    }

    get amount() {
        return this.θamount
    }

    set amount(amount: number) {
        this.θamount = LowgileDataUtil.coerceDataType(amount, 'number', false, true)
    }

    get orderItem() {
        return this.θorderItem
    }

    set orderItem(orderItem: PurchaseToPay.PurchaseOrderItem) {
        this.θorderItem = LowgileDataUtil.coerceDataType(orderItem, PurchaseToPay.PurchaseOrderItem, false, true)
    }

    get orderItemId() {
        return this.θorderItemId
    }

    set orderItemId(orderItemId: number) {
        this.θorderItemId = LowgileDataUtil.coerceDataType(orderItemId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseOrderItemExtra.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseOrderItemExtra>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseOrderItemExtra(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseOrderItemExtra>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseOrderItemExtra>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('amountType$id', 'extraType$id', 'amount', 'orderItem', 'orderItemId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseOrderItemExtra { }

export import PurchaseOrderItemExtra = PurchaseToPay__PurchaseOrderItemExtra
setTimeout(() => {
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'amount')
    jsonMember(() => PurchaseToPay.PurchaseOrderItem)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'orderItem')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'orderItemId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrderItemExtra.prototype, 'versionNumber')
})

export const PurchaseOrderItemExtraθId = Number;

export type PurchaseOrderItemExtraθId = number;

namespace PurchaseToPay__PurchaseOrderItemExtra {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseOrderItemExtra'>

}
