import { AsyncValidationRule, SyncValidationRule, ValidationRules } from '@shared/types'
import { AbstractControl, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms'
import { AppContext } from '../app-context'
import { ChangeDetectionService } from '../services/change-detection.service'
import { ScopeDirective } from '../directives/scope.directive'

export class ValidationUtil {
	static createSyncValidationFunction(validationRules: SyncValidationRule[] | undefined, scopeDirective: ScopeDirective): ValidatorFn | undefined {
		if(!validationRules?.length) return undefined

		return (control: AbstractControl) => {
			let errors: Record<string, string> | null = null
			const scope = scopeDirective?.viewRef?.context?.$implicit ?? {}

			let idx = 0
			for(const rule of validationRules) {
				const result = rule(control.value, scope)
				if(result?.error) {
					const ruleId = result.ruleId || `rule_${idx}`
					errors = {
						...errors!,
						[ruleId]: result.error,
					}
				}
				if(result?.skipSubsequentRules) break
				idx++
			}

			return errors
		}
	}

	static createAsyncValidationFunction(validationRules: AsyncValidationRule[] | undefined, scopeDirective: ScopeDirective): AsyncValidatorFn | undefined {
		if(!validationRules?.length) return undefined

		return async (control: AbstractControl) => {
			let errors: Record<string, string> | null = null
			const scope = scopeDirective?.viewRef?.context?.$implicit ?? {}

			let idx = 0
			for(const rule of validationRules) {
				const result = await rule(control.value, scope)
				if(result?.error) {
					const ruleId = result.ruleId || `rule_${idx}`
					errors = {
						...errors!,
						[ruleId]: result.error,
					}
				}
				if(result?.skipSubsequentRules) break
				idx++
			}

			return errors
		}
	}

	static async hasValidatorError(formControl: FormControl, validationRules: ValidationRules | undefined, $scope: any) {
		const syncErrors = validationRules?.sync.map(validator => validator(undefined, $scope)) ?? []
		const errorEntries = syncErrors.filter(error => error).map(error => [error!.ruleId, error!.error])

		if(!errorEntries.length) {
			const asyncErrorPromises = validationRules?.async.map(validator => validator(undefined, $scope)) ?? []
			const asyncErrors = await Promise.all(asyncErrorPromises)
			errorEntries.push(...asyncErrors.filter(error => error).map(error => [error!.ruleId, error!.error]))
		}

		formControl.setErrors(errorEntries.length ? Object.fromEntries(errorEntries) : null)
		return errorEntries.length > 0
	}
}