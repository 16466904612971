// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    request: This.PurchaseRequest;
    addErpVendorId: string;
    stage: 'request' | 'wait' | 'evaluate' | 'pick' | 'issuePo';
    showRequest: boolean | 'disabled';
    showVendors: boolean | 'disabled';
    showEvaluation: boolean | 'disabled';
    showOrders: boolean | 'disabled';
    showPurchaseOrders: boolean | 'disabled';
    selectedTabId: string;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-IncludePurchaseInfo',
    templateUrl: './IncludePurchaseInfo.html',
    styleUrls: ['./IncludePurchaseInfo.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class IncludePurchaseInfo extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.IncludePurchaseInfo';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_330 = (screen: Properties) => ['delete', 'file', 'uploadedOn', 'source'];
    $validators: Record<number, ValidationRules> = {
        92: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, form, offer, offerIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        385: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, form, offer, offerIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        94: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, form, offer, offerIndex } = $scope
                return undefined
                if (offer.totalPrice <= screen.request.overallBudget) return undefined
                if (offer.commentInternal?.trim()) return undefined
                return {
                    error: 'Required when budget is exceeded'
                }
            }],
            async: [],
        },
        440: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, form, offer, offerIndex } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            },
            ($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, form, offer, offerIndex } = $scope
                if (!offer.selectionStatus.isUndecided) return
                return {
                    ruleId: 'undecided',
                    error: 'Please make a buying decision'
                }
            }],
            async: [],
        }
    };
    componentStore = this.storeService.createComponentStore<Properties>({
        request: this.request !== undefined ? this.request : (undefined) as This.PurchaseRequest,
        addErpVendorId: this.addErpVendorId !== undefined ? this.addErpVendorId : (undefined) as string,
        stage: this.stage !== undefined ? this.stage : (undefined) as 'request' | 'wait' | 'evaluate' | 'pick' | 'issuePo',
        showRequest: this.showRequest !== undefined ? this.showRequest : (true) as boolean | 'disabled',
        showVendors: this.showVendors !== undefined ? this.showVendors : (undefined) as boolean | 'disabled',
        showEvaluation: this.showEvaluation !== undefined ? this.showEvaluation : (undefined) as boolean | 'disabled',
        showOrders: this.showOrders !== undefined ? this.showOrders : (undefined) as boolean | 'disabled',
        showPurchaseOrders: this.showPurchaseOrders !== undefined ? this.showPurchaseOrders : (undefined) as boolean | 'disabled',
        selectedTabId: this.selectedTabId !== undefined ? this.selectedTabId : (undefined) as string
    });

    get $inputs() {
        return {
            request: this.request,
            stage: this.stage,
            showRequest: this.showRequest,
            showVendors: this.showVendors,
            showEvaluation: this.showEvaluation,
            showOrders: this.showOrders,
            showPurchaseOrders: this.showPurchaseOrders,
            selectedTabId: this.selectedTabId
        }
    }

    @Input()
    get request() {
        return this.screen?.request
    }

    set request(request: This.PurchaseRequest) {
        request = (request as any)?.[ProxyTargetSymbol] ?? request
        this.storeService.setProperty([this.componentStore.name], 'request', request)
    }

    get addErpVendorId() {
        return this.screen?.addErpVendorId
    }

    set addErpVendorId(addErpVendorId: string) {
        addErpVendorId = (addErpVendorId as any)?.[ProxyTargetSymbol] ?? addErpVendorId
        this.storeService.setProperty([this.componentStore.name], 'addErpVendorId', addErpVendorId)
    }

    @Input()
    get stage() {
        return this.screen?.stage
    }

    set stage(stage: 'request' | 'wait' | 'evaluate' | 'pick' | 'issuePo') {
        stage = (stage as any)?.[ProxyTargetSymbol] ?? stage
        this.storeService.setProperty([this.componentStore.name], 'stage', stage)
    }

    @Input()
    get showRequest() {
        return this.screen?.showRequest
    }

    set showRequest(showRequest: boolean | 'disabled') {
        showRequest = (showRequest as any)?.[ProxyTargetSymbol] ?? showRequest
        this.storeService.setProperty([this.componentStore.name], 'showRequest', showRequest)
    }

    @Input()
    get showVendors() {
        return this.screen?.showVendors
    }

    set showVendors(showVendors: boolean | 'disabled') {
        showVendors = (showVendors as any)?.[ProxyTargetSymbol] ?? showVendors
        this.storeService.setProperty([this.componentStore.name], 'showVendors', showVendors)
    }

    @Input()
    get showEvaluation() {
        return this.screen?.showEvaluation
    }

    set showEvaluation(showEvaluation: boolean | 'disabled') {
        showEvaluation = (showEvaluation as any)?.[ProxyTargetSymbol] ?? showEvaluation
        this.storeService.setProperty([this.componentStore.name], 'showEvaluation', showEvaluation)
    }

    @Input()
    get showOrders() {
        return this.screen?.showOrders
    }

    set showOrders(showOrders: boolean | 'disabled') {
        showOrders = (showOrders as any)?.[ProxyTargetSymbol] ?? showOrders
        this.storeService.setProperty([this.componentStore.name], 'showOrders', showOrders)
    }

    @Input()
    get showPurchaseOrders() {
        return this.screen?.showPurchaseOrders
    }

    set showPurchaseOrders(showPurchaseOrders: boolean | 'disabled') {
        showPurchaseOrders = (showPurchaseOrders as any)?.[ProxyTargetSymbol] ?? showPurchaseOrders
        this.storeService.setProperty([this.componentStore.name], 'showPurchaseOrders', showPurchaseOrders)
    }

    @Input()
    get selectedTabId() {
        return this.screen?.selectedTabId
    }

    set selectedTabId(selectedTabId: string) {
        selectedTabId = (selectedTabId as any)?.[ProxyTargetSymbol] ?? selectedTabId
        this.storeService.setProperty([this.componentStore.name], 'selectedTabId', selectedTabId)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.request = this.dialogData.request ?? this.request
            this.stage = this.dialogData.stage ?? this.stage
            this.showRequest = this.dialogData.showRequest ?? this.showRequest
            this.showVendors = this.dialogData.showVendors ?? this.showVendors
            this.showEvaluation = this.dialogData.showEvaluation ?? this.showEvaluation
            this.showOrders = this.dialogData.showOrders ?? this.showOrders
            this.showPurchaseOrders = this.dialogData.showPurchaseOrders ?? this.showPurchaseOrders
            this.selectedTabId = this.dialogData.selectedTabId ?? this.selectedTabId
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen


                screen.addErpVendorId = undefined

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_DropdownBlock_0_1_0_1_0_0_0_1_0_0_selectionChange_addErpVendor_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}

        let selection: any = $event
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addErpVendor($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_1_0_1_0_0_0_1_0_1_0_click_addVendor_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addVendor($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_CheckboxBlock_0_1_0_1_0_0_0_2_0_0_0_0_0_1_1_0_0_1_0_change_setIsItemOfferedForVendor_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, requestItem, requestItemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, requestItem: This.PurchaseRequestItem, requestItemIndex: number } = $scope ?? {}

        let isChecked: boolean = $event.checked
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_setIsItemOfferedForVendor(offer, requestItem, isChecked, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    getter_CheckboxBlock_0_1_0_1_0_0_0_2_0_0_0_0_0_1_1_0_0_1_0($scope: any): boolean {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, requestItem, requestItemIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, requestItem: This.PurchaseRequestItem, requestItemIndex: number } = $scope ?? {}

        return (offer.itemsOffered.some(offerItem => offerItem.requestItem.requestLineNumber == requestItem.requestLineNumber))
    }

    _ForBlock_collectionCode_348($scope: any): readonly any[] {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number } = $scope ?? {}

        return (This.VendorContract.getEntryList())
    }

    async handler_CheckboxBlock_0_1_0_1_0_0_0_2_0_0_0_0_0_1_1_0_1_1_0_0_change_toggleVendorContractId_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, contract, contractIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, contract: any, contractIndex: number } = $scope ?? {}

        let isChecked: boolean = $event.checked
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_toggleVendorContractId(offerIndex, contract.id, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    getter_CheckboxBlock_0_1_0_1_0_0_0_2_0_0_0_0_0_1_1_0_1_1_0_0($scope: any): boolean {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, contract, contractIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, contract: any, contractIndex: number } = $scope ?? {}

        return (offer.referencedContractIds?.split('|').includes(contract.id))
    }

    async handler_ButtonBlock_0_1_0_1_0_0_0_2_0_0_0_0_0_1_3_0_0_click_regenerateRfqText_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_regenerateRfqText(offer, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_330($scope: any): any {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number } = $scope ?? {}

        return (offer.offerAttachments)
    }

    _DataTableBlock_additionalFindOptions_330($scope: any): any {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_334_334($scope: any): any {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, attachment: This.PurchaseVendorOfferAttachment, attachmentIndex: number } = $scope ?? {}

        return ''
    }

    async handler_LinkBlock_0_1_0_1_0_0_0_2_0_0_0_0_1_0_0_0_0_0_0_0_0_0_0_click_deleteOfferAttachment_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, attachment: This.PurchaseVendorOfferAttachment, attachmentIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_deleteOfferAttachment(offerIndex, attachmentIndex, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableColumnBlock_cellText_331_331($scope: any): any {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, attachment: This.PurchaseVendorOfferAttachment, attachmentIndex: number } = $scope ?? {}

        return ''
    }

    async handler_LinkBlock_0_1_0_1_0_0_0_2_0_0_0_0_1_0_0_0_0_0_0_1_0_0_click_downloadOfferAttachment_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, attachment: This.PurchaseVendorOfferAttachment, attachmentIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_downloadOfferAttachment(attachment, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableColumnBlock_cellText_332_332($scope: any): any {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, attachment: This.PurchaseVendorOfferAttachment, attachmentIndex: number } = $scope ?? {}

        return (Sys.Date.format(attachment.uploadedOn, 'dd.MM.yyyy HH:mm'))
    }

    _DataTableColumnBlock_cellText_333_333($scope: any): any {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number, attachment: This.PurchaseVendorOfferAttachment, attachmentIndex: number } = $scope ?? {}

        return (attachment.source)
    }

    async handler_FileUploadBlock_0_1_0_1_0_0_0_2_0_0_0_0_1_0_0_0_3_0_0_filesSelected_addOfferAttachments_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number } = $scope ?? {}

        let files: File[] = [...$event]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addOfferAttachments(offerIndex, files, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_1_0_1_0_0_0_2_0_0_0_1_0_0_click_removeVendor_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, offer, offerIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, offer: This.PurchaseVendorOffer, offerIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeVendor(offerIndex, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_LinkBlock_0_1_0_2_0_0_0_0_0_0_0_0_0_0_click_deleteEvaluationAttachment_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form, attachment, attachmentIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, attachment: This.PurchaseRequestEvaluationAttachment, attachmentIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_deleteEvaluationAttachment(attachmentIndex, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_FileUploadBlock_0_1_0_2_0_0_0_1_0_0_filesSelected_addEvaluationAttachments_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, form }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}

        let files: File[] = [...$event]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addEvaluationAttachments(files, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_addItem($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.request.items.push(new This.PurchaseRequestItem)
        }
    }

    async action_removeItem(index: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        index = this.storeService.replaceWithStateVersion(index, $context)
        {
            screen.request.items.splice(index, 1)
        }
    }

    async action_addVendor($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            const offerNumber = Sys.Text.calculateNextFreeName(screen.request.requestNumber.replace(/^PR/, 'RFQ') + '-', screen.request.vendorOffers, vo => vo.offerNumber, 2, true)

            screen.request.vendorOffers.push(new This.PurchaseVendorOffer({
                itemsOffered: [],
                offerAttachments: [],
                offerNumber,
                purchaseOrders: [],
                vendorAddress: '',
                vendorEmail: '',
            }))
        }
    }

    async action_addErpVendor($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            const vendorEntry = await This.Vendor.getEntry(screen.addErpVendorId)
            const offerNumber = Sys.Text.calculateNextFreeName(screen.request.requestNumber.replace(/^PR/, 'RFQ') + '-', screen.request.vendorOffers, vo => vo.offerNumber, 2, true)

            screen.request.vendorOffers.push(new This.PurchaseVendorOffer({
                itemsOffered: [],
                offerAttachments: [],
                offerNumber,
                purchaseOrders: [],
                vendorId: vendorEntry.id,
                vendorName: vendorEntry.name,
                vendorAddress: vendorEntry.address,
                vendorEmail: vendorEntry.email,
            }))
            screen.addErpVendorId = undefined
        }
    }

    async action_removeVendor(index: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        index = this.storeService.replaceWithStateVersion(index, $context)
        {
            screen.request.vendorOffers.splice(index, 1)
        }
    }

    async action_addOfferAttachments(offerIndex: number, files: File[], $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offerIndex = this.storeService.replaceWithStateVersion(offerIndex, $context)
        files = this.storeService.replaceWithStateVersion(files, $context)
        {
            Sys.App.isSpinnerVisible = true

            const offer = screen.request.vendorOffers[offerIndex]
            for (const file of files) {
                offer.offerAttachments.push(new This.PurchaseVendorOfferAttachment({
                    content: Sys.Text.convert(await file.arrayBuffer(), 'binary', 'base64'),
                    fileName: file.name,
                    uploadedOn: new Date(),
                    source: 'Uploaded by ' + (Sys.User.currentUser?.getName() || 'unknown user'),
                }))
                const docBase64 = Sys.Text.convert(await file.arrayBuffer(), 'binary', 'base64')
                const offerItems = await screen.request.parseIncomingOfferDocument(This.Db, docBase64, offer.id)
                for (const offerItem of offerItems) {
                    offerItem.requestItem = screen.request.items.find(i => i.id == offerItem.requestItemId)
                }

                screen.request.vendorOffers[offerIndex].itemsOffered = offerItems
            }

            // if(!offer.itemsOffered) {
            //     console.log(JSON.stringify(screen.request.items))
            //     offer.itemsOffered = screen.request.items.map(item => new This.PurchaseVendorOfferItem({
            //         requestItem: item,
            //         quantityOffered: item.quantity,
            //     }))
            // }

            if (offer.offerStatus.isRequested) offer.offerStatus.setReceived()

            Sys.App.isSpinnerVisible = false
        }
    }

    async action_deleteOfferAttachment(offerIndex: number, attachmentIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offerIndex = this.storeService.replaceWithStateVersion(offerIndex, $context)
        attachmentIndex = this.storeService.replaceWithStateVersion(attachmentIndex, $context)
        {
            screen.request.vendorOffers[offerIndex].offerAttachments.splice(attachmentIndex, 1)
        }
    }

    async action_regenerateRfqText(offer: This.PurchaseVendorOffer, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offer = this.storeService.replaceWithStateVersion(offer, $context)
        {
            try {
                offer.rfqText = await screen.request.generateRfqText(null, offer)
            } catch (err) {
                Sys.App.showNotification('error', err.message)
            }

        }
    }

    async action_addEvaluationAttachments(files: File[], $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        files = this.storeService.replaceWithStateVersion(files, $context)
        {
            if (!screen.request.evaluationAttachments) screen.request.evaluationAttachments = []
            for (const file of files) {
                screen.request.evaluationAttachments.push(new This.PurchaseRequestEvaluationAttachment({
                    content: Sys.Text.convert(await file.arrayBuffer(), 'binary', 'base64'),
                    fileName: file.name,
                }))
            }

        }
    }

    async action_deleteEvaluationAttachment(attachmentIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        attachmentIndex = this.storeService.replaceWithStateVersion(attachmentIndex, $context)
        {
            screen.request.evaluationAttachments.splice(attachmentIndex, 1)
        }
    }

    async action_setIsItemOfferedForVendor(offer: This.PurchaseVendorOffer, requestItem: This.PurchaseRequestItem, isOffered: boolean, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offer = this.storeService.replaceWithStateVersion(offer, $context)
        requestItem = this.storeService.replaceWithStateVersion(requestItem, $context)
        isOffered = this.storeService.replaceWithStateVersion(isOffered, $context)
        {
            if (isOffered) {
                offer.itemsOffered.push(new This.PurchaseVendorOfferItem({
                    requestItem,
                    description: requestItem.name,
                    quantityOffered: requestItem.quantity,
                }))
                offer.itemsOffered.sort((a, b) => a.requestItem.requestLineNumber.localeCompare(b.requestItem.requestLineNumber))
            } else {
                const index = offer.itemsOffered.findIndex(offerItem => offerItem.requestItem.requestLineNumber == requestItem.requestLineNumber)
                offer.itemsOffered.splice(index, 1)
            }

        }
    }

    async action_downloadOfferAttachment(attachment: This.PurchaseVendorOfferAttachment, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        attachment = this.storeService.replaceWithStateVersion(attachment, $context)
        {
            $event.preventDefault()
            const binary = Sys.Text.convert(attachment.content, 'base64', 'binary')
            Sys.App.provideDownload(binary, attachment.fileName)
        }
    }

    async action_toggleVendorContractId(offerIndex: number, contractId: string, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offerIndex = this.storeService.replaceWithStateVersion(offerIndex, $context)
        contractId = this.storeService.replaceWithStateVersion(contractId, $context)
        {
            const offer = screen.request.vendorOffers[offerIndex]
            const contractIds = offer.referencedContractIds?.split('|').filter(Boolean) ?? []
            const idx = contractIds.indexOf(contractId)
            if (idx >= 0) {
                contractIds.splice(idx, 1)
            } else {
                contractIds.push(contractId)
            }
            offer.referencedContractIds = contractIds.join('|')
        }
    }

    async action_addOfferItem(offerIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offerIndex = this.storeService.replaceWithStateVersion(offerIndex, $context)
        {
            screen.request.vendorOffers[offerIndex].itemsOffered.push(new This.PurchaseVendorOfferItem)
        }
    }

    async action_removeOfferItem(offerIndex: number, itemIndex: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        offerIndex = this.storeService.replaceWithStateVersion(offerIndex, $context)
        itemIndex = this.storeService.replaceWithStateVersion(itemIndex, $context)
        {
            screen.request.vendorOffers[offerIndex].itemsOffered.splice(itemIndex, 1)
        }
    }
}
