// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    mockFile: This.MockupFile;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-ManageMasterData',
    templateUrl: './ManageMasterData.html',
    styleUrls: ['./ManageMasterData.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ManageMasterData extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.ManageMasterData';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    $validators: Record<number, ValidationRules> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        mockFile: this.mockFile !== undefined ? this.mockFile : (new This.MockupFile) as This.MockupFile
    });

    get $inputs() {
        return {

        }
    }

    get mockFile() {
        return this.screen?.mockFile
    }

    set mockFile(mockFile: This.MockupFile) {
        mockFile = (mockFile as any)?.[ProxyTargetSymbol] ?? mockFile
        this.storeService.setProperty([this.componentStore.name], 'mockFile', mockFile)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {

        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen


                screen.mockFile = new This.MockupFile

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_LinkBlock_0_0_1_1_0_0_click_download_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_download($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_FileUploadBlock_0_0_1_1_0_1_filesSelected_upload_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let files: File[] = [...$event]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_upload(files[0], $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_download($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            $event.preventDefault()
            const base64 = await This.Util.downloadMockFile(screen.mockFile.id)
            if (base64) {
                Sys.App.provideDownload(Sys.Text.convert(base64, 'base64', 'binary'), screen.mockFile.fileName) //
            } else {
                Sys.App.showNotification('error', 'No such file found')
            }

        }
    }

    async action_upload(file: File, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        file = this.storeService.replaceWithStateVersion(file, $context)
        {
            const base64 = Sys.Text.convert(await file.arrayBuffer(), 'binary', 'base64')
            try {
                await This.Util.uploadMockFile(screen.mockFile.id, base64)

                await Promise.all([
                    This.Vendor.load(true),
                    This.ItemCategory.load(true),
                    This.CostCenter.load(true),
                    This.CostAllocationAccount.load(true),
                    This.ProjectCode.load(true),
                ])

                Sys.App.showNotification('success', 'Upload successful')
            } catch (err) {
                Sys.App.showNotification('error', err.message)
            }

        }
    }
}
