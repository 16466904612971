// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__Customer {
    @jsonMember(() => PurchaseToPay__Customer.Id)
    id: PurchaseToPay__Customer.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "name", "invoiceAddress", "language", "contactPerson", "contactPhoneNumber", "contactEmailAddress", "schedule", "scheduleId", "invoices", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "name": "string", "invoiceAddress": "string", "language": "string", "contactPerson": "string", "contactPhoneNumber": "string", "contactEmailAddress": "string", "schedule": "PurchaseToPay.ArSchedule", "scheduleId": "PurchaseToPay.ArSchedule.Id", "invoices": "PurchaseToPay.ArInvoice[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ schedule: 'PurchaseToPay.ArSchedule' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ invoices: 'PurchaseToPay.ArInvoice' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        embeddedEntities: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    static readonly Id = Number;
    [immerable] = true;
    private θname?: string = undefined;
    private θinvoiceAddress?: string = undefined;
    private θlanguage?: string = undefined;
    private θcontactPerson?: string = undefined;
    private θcontactPhoneNumber?: string = undefined;
    private θcontactEmailAddress?: string = undefined;
    private θschedule?: PurchaseToPay.ArSchedule = undefined;
    private θscheduleId?: number = null;
    private θinvoices?: PurchaseToPay.ArInvoice[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        name: 0,
        invoiceAddress: 0,
        language: 0,
        contactPerson: 0,
        contactPhoneNumber: 0,
        contactEmailAddress: 0,
        schedule: 0,
        scheduleId: 0,
        invoices: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        name: () => {
            return false
        }
        , invoiceAddress: () => {
            return false
        }
        , language: () => {
            return false
        }
        , contactPerson: () => {
            return false
        }
        , contactPhoneNumber: () => {
            return false
        }
        , contactEmailAddress: () => {
            return false
        }
        , schedule: () => {
            return false
        }
        , scheduleId: () => {
            return false
        }
        , invoices: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.Customer' {
        return 'PurchaseToPay.Customer'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.Customer' {
        return 'PurchaseToPay.Customer'
    }

    static get __baseType(): 'PurchaseToPay.Customer' {
        return 'PurchaseToPay.Customer'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.Customer' {
        return 'PurchaseToPay.Customer'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__Customer[EntityInfoSymbol]
    }

    get name() {
        return this.θname
    }

    set name(name: string) {
        this.θname = LowgileDataUtil.coerceDataType(name, 'string', false, true)
    }

    get invoiceAddress() {
        return this.θinvoiceAddress
    }

    set invoiceAddress(invoiceAddress: string) {
        this.θinvoiceAddress = LowgileDataUtil.coerceDataType(invoiceAddress, 'string', false, true)
    }

    get language() {
        return this.θlanguage
    }

    set language(language: string) {
        this.θlanguage = LowgileDataUtil.coerceDataType(language, 'string', false, true)
    }

    get contactPerson() {
        return this.θcontactPerson
    }

    set contactPerson(contactPerson: string) {
        this.θcontactPerson = LowgileDataUtil.coerceDataType(contactPerson, 'string', false, true)
    }

    get contactPhoneNumber() {
        return this.θcontactPhoneNumber
    }

    set contactPhoneNumber(contactPhoneNumber: string) {
        this.θcontactPhoneNumber = LowgileDataUtil.coerceDataType(contactPhoneNumber, 'string', false, true)
    }

    get contactEmailAddress() {
        return this.θcontactEmailAddress
    }

    set contactEmailAddress(contactEmailAddress: string) {
        this.θcontactEmailAddress = LowgileDataUtil.coerceDataType(contactEmailAddress, 'string', false, true)
    }

    get schedule() {
        return this.θschedule
    }

    set schedule(schedule: PurchaseToPay.ArSchedule) {
        this.θschedule = LowgileDataUtil.coerceDataType(schedule, PurchaseToPay.ArSchedule, false, true)
    }

    get scheduleId() {
        return this.θscheduleId
    }

    set scheduleId(scheduleId: number) {
        this.θscheduleId = LowgileDataUtil.coerceDataType(scheduleId, 'number', false, true)
    }

    get invoices() {
        return this.θinvoices
    }

    set invoices(invoices: PurchaseToPay.ArInvoice[]) {
        this.θinvoices = LowgileDataUtil.coerceDataType(invoices, PurchaseToPay.ArInvoice, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__Customer.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__Customer>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__Customer(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__Customer>, restrictToClass?: Class<Partial<PurchaseToPay__Customer>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('name', 'invoiceAddress', 'language', 'contactPerson', 'contactPhoneNumber', 'contactEmailAddress', 'schedule', 'scheduleId', 'invoices', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__Customer { }

export import Customer = PurchaseToPay__Customer
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'name')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'invoiceAddress')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'language')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'contactPerson')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'contactPhoneNumber')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'contactEmailAddress')
    jsonMember(() => PurchaseToPay.ArSchedule)(PurchaseToPay__Customer.prototype, 'schedule')
    jsonMember(() => Number)(PurchaseToPay__Customer.prototype, 'scheduleId')
    jsonArrayMember(() => PurchaseToPay.ArInvoice)(PurchaseToPay__Customer.prototype, 'invoices')
    jsonMember(() => Date)(PurchaseToPay__Customer.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__Customer.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__Customer.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__Customer.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__Customer.prototype, 'versionNumber')
})

export const CustomerθId = Number;

export type CustomerθId = number;

namespace PurchaseToPay__Customer {
    export type Id = NominalType<number, 'PurchaseToPay.Customer'>

}
