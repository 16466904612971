import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'const',
  standalone: false,
})
export class ConstPipe<T> implements PipeTransform {
	private isCached = false
	private cacheValue?: T 

	transform(arg: T): T {
		if(!this.isCached) this.cacheValue = arg
		return this.cacheValue!
  	}
}
