// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRules } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    schedules: This.ArSchedule[];
    selectedSchedule: This.ArSchedule;
    emailTemplates: This.ArEmailTemplate[];
    selectableEmailTemplates: This.ArEmailTemplate[];
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-DefineSchedule',
    templateUrl: './DefineSchedule.html',
    styleUrls: ['./DefineSchedule.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class DefineSchedule extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.DefineSchedule';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_28 = (screen: Properties) => ['id', 'name', 'type', 'description'];
    $validators: Record<number, ValidationRules> = {
        104: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, event, eventIdx, template, templateIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        106: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, event, eventIdx, template, templateIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        143: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, event, eventIdx, template, templateIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        },
        145: {
            sync: [($value: any, $scope: any) => {
                const { app, screen, $pathSegments, $disabled, event, eventIdx, template, templateIdx } = $scope

                if ($value) return undefined
                return {
                    error: Common.$T.validationRequired,
                    ruleId: 'isRequired',
                }

            }],
            async: [],
        }
    };
    componentStore = this.storeService.createComponentStore<Properties>({
        schedules: this.schedules !== undefined ? this.schedules : ([]) as This.ArSchedule[],
        selectedSchedule: this.selectedSchedule !== undefined ? this.selectedSchedule : (new This.ArSchedule) as This.ArSchedule,
        emailTemplates: this.emailTemplates !== undefined ? this.emailTemplates : ([]) as This.ArEmailTemplate[],
        selectableEmailTemplates: this.selectableEmailTemplates !== undefined ? this.selectableEmailTemplates : ([]) as This.ArEmailTemplate[]
    });

    get $inputs() {
        return {

        }
    }

    get schedules() {
        return this.screen?.schedules
    }

    set schedules(schedules: This.ArSchedule[]) {
        schedules = (schedules as any)?.[ProxyTargetSymbol] ?? schedules
        this.storeService.setProperty([this.componentStore.name], 'schedules', schedules)
    }

    get selectedSchedule() {
        return this.screen?.selectedSchedule
    }

    set selectedSchedule(selectedSchedule: This.ArSchedule) {
        selectedSchedule = (selectedSchedule as any)?.[ProxyTargetSymbol] ?? selectedSchedule
        this.storeService.setProperty([this.componentStore.name], 'selectedSchedule', selectedSchedule)
    }

    get emailTemplates() {
        return this.screen?.emailTemplates
    }

    set emailTemplates(emailTemplates: This.ArEmailTemplate[]) {
        emailTemplates = (emailTemplates as any)?.[ProxyTargetSymbol] ?? emailTemplates
        this.storeService.setProperty([this.componentStore.name], 'emailTemplates', emailTemplates)
    }

    get selectableEmailTemplates() {
        return this.screen?.selectableEmailTemplates
    }

    set selectableEmailTemplates(selectableEmailTemplates: This.ArEmailTemplate[]) {
        selectableEmailTemplates = (selectableEmailTemplates as any)?.[ProxyTargetSymbol] ?? selectableEmailTemplates
        this.storeService.setProperty([this.componentStore.name], 'selectableEmailTemplates', selectableEmailTemplates)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {

        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }
                const screen = θstate.screen


                screen.schedules = []
                screen.selectedSchedule = new This.ArSchedule
                screen.emailTemplates = []
                screen.selectableEmailTemplates = []

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_DataTableBlock_0_0_0_1_0_rowClick_loadSelectedSchedule_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let schedule: This.ArSchedule = $event[1], sIndex: number = $event[2]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_loadSelectedSchedule(schedule, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_28($scope: any): any {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.schedules)
    }

    _DataTableBlock_additionalFindOptions_28($scope: any): any {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_29_29($scope: any): any {
        let { app, screen, $pathSegments, $disabled, schedule, sIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, schedule: This.ArSchedule, sIndex: number } = $scope ?? {}

        return (schedule.id)
    }

    _DataTableColumnBlock_cellText_88_88($scope: any): any {
        let { app, screen, $pathSegments, $disabled, schedule, sIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, schedule: This.ArSchedule, sIndex: number } = $scope ?? {}

        return (schedule.name)
    }

    _DataTableColumnBlock_cellText_30_30($scope: any): any {
        let { app, screen, $pathSegments, $disabled, schedule, sIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, schedule: This.ArSchedule, sIndex: number } = $scope ?? {}

        return (schedule.type)
    }

    _DataTableColumnBlock_cellText_31_31($scope: any): any {
        let { app, screen, $pathSegments, $disabled, schedule, sIndex }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, schedule: This.ArSchedule, sIndex: number } = $scope ?? {}

        return (schedule.description)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_1_0_0_0_0_0_0_1_0_0_0_0_1_0_click_removeEmailTemplate_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, event, eventIdx, template, templateIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, event: This.ArScheduleEvent, eventIdx: number, template: This.ArEmailTemplate, templateIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeEmailTemplate(eventIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_1_0_0_0_0_0_0_2_0_0_click_addEmailTemplate_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, event, eventIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, event: This.ArScheduleEvent, eventIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addEmailTemplate(eventIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_1_0_0_0_0_0_0_2_1_0_click_removeEvent_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, event, eventIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, event: This.ArScheduleEvent, eventIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeEvent(eventIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_1_0_1_click_newEvent_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_newEvent($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_2_0_0_0_0_0_1_0_0_2_0_click_removeEmailTemplate_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, event, eventIdx, template, templateIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, event: This.ArScheduleEvent, eventIdx: number, template: This.ArEmailTemplate, templateIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeEmailTemplate(eventIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_2_0_0_0_0_0_2_0_0_click_addEmailTemplate_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, event, eventIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, event: This.ArScheduleEvent, eventIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_addEmailTemplate(eventIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_2_0_0_0_0_0_2_1_0_click_removeEvent_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled, event, eventIdx }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean, event: This.ArScheduleEvent, eventIdx: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeEvent(eventIdx, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_2_0_1_2_0_0_1_click_newEvent_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_newEvent($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_3_0_click_saveSelectedSchedule_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_saveSelectedSchedule(screen.selectedSchedule, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_3_1_click_removeSelectedSchedule_0($event: any, $scope: any): Promise<void> {
        let { app, screen, $pathSegments, $disabled }: { app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeSelectedSchedule(screen.selectedSchedule, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            Sys.App.title = This.$T.manageSchedules
            screen.emailTemplates = await This.Db.loadByCondition(This.ArEmailTemplate)
            screen.selectableEmailTemplates = screen.emailTemplates.filter(template => template.isSelectable)
            await this.action_reloadSchedules($context)
        }
    }

    async action_loadSelectedSchedule(selectedSchedule: This.ArSchedule, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        selectedSchedule = this.storeService.replaceWithStateVersion(selectedSchedule, $context)
        {
            screen.selectedSchedule = selectedSchedule
        }
    }

    async action_removeSelectedSchedule(selectedSchedule: This.ArSchedule, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        selectedSchedule = this.storeService.replaceWithStateVersion(selectedSchedule, $context)
        {
            if (selectedSchedule.id) {
                //await This.Db.deleteMany(selectedSchedule.events)
                await This.Db.deleteById(This.ArSchedule, selectedSchedule.id)
            }
            await this.action_reloadSchedules($context)
        }
    }

    async action_saveSelectedSchedule(selectedSchedule: This.ArSchedule, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        selectedSchedule = this.storeService.replaceWithStateVersion(selectedSchedule, $context)
        {
            await This.Db.saveOne(selectedSchedule)
            await this.action_reloadSchedules($context)
        }
    }

    async action_newSchedule($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.selectedSchedule = new This.ArSchedule
        }
    }

    async action_newEvent($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.selectedSchedule.events.push(new This.ArScheduleEvent)
        }
    }

    async action_reloadSchedules($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.schedules = await This.Db.loadByCondition(This.ArSchedule, {
                relations: {
                    events: { emailTemplates: true }
                }
            }
            )
        }
    }

    async action_removeEvent(index: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        index = this.storeService.replaceWithStateVersion(index, $context)
        {
            screen.selectedSchedule.events.splice(index, 1)
            await this.action_reloadSchedules($context)
        }
    }

    async action_addEmailTemplate(eventIdx: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        eventIdx = this.storeService.replaceWithStateVersion(eventIdx, $context)
        {
            if (!screen.selectedSchedule.events[eventIdx].emailTemplates)
                screen.selectedSchedule.events[eventIdx].emailTemplates = []
            screen.selectedSchedule.events[eventIdx].emailTemplates.push(new This.ArEmailTemplate)
        }
    }

    async action_removeEmailTemplate(eventIdx: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        eventIdx = this.storeService.replaceWithStateVersion(eventIdx, $context)
        {
            screen.selectedSchedule.events[eventIdx].emailTemplates.splice(eventIdx, 1)
        }
    }
}
