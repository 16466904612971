import { BranchNameType, BusinessObjectIdType, BusinessObjectTypeType, ModuleIdType, ScreenQualifiedNameAttribute } from '@shared/types'
import { AppContext } from '../app-context'
import { inject, Injectable, OnInit, signal, WritableSignal } from '@angular/core'
import { IdbService } from './idb.service'
import { FrontendUserBridge } from '../bridges/frontend-user-bridge'
import { EnvironmentSettings, idbSettingName } from '@ng-shared/lib/services/environment-settings'
import { getBoEditorPath } from '@shared/util/data-util'
import { BoReference } from '@shared/bos/bo-reference'
import { AuthService } from './auth-service'
import { BehaviorSubject, delay, filter, map, merge, shareReplay, Subject } from 'rxjs'
import { NavigationEnd, Router } from '@angular/router'
import { toSignal } from '@angular/core/rxjs-interop'

const OverlayElementId = 'dev-bar-screen-overlay'
const NameElementId = 'dev-bar-screen-name'

interface OverlayDivs {
	overlay: HTMLDivElement
	screenName: HTMLDivElement
}
interface DevBarSettings {
	isAutoReload: boolean
	isKeepData: boolean
	isFloating: boolean
	isEnableEverything: boolean
	isIgnoreValidations: boolean
  }
const DevBarSettingsIdbKey = 'Lowgile_DevBarSettings'
  
@Injectable({
	providedIn: 'root'
})
export class DevBarService {
	private router = inject(Router)

	environmentSettings?: EnvironmentSettings
	settings = signal({} as DevBarSettings)
	isCollapsed = true
	canUseDevBar: boolean | undefined = undefined

	forceScreenRefreshSubject = new Subject<void>()
	refreshScreenNameSubject = new Subject<void>()
	mainScreenName$ = merge(
		this.refreshScreenNameSubject,
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			delay(10),
		),
	).pipe(
		map(() => this.findTopLevelScreenName()),
		shareReplay(1),
	)
	mainScreenName = toSignal(this.mainScreenName$)

	constructor(
		private idbService: IdbService,
		private authService: AuthService,
	) {}
	
	async init() {
		await this.authService.initializedPromise
		const user = FrontendUserBridge.currentUser
		this.canUseDevBar = user?.hasSystemPermission('UseConsole') ?? false

		this.environmentSettings = await this.idbService.get(idbSettingName)
		const settings = await this.idbService.get(DevBarSettingsIdbKey) || {
			isAutoReload: false,
			isKeepData: false,
			isFloating: false,
			isEnableEverything: false,
			isIgnoreValidations: false,
		}
		this.settings.set(settings)
	    this.isCollapsed = settings.isFloating
	}

	async saveSettings() {
		await this.idbService.put(DevBarSettingsIdbKey, this.settings())
		this.settings.update(settings => settings)
		this.forceScreenRefreshSubject.next()
	}

	openBoEditor(branchName: BranchNameType, boRef: BoReference) {
		const path = getBoEditorPath(branchName, boRef)
	
		window.open(path, path);
	}

	handleMouseEvent(event: MouseEvent) {
		const highlightModeActive = event.altKey && event.ctrlKey
		if(!highlightModeActive) {
			this.removeOverlayDivs()
			return
		}

		const overlayDivs = this.getOrCreateOverlayDivs()

		switch(event.type) {
			case 'click':
				return this.openDefiningScreenAtMouseEvent(event)

			case 'mousemove':
				return this.updateOverlay(event, overlayDivs)
		}
	}

	private updateOverlay(event: MouseEvent, overlayDivs: OverlayDivs) {
		const definingScreen = this.findDefiningScreenAtPosition(event.x, event.y)
		if(definingScreen.qualifiedName && definingScreen.rect) {
			const style = overlayDivs.overlay.style
			const { rect } = definingScreen
			style.top = `${rect.top}px`
			style.left = `${rect.left}px`
			style.width = `${rect.width}px`
			style.height = `${rect.height}px`

			overlayDivs.screenName.innerText = definingScreen.qualifiedName
		}
	}

	private findDefiningScreenAtPosition(x: number, y: number): {
		qualifiedName?: string,
		rect?: DOMRect
	} {
		let foundScreenId: string | undefined = undefined
		let foundRect: DOMRect | undefined = undefined

		for(const el of document.elementsFromPoint(x, y)) {
			let screenId = el.getAttribute(ScreenQualifiedNameAttribute)
			if(screenId) {
				if(foundScreenId && foundScreenId != screenId) {
					break
				}
				foundScreenId = screenId
				foundRect = el.getBoundingClientRect()
			}
		}

		return {
			qualifiedName: foundScreenId,
			rect: foundRect,
		}
	}

	private openDefiningScreenAtMouseEvent(event: MouseEvent) {
		if(event.target instanceof HTMLElement) {
			const { qualifiedName} = this.findDefiningScreenAtPosition(event.x, event.y)
			if(qualifiedName) {
				const boRef = BoReference.fromQualifiedName(qualifiedName, 'Screen')
				this.openBoEditor(AppContext.branchName, boRef)
			}
		}
	}

	findTopLevelScreenName(): string {
		return document.querySelector(`[${ScreenQualifiedNameAttribute}]`)?.attributes?.getNamedItem(ScreenQualifiedNameAttribute)?.value ?? ''
	}

	getTopLevelProcessName(): string {
		return AppContext.processId()
	}

	getOrCreateOverlayDivs(): OverlayDivs {
		let overlay = document.getElementById(OverlayElementId)
		if(!overlay) {
			overlay = document.createElement('div')
			overlay.id = OverlayElementId
			overlay.innerHTML = `<div id="${NameElementId}"></div>`
			document.body.appendChild(overlay)
		}

		return {
			overlay: overlay as HTMLDivElement,
			screenName: document.getElementById(NameElementId) as HTMLDivElement,
		}
	}

	removeOverlayDivs() {
		let overlay = document.getElementById(OverlayElementId)
		if(overlay) document.body.removeChild(overlay)
	}

	ifValidationsEnabled<T>(value: T): T | null {
		return this.settings().isIgnoreValidations ? null : value
	}
}