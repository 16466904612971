// Compiled at 2025-01-14T21:36:14.508Z
import * as System from "./System";
import * as This from "./System";
import { ScreenDefinition } from "@shared/types";

// Auto-generated by Lowgile
export const $ModuleId = 'System';

export * from "../UserDefinedConfiguration/System/UserLanguage";
export * from "../Label/System/$translations";
export * from "../Resource/System/$resources";
export { $Config } from "../ConfigurationItem/System/config";
export * as $Process from "../Process/System/$processes";
export * from "../Role/System/$roles";
export * from "../Permission/System/$permissions";

export const $Screens = {
};
