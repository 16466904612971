import { Pipe, PipeTransform, ɵisPromise, ɵisSubscribable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Observable, Subscribable } from 'rxjs'

export class JsonHighlightItem {
	type: 'object' | 'array' | 'value'
	isExpanded = true

	constructor(
		public originalValue: any,
		public key: string | number | undefined,
		public children: JsonHighlightItem[],
	) {
		if(Array.isArray(originalValue)) {
			this.type = 'array'
		} else if(typeof originalValue == 'object' && originalValue) {
			this.type = 'object'
		} else {
			this.type = 'value'
		}
	}

	canCollapse() {
		return this.type == 'object' || this.type == 'array'
	}
}

function jsonHighlightParse(input: any, inputKey: string | number | undefined): JsonHighlightItem {
	if(Array.isArray(input)) {
		return new JsonHighlightItem(input, inputKey, input.map((item, idx) => jsonHighlightParse(item, idx)))
	}
	
	if(typeof input == 'object' && input) {
		return new JsonHighlightItem('object', inputKey, Object.entries(input).map(([key, value]) => {
			return jsonHighlightParse(value, key)
		}))
	}

	return new JsonHighlightItem('value', inputKey, [])
}

@Pipe({
  name: 'jsonHighlight',
  pure: true,
  standalone: false,
})
export class JsonHighlightPipe implements PipeTransform {
	transform(input: any): JsonHighlightItem {
		return jsonHighlightParse(input, undefined)
  	}

	// private indentText = '  '

	// constructor(private sanitizer: DomSanitizer) {}

	// transform<T>(input: any, multiline: boolean): SafeHtml {
	// 	const html = this.stringify(input, multiline, 0)
	// 	return this.sanitizer.bypassSecurityTrustHtml(html)
  	// }

	// private stringify(input: any, multiline: boolean, indentLevel: number): string {

	// 	if(Array.isArray(input)) {
	// 		const multilineLocal = multiline && input.length > 1
	// 		const itemsHl = input.map(item => this.stringify(item, multilineLocal, indentLevel + 1))
	// 		if(multiline) {
	// 			return `[\n${itemsHl.join(',\n')}\n${this.indentText.repeat(indentLevel)}]`
	// 		}
	// 		return `[${itemsHl.join(', ')}]`
	// 	}

	// 	if(typeof input == 'object' && input) {
	// 		const entries = Object.entries(input)
	// 		const propertiesHl = entries.map(([key, value]) => {
	// 			const keyHl = `<span class="json-key">${key}</span>`
	// 			const valueHl = this.stringify(value, multiline, indentLevel + 1)

	// 			if(multiline) {
	// 				return `${this.indentText.repeat(indentLevel)}${keyHl}: ${valueHl}`
	// 			}
	// 			return `${keyHl}: ${valueHl}`
	// 		})


	// 		return propertiesHl.join(multiline ? ',\n' : ', ')
	// 	}

	// 	return `<span class="json-value">${JSON.stringify(input)}</span>`
	// }

	// private indent(text: string, multiline: boolean, level: number): string {
	// 	if(!multiline || level <= 0) return text
	// 	return this.indentText.repeat(level) + text
	// }

	// private encloseAndIndent(texts: string[], encloseWith: '[]' | '{}', multiline: boolean, level: number): string {
	// 	if(!multiline) return `${encloseWith[0]}${texts.join(', ')}${encloseWith[1]}`
	// 	return `${encloseWith[0]}\n${texts.map(text => this.indent(text, multiline, level + 1)).join(',\n')}\n${this.indentText.repeat(level)}${encloseWith[1]}`
	// }
}
