// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.app-top {
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
}

.spinner-wrapper {
  position: fixed;
  inset: 0;
  pointer-events: none;
  user-select: none;
  z-index: 1000;
}

.spinner-grid {
  height: 100%;
  display: grid;
  place-items: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDLGtCQUFBO0VBQ0EsTUFBQTtFQUNBLFNBQUE7RUFDQSxPQUFBO0VBQ0EsUUFBQTtBQUNEOztBQUVBO0VBQ0MsYUFBQTtFQUNBLGlDQUFBO0VBQ0EsWUFBQTtBQUNEOztBQUVBO0VBQ0MsZUFBQTtFQUNBLFFBQUE7RUFDQSxvQkFBQTtFQUNBLGlCQUFBO0VBQ0EsYUFBQTtBQUNEOztBQUVBO0VBQ0MsWUFBQTtFQUNBLGFBQUE7RUFDQSxtQkFBQTtBQUNEIiwiZmlsZSI6ImFwcC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcblx0cG9zaXRpb246IGFic29sdXRlO1xuXHR0b3A6IDA7XG5cdGJvdHRvbTogMDtcblx0bGVmdDogMDtcblx0cmlnaHQ6IDA7XG59XG5cbi5hcHAtdG9wIHtcblx0ZGlzcGxheTogZ3JpZDtcblx0Z3JpZC10ZW1wbGF0ZS1yb3dzOiBhdXRvIGF1dG8gMWZyO1xuXHRoZWlnaHQ6IDEwMCU7XG59XG5cbi5zcGlubmVyLXdyYXBwZXIge1xuXHRwb3NpdGlvbjogZml4ZWQ7XG5cdGluc2V0OiAwO1xuXHRwb2ludGVyLWV2ZW50czogbm9uZTtcblx0dXNlci1zZWxlY3Q6IG5vbmU7XG5cdHotaW5kZXg6IDEwMDA7XG59XG5cbi5zcGlubmVyLWdyaWQge1xuXHRoZWlnaHQ6IDEwMCU7XG5cdGRpc3BsYXk6IGdyaWQ7XG5cdHBsYWNlLWl0ZW1zOiBjZW50ZXI7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AACD;;AAEA;EACC,aAAA;EACA,iCAAA;EACA,YAAA;AACD;;AAEA;EACC,eAAA;EACA,QAAA;EACA,oBAAA;EACA,iBAAA;EACA,aAAA;AACD;;AAEA;EACC,YAAA;EACA,aAAA;EACA,mBAAA;AACD;AACA,g+BAAg+B","sourcesContent":[":host {\n\tposition: absolute;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n}\n\n.app-top {\n\tdisplay: grid;\n\tgrid-template-rows: auto auto 1fr;\n\theight: 100%;\n}\n\n.spinner-wrapper {\n\tposition: fixed;\n\tinset: 0;\n\tpointer-events: none;\n\tuser-select: none;\n\tz-index: 1000;\n}\n\n.spinner-grid {\n\theight: 100%;\n\tdisplay: grid;\n\tplace-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
