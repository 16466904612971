// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => PurchaseVendorOfferSelectionStatus.loadInternal(), entryList => {
        PurchaseVendorOfferSelectionStatus.θentryList = entryList
        PurchaseVendorOfferSelectionStatus.θentryMap = undefined
        PurchaseVendorOfferSelectionStatus.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class PurchaseVendorOfferSelectionStatus {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: PurchaseVendorOfferSelectionStatus.Id;
    static readonly Id = String;
    static θids: ReadonlyArray<PurchaseVendorOfferSelectionStatus.Id> = Object.freeze(['undecided', 'buy', 'dontBuy']);
    static θentryMap: Readonly<Record<PurchaseVendorOfferSelectionStatus.Id, Readonly<PurchaseVendorOfferSelectionStatus.Entry>>>;
    static θentryList: ReadonlyArray<PurchaseVendorOfferSelectionStatus.Entry>;
    static θchoicesCache = new Map<keyof PurchaseVendorOfferSelectionStatus.Entry, Sys.Types.Choice[]>();
    readonly θhasOthers = false;
    static readonly $displayProperty = 'label';
    [immerable] = true;

    get __type(): 'PurchaseToPay.PurchaseVendorOfferSelectionStatus' {
        return 'PurchaseToPay.PurchaseVendorOfferSelectionStatus'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.PurchaseVendorOfferSelectionStatus' {
        return 'PurchaseToPay.PurchaseVendorOfferSelectionStatus'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get $displayValue(): string {
        return this.$isOther ? (this as any).$other : String((this as any)[PurchaseVendorOfferSelectionStatus.$displayProperty])
    }

    get label() {
        return PurchaseVendorOfferSelectionStatus.getEntryMap()[this.id]?.label
    }

    get $isOther() {
        return false
    }

    get isUndecided() {
        return this.id == 'undecided'
    }

    static get Undecided() {
        return new this('undecided')
    }

    get isBuy() {
        return this.id == 'buy'
    }

    static get Buy() {
        return new this('buy')
    }

    get isDontBuy() {
        return this.id == 'dontBuy'
    }

    static get DontBuy() {
        return new this('dontBuy')
    }

    constructor(id: PurchaseVendorOfferSelectionStatus.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        if (!this.θentryList) this.θentryList = this.θgetStaticEntryList()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: PurchaseVendorOfferSelectionStatus.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getChoices(labelColumn: keyof PurchaseVendorOfferSelectionStatus.Entry = '$displayValue'): Sys.Types.Choice[] {
        if (labelColumn == "$displayValue") labelColumn = PurchaseVendorOfferSelectionStatus.$displayProperty
        let options = this.θchoicesCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θchoicesCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<PurchaseVendorOfferSelectionStatus.Entry[]> {
        const dynamicEntryList = []
        this.θentryMap = null
        this.θchoicesCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...this.θgetStaticEntryList(), ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new PurchaseVendorOfferSelectionStatus(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: PurchaseVendorOfferSelectionStatus.Id | PurchaseVendorOfferSelectionStatus) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.PurchaseVendorOfferSelectionStatus yet:', err.message)
            //throw err
        })
    }

    setUndecided() {
        this.id = 'undecided'

    }

    setBuy() {
        this.id = 'buy'

    }

    setDontBuy() {
        this.id = 'dontBuy'

    }

    static θgetStaticEntryList() {
        return Object.freeze([
            Object.freeze({ id: "undecided", label: "Undecided" }),
            Object.freeze({ id: "buy", label: "Buy" }),
            Object.freeze({ id: "dontBuy", label: "Don't buy" })
        ])
    }
}

export const PurchaseVendorOfferSelectionStatusθId = String;

export type PurchaseVendorOfferSelectionStatusθId = string;

export namespace PurchaseVendorOfferSelectionStatus {
    export type Id = 'undecided' | 'buy' | 'dontBuy'

    export interface Entry {
        id: string;
        label: string;
        $displayValue?: string;
    }
}
Sys.Language.languageChanged$.subscribe(() => PurchaseVendorOfferSelectionStatus.load(true))
