// Compiled at 2025-01-14T21:36:14.508Z
// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => Vendor.loadInternal(), entryList => {
        Vendor.θentryList = entryList
        Vendor.θentryMap = undefined
        Vendor.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class Vendor {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: Vendor.Id;
    static readonly Id = String;
    static θids: ReadonlyArray<Vendor.Id> = Object.freeze(['undefined']);
    static θentryMap: Readonly<Record<Vendor.Id, Readonly<Vendor.Entry>>>;
    static θentryList: ReadonlyArray<Vendor.Entry>;
    static θchoicesCache = new Map<keyof Vendor.Entry, Sys.Types.Choice[]>();
    readonly θhasOthers = false;
    static readonly $displayProperty = 'id';
    [immerable] = true;

    get __type(): 'PurchaseToPay.Vendor' {
        return 'PurchaseToPay.Vendor'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.Vendor' {
        return 'PurchaseToPay.Vendor'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get $displayValue(): string {
        return this.$isOther ? (this as any).$other : String((this as any)[Vendor.$displayProperty])
    }

    get name() {
        return Vendor.getEntryMap()[this.id]?.name
    }

    get address() {
        return Vendor.getEntryMap()[this.id]?.address
    }

    get email() {
        return Vendor.getEntryMap()[this.id]?.email
    }

    get $isOther() {
        return false
    }

    get isEmpty() {
        return this.id == 'undefined'
    }

    static get Empty() {
        return new this('undefined')
    }

    constructor(id: Vendor.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        if (!this.θentryList) this.θentryList = this.θgetStaticEntryList()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: Vendor.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getChoices(labelColumn: keyof Vendor.Entry = '$displayValue'): Sys.Types.Choice[] {
        if (labelColumn == "$displayValue") labelColumn = Vendor.$displayProperty
        let options = this.θchoicesCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θchoicesCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<Vendor.Entry[]> {
        async function getDynamicEntries() {
            try {
                return ExecutionService.executeMethodAndReturnData<any[]>('StaticEntity', 'PurchaseToPay', 'Vendor', this, 'getEntryList', [])
            } catch (err: any) {
                return []
            }

        }

        const dynamicEntryList = await getDynamicEntries()
        this.θentryMap = null
        this.θchoicesCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...this.θgetStaticEntryList(), ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new Vendor(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: Vendor.Id | Vendor) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.Vendor yet:', err.message)
            //throw err
        })
    }

    setEmpty() {
        this.id = 'undefined'

    }

    static θgetStaticEntryList() {
        return Object.freeze([

        ])
    }
}

export const VendorθId = String;

export type VendorθId = string;

export namespace Vendor {
    export type Id = 'undefined' | string

    export interface Entry {
        id: string;
        name: string;
        address: string;
        email: string;
        $displayValue?: string;
    }
}
Sys.Language.languageChanged$.subscribe(() => Vendor.load(true))
