import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { FrontendLanguageBridge } from '../bridges/frontend-language-bridge';
import { ChangeDetectionService } from '../services/change-detection.service'

@Component({
	selector: 'lowgile-validation-errors',
	template: `
	<div *ngIf="shouldShowError()">
	<mat-error *ngFor="let error of getErrorMessages()" [innerHtml]="error">
	</mat-error>
	</div>
	`,
	styleUrls: [
		'./validation-errors.component.scss',
	],
	standalone: false,
})
export class ValidationErrorsComponent implements OnInit, OnDestroy {
	@Input('control') formControl!: FormControl
	private subscription?: Subscription
	
	constructor(
		private cdService: ChangeDetectionService,
	) {}
	
	shouldShowError() {
		if(!this.formControl?.errors) return false
		if(!this.formControl.dirty && !this.formControl.touched) return false
		return true
	}

	ngOnInit(): void {
		this.subscription = FrontendLanguageBridge.languageChanged$.subscribe(() => {
			this.formControl?.updateValueAndValidity()
			this.cdService.markViewDirty(this, true)
		})
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe()
	}
	
	
	getErrorMessages() {
		return Object.values(this.formControl?.errors ?? {})
	}
}
	